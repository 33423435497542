import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../../../../Context";
import transformToIdValuePair from "../../../../controllers/utils/transformToIdValuePair";
import parseExpiresAt from "../../../../controllers/parseExpiresAt";
import { t } from "i18next";
import SecondaryText from "../../../detailsPage/SecondaryText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Line = styled.div``;

const ExistingMembershipPlan = ({}) => {
  const { membershipPlans, loggedInUser } = useContext(Context);

  let planIdVsData = transformToIdValuePair(membershipPlans);

  if (!loggedInUser.membershipPlanID)
    return (
      <Container>
        <Line>{t("noMembershipPlanSelected")}</Line>
      </Container>
    );

  let item = planIdVsData[loggedInUser.membershipPlanID];

  if (!item) return null;

  return (
    <Container>
      <SecondaryText>
        {t("currentPlan")} : {item.name}
      </SecondaryText>

      <SecondaryText>
        {t("validity")} : {parseExpiresAt(loggedInUser.membershipPlanValidity)}{" "}
        Days
      </SecondaryText>
    </Container>
  );
};

export default ExistingMembershipPlan;
