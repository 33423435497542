import styled from "styled-components";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";
import SecondaryText from "../../../../detailsPage/SecondaryText";

const Container = styled.div``;

export default function ActivityRechargeBillLine({
  type,
  typeIdVsData,
  quantity,
  itemID,
}) {
  let amount = getPrice() * quantity;
  return (
    <SecondaryText>
      {typeIdVsData[itemID].title} {capitalizeFirstLetter(type.toLowerCase())} x{" "}
      {quantity} = {formatNumber(amount)} CFA
    </SecondaryText>
  );

  function getPrice() {
    if (type == "SOLO") return typeIdVsData[itemID].price;
    if (type == "PAIR") return typeIdVsData[itemID].pairPrice;
    if (type == "TRIO") return typeIdVsData[itemID].trioPrice;
  }
}
