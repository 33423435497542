import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { serverLine } from "../../../../controllers/network/serverLine";
import { MdDone } from "react-icons/md";
import CustomButton from "../../../helperComponents/CustomButton";
import { useState } from "react";
import MembershipPlanRequested from "./MembershipPlanRequested";
import goTo from "../../../../controllers/goTo";
import LoadingSection from "../../../helperComponents/LoadingSection";
import formatNumber from "../../../../controllers/utils/formatNumber";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding-right: 50px; */

  gap: 30px;
  font-weight: bold;
  font-size: 20px;

  padding: 20px 30px;
  border-radius: 20px;
  padding-bottom: 50px;
  width: 100%;
  padding-top: 40px;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const Features = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0.8;
  flex-direction: column;
`;

const PlanName = styled.span`
  color: var(--accentColor);
  font-size: 19px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
`;

const PriceTag = styled.div`
  color: var(--accentColor);
  font-size: 13px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: var(--accentColor);
  text-align: center;
  text-transform: capitalize;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const Icon = styled.div`
  background-color: #e3f004;
  /* height: 100px; */
  /* width: 100px; */
  color: #000;
  border-radius: 100px;
  font-size: 22px;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 900;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  /* margin-top: -3px; */
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AskMembershipPlanConfirmation = ({ sendRequest, price, title }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const { t } = useTranslation();

  if (loading) return <LoadingSection />;

  if (status) return <MembershipPlanRequested />;

  return (
    <Card>
      <Icon>{title}</Icon>

      <CardTitle>
        <PlanName>{t("confirmMembershipTitle")} </PlanName>
        <PriceTag>
          {t("price")} : {formatNumber(price)} CFA{" "}
        </PriceTag>
      </CardTitle>

      <Buttons>
        <PrimaryButton
          onClick={() => {
            sendRequest({
              secondarySetLoading: setLoading,
              onSuccess: setStatus,
            });
          }}
        >
          {t("confirmMembershipConfirmButton")}
        </PrimaryButton>
        <CustomButton onClick={goTo(-1)}>
          {t("confirmMembershipCancelButton")}
        </CustomButton>
      </Buttons>
    </Card>
  );
};

export default AskMembershipPlanConfirmation;
