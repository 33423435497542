import styled from "styled-components";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const Subtitle = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  color: var(--accentColor);
  margin: 0;
  padding: 0;
  font-style: italic;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  /* margin-bottom: 10px; */
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 800;

  /* font-style: italic; */
  font-weight: 800;

  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
`;

export default function DetailsSection({ title, children }) {
  return (
    <Section>
      <Subtitle>{title}</Subtitle>
      <SectionContent>{children}</SectionContent>
    </Section>
  );
}
