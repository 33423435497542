import { useTranslation } from "react-i18next";
import ListPage from "../../ListPage";
import ActivityBookingCard from "../ActivityBookingCard";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import DropDownEmployee from "../upcomingActivities/DropDownEmployee";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import getUrlQuery from "../../../../../controllers/getUrlQuery";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function MyBookingsPage() {
  const { isMobile } = useContext(Context);
  const [value, setValue] = useState(null);

  const { t } = useTranslation();

  let urlQuery = getUrlQuery();

  useEffect(() => {}, []);

  return (
    <ListPage
      colsOnMobile={1}
      title={t("myActivitiesTitle")}
      path={getPath()}
      CardComponent={ActivityBookingCard}
      filterFunction={filterByCoach}
      filterComponent={
        <Row>
          <DropDownEmployee value={value} onChange={setValue} />

          <CustomButton
            textStyle={{ fontSize: isMobile ? "9px" : "11px" }}
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setValue(null);
            }}
          >
            Reset
          </CustomButton>
        </Row>
      }
    />
  );

  function getPath() {
    let activityTypeID = null;

    if (urlQuery.activityTypeID) {
      activityTypeID = urlQuery.activityTypeID;
    }

    if (activityTypeID)
      return `/activity-bookings/?activityTypeID=${activityTypeID}`;

    return "/activity-bookings";
  }

  function filterByCoach(dataList) {
    if (!value) return dataList;

    return dataList.filter((item) => item.activity.employeeID == value);
  }
}
