import styled from "styled-components";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import RechargeActivitySection from "./RechargeActivitySection";
import calculateActivityRechargeTotal from "../../../../../controllers/payment/calculateActivityRechargeTotal";

export default function ShowPrice({ activityTypes, newRecharge }) {
  let amount = calculateActivityRechargeTotal({
    activityTypes,
    rechargeDoc: newRecharge,
  });

  return (
    <RechargeActivitySection title="Total">
      {formatNumber(amount)} CFA
    </RechargeActivitySection>
  );
}
