import styled from "styled-components";
import ActivityRechargeBillLine from "./ActivityRechargeBillLine";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import getUserMembership from "../../../../../controllers/getMembershipData";
import { useContext } from "react";
import Context from "../../../../../Context";
import calculateActivityRechargeTotal from "../../../../../controllers/payment/calculateActivityRechargeTotal";
import SecondaryText from "../../../../detailsPage/SecondaryText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LastLine = styled.div``;

export default function ActivityRechargeBill({
  user,
  typeIdVsData,
  rechargeRequestData,
}) {
  const { membershipPlans } = useContext(Context);

  let list = [];

  for (let type in rechargeRequestData) {
    let items = rechargeRequestData[type];

    for (let itemID in items) {
      let quantity = items[itemID];

      list.push(
        <ActivityRechargeBillLine
          type={type}
          typeIdVsData={typeIdVsData}
          itemID={itemID}
          quantity={quantity}
        />
      );
    }
  }

  let total = calculateActivityRechargeTotal({
    activityTypes: typeIdVsData,
    rechargeDoc: rechargeRequestData,
  });

  return (
    <Container>
      {list}
      <SecondaryText>Total : {formatNumber(total)} CFA</SecondaryText>
      {getDiscountedPrice()}
    </Container>
  );

  function getDiscountedPrice() {
    let membershipData = getUserMembership({ membershipPlans, user });

    if (!membershipData) return null;
    if (!membershipData.isRoyalty) return null;

    return (
      <LastLine>After Discount : {formatNumber(total * 0.85)} CFA</LastLine>
    );
  }
}
