import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";
import limitStringLength from "../../../../../controllers/limitStringLength";
import { AiOutlinePlus } from "react-icons/ai";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import goTo from "../../../../../controllers/goTo";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 15px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border: 1px solid #222;
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function ManageActivityTypeCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
  dashboardType,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    { type: "IMAGE", name: "image", label: t("adminPageActivityTypeImage") },
    {
      type: "STRING",
      name: "title",
      label: t("adminPageActivityTypeFormName"),
    },
    {
      type: "LONG_STRING",
      name: "description",
      label: t("adminPageActivityTypeFormDescription"),
    },
    {
      type: "NUMBER",
      name: "duration",
      label: `${t("duration")} (in mins)`,
    },
    {
      type: "NUMBER",
      name: "slotsPerHour",
      label: t("activityTypeSessionsPerHour"),
    },
    {
      type: "NUMBER",
      name: "price",
      label: t("price"),
    },

    {
      type: "NUMBER",
      name: "pairPrice",
      label: `${t("adminPageActivityTypePairPrice")} (Optional)`,
    },
    {
      type: "NUMBER",
      name: "trioPrice",
      label: `${t("adminPageActivityTypeTrioPrice")} (Optional)`,
    },
    //

    {
      type: "BOOLEAN",
      name: "allowPairParticipation",
      label: t("adminPageActivityTypeAllowPairParticipation"),
    },
    {
      type: "BOOLEAN",
      name: "allowTrioParticipation",
      label: t("adminPageActivityTypeAllowTrioParticipation"),
    },
    {
      type: "BOOLEAN",
      name: "willHaveReport",
      label: t("adminPageActivityTypeFormWillHaveReport"),
    },

    //

    {
      type: "HOUR",
      name: "startingTime",
      label: t("activityTypeStartTime"),
    },
    {
      type: "HOUR",
      name: "endingTime",
      label: t("activityTypeEndTime"),
    },
    {
      type: "HOUR",
      name: "breakTimeStart",
      label: t("activityTypeBreakTimeStart"),
    },
    {
      type: "HOUR",
      name: "breakTimeEnding",
      label: t("activityTypeBreakTimeEnding"),
    },
    // {
    //   type: "DAYS",
    //   name: "weekdayAvailability",
    //   label: t("activityTypeWeekdayAvailability"),
    // },

    {
      type: "UPCOMING_DATES",
      name: "dates",
      label: t("activityTypeWeekdayAvailability"),
    },

    {
      type: "EMPLOYEE",
      name: "employeeID",
      label: t("adminPageActivityFormSelectEmployee"),
    },
    {
      type: "MEMBERSHIP_PLAN",
      name: "membershipPlanIDs",
    },
  ];

  if (forAddingNewItem) {
    if (dashboardType == "EMPLOYEE_DASHBOARD") return null;

    return (
      <CustomButton
        icon={<AiOutlinePlus />}
        style={{ background: "#000" }}
        onClick={addItem}
      >
        {t("clickHereToAddNew")}
      </CustomButton>
    );
  }

  return (
    <Card>
      <ProfileContainer>
        <Image src={getImageURL(item.image, false)} alt="Activity Type Image" />

        <ProfileText>
          <Name>{item.title} </Name>
        </ProfileText>
      </ProfileContainer>

      <Data>
        {item.employee ? (
          <FiledValue>
            {t("adminPageActivityEmployee")} : {item.employee.name}{" "}
          </FiledValue>
        ) : null}

        <FiledValue>
          {t("adminPageActivityTypeWillHaveReport")} :{" "}
          {item.willHaveReport ? "Yes" : "No"}{" "}
        </FiledValue>

        <FiledValue style={{ opacity: 0.5 }}>
          {limitStringLength(item.description, 100)}{" "}
        </FiledValue>

        <FiledValue style={{ opacity: 0.5 }}>
          {" "}
          {t("price")} : {formatNumber(item.price)} CFA
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton
          style={{ background: "#000" }}
          onClick={goTo(
            `/admin/manage-activity-bookings/?activityTypeID=${item._id}`
          )}
        >
          {t("bookings")}
        </CustomButton>

        <CustomButton style={{ background: "#000" }} onClick={editItem}>
          {t("adminPageActivityTypeEditItem")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          dashboardType={dashboardType}
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/activity-type"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          dashboardType={dashboardType}
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/activity-type"
        />
      ),
    });
  }
}
