import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import TitleBar from "../../../helperComponents/TitleBar";
import LoggedInMembershipPlans from "./LoggedInMembershipPlans";
import CustomButton from "../../../helperComponents/CustomButton";
import { useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import transformToIdValuePair from "../../../../controllers/utils/transformToIdValuePair";
import LoadingSection from "../../../helperComponents/LoadingSection";
import ExistingActivityRecharge from "../rechargeActivity/ExistingActivityRecharge";
import ActivityBalance from "../rechargeActivity/ActivityBalance";
import ExistingMembershipPlan from "./ExistingMembershipPlan";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 62vw;
  align-items: center;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function LoggedInMembershipPage() {
  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <TitleBar>Membership</TitleBar>

      <Container>
        <LoggedInMembershipPlans />
      </Container>
      <br />
    </LoggedInBoilerplate>
  );
}
