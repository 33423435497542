import styled from "styled-components";
import WithBackground from "../../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../../LoggedInHeader";
import { RiAdminLine } from "react-icons/ri";
import CustomToggle from "../../../../helperComponents/CustomToggle";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Context from "../../../../../Context";
import LoginWall from "../../LoginWall";
import TitleBar from "../../../../helperComponents/TitleBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  width: 100%;
  align-items: flex-start;
`;

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 31px;

  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  font-size: 31px;
  font-weight: 900;

  @media (max-width: 900px) {
    font-size: 17px;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div``;

let toggleGridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  width: "90vw",
};

let toggleItemStyle = { fontSize: "10px" };

export default function EmployeeDashboardBoilerplate({
  title,
  children,
  buttons,
}) {
  const { loggedInUserID, isMobile } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUserID) return <LoginWall />;

  let options = [
    {
      value: "/employee-dashboard/manage-activity-types",
      label: t("adminPageActivityTypesTitle"),
    },
    {
      value: "/employee-dashboard/manage-activities",
      label: t("adminPageActivitiesTitle"),
    },
    {
      value: "/employee-dashboard/manage-employees",
      label: t("me"),
    },
  ];

  return (
    <LoggedInBoilerplate showBackgroundImage={false}>
      <TitleBar onlyMobile={true}> {t("employeeDashboard")} </TitleBar>

      <Container>
        <Top>
          <Left>
            <Icon>
              <RiAdminLine />
            </Icon>

            <Title>
              {t("employeeDashboard")} / {title}
            </Title>
          </Left>

          <Right>
            {buttons}
            <CustomToggle
              toggleStyle={isMobile ? toggleGridStyle : {}}
              btnStyle={isMobile ? toggleItemStyle : {}}
              options={options}
              isLinkBased={true}
            />
          </Right>
        </Top>
        <Main>{children}</Main>
      </Container>
    </LoggedInBoilerplate>
  );
}
