import styled from "styled-components";

const SecondaryText = styled.div`
  font-size: 15px;
  font-weight: 500;
  /* max-width: 300px; */
  opacity: 0.5;
  white-space: pre-line;
  font-family: "Montserrat", sans-serif;
`;

export default SecondaryText;
