import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { useContext } from "react";
import ActivityRechargeBill from "../admin/purchaseRequests/ActivityRechargeBill";
import Context from "../../../../Context";
import ExistingMembershipPlan from "../membership/ExistingMembershipPlan";
import ActivityRechargeBalanceSlip from "../admin/purchaseRequests/ActivityRechargeBalanceSlip";
import { t } from "i18next";
import SectionTitle from "../../../detailsPage/SectionTitle";
import SecondaryText from "../../../detailsPage/SecondaryText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* align-items: center; */
  justify-content: center;
  align-items: center;

  padding: 40px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.div`
  font-weight: 900;
  /* text-align: center; */
  font-size: 15px;
  /* text-align: center; */
  color: var(--accentColor);
  text-transform: capitalize;

  font-family: "Montserrat", sans-serif;

  font-style: italic;
  text-transform: uppercase;
`;

const SecondaryTitle = styled.div`
  font-weight: 700;
  /* text-align: center; */
  /* text-align: center; */
  font-size: 10px;
  opacity: 0.7;
  text-transform: capitalize;

  font-family: "Montserrat", sans-serif;

  font-style: italic;
  text-transform: uppercase;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export default function ActivityBalance({ activityTypeIdVsData }) {
  const { loggedInUser } = useContext(Context);

  let balanceItems = 0;

  if (!loggedInUser) return null;

  if (loggedInUser.activityBalance) {
    for (let typeID in loggedInUser.activityBalance) {
      let items = loggedInUser.activityBalance[typeID];

      for (let typeID in items) {
        balanceItems += items[typeID];
      }
    }
  }

  return (
    <Container>
      <Section>
        <SectionTitle>{t("currentMembershipPlan")}</SectionTitle>

        <ExistingMembershipPlan />
      </Section>

      <Section>
        <SectionTitle>{t("membershipPageActivityTitle")}</SectionTitle>
        <SecondaryText>
          {balanceItems
            ? t("youHaveSubscribedTo")
            : t("membershipPageActivitySecondaryTitle")}
        </SecondaryText>
        <ActivityRechargeBalanceSlip
          rechargeRequestData={loggedInUser.activityBalance}
          typeIdVsData={activityTypeIdVsData}
        />
      </Section>

      <PrimaryButton style={{ width: "150px" }} href="/recharge-activity">
        {t("recharge2")}
      </PrimaryButton>
    </Container>
  );
}
