import { useContext, useState } from "react";

import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import Context from "../../../../Context";
import { serverLine } from "../../../../controllers/network/serverLine";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { GrWhatsapp } from "react-icons/gr";
import { FaWhatsapp } from "react-icons/fa";

const InputContainer = styled.div`
  position: relative;
`;

const WhatsappLogo = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  font-size: 18px;
  opacity: 0.5;
  height: 100%;
`;

export default function AskPhoneNumberPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { t } = useTranslation();

  return (
    <OnboardingBoilerplate
      title={t("onboardingPhoneNumberTitle")}
      desc={t("onboardingPhoneNumberDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <InputContainer>
        <PhoneInput
          international
          defaultCountry="CI"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />

        <WhatsappLogo>
          <FaWhatsapp />
        </WhatsappLogo>
      </InputContainer>
      {/* <MaterialInput
        type="number"
        label={t("onboardingPhoneNumberInput")}
 
        value={phoneNumber}
        onChange={extractEventValue(setPhoneNumber)}
      /> */}
    </OnboardingBoilerplate>
  );

  async function onSubmit() {
    if (!phoneNumber)
      return window.popupAlert(t("onboardingPhoneNumberWarning"));
    if (phoneNumber.toString().length < 10)
      return window.popupAlert("Name should be at least 10 digits.");

    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { phoneNumber } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, phoneNumber };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert(t("saved"));
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
