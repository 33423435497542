import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import AdminPageBoilerplate from "../AdminPageBoilerplate";
import styled from "styled-components";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/network/serverLine";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import FilterUser from "./FilterUser";
import UserPagination from "./UserPagination";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import goTo from "../../../../../controllers/goTo";
import calculateAge from "../../../../../controllers/calculateAge";
import parseDate from "../../../../../controllers/parseDate";
import getMembershipPlanName from "../../../../../controllers/getMembershipPlanName";
import parseExpiresAt from "../../../../../controllers/parseExpiresAt";

const Filters = styled.div``;

const columns = [
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },
  {
    field: "phoneNumber",
    headerName: "Phone No.",
    width: 150,
  },
  {
    field: "age",
    headerName: "Age",
    width: 150,
  },
  {
    field: "gender",
    headerName: "Gender",

    width: 150,
  },
  {
    field: "membershipType",
    headerName: "Membership Type",
    width: 150,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const FilterAndPagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export default function ManageUserPage() {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();
  const [result, setResult] = useState(null);
  const [pagination, setPagination] = useState({ page: 0 });
  const [filter, setFilter] = useState({});

  const [membershipPlans, setMembershipPlans] = useState(null);

  useEffect(() => {
    serverLine.get("/membership-plans").then(setMembershipPlans);
  }, []);

  useEffect(() => {
    setResult(true);
    loadFirstBatch(filter, pagination);
  }, [filter, pagination]);

  if (!membershipPlans)
    return (
      <AdminPageBoilerplate title={t("adminPageManageUsersTitle")}>
        <LoadingSection />
      </AdminPageBoilerplate>
    );

  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  if (!result)
    return (
      <AdminPageBoilerplate title={t("adminPageManageUsersTitle")}>
        <LoadingSection />
      </AdminPageBoilerplate>
    );

  let rows = processOutput();

  return (
    <AdminPageBoilerplate title={t("adminPageManageUsersTitle")}>
      <Container>
        <FilterAndPagination>
          <CustomButton onClick={openFilter}>Filter</CustomButton>
          <UserPagination
            currentData={result}
            pagination={pagination}
            setPagination={setPagination}
          />
        </FilterAndPagination>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone No.</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Membership</TableCell>
                <TableCell>Membership Val.</TableCell>
                <TableCell>Expires in</TableCell>
                <TableCell>Reg. Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  onClick={goTo("/user/" + row.username)}
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.firstName} {row.lastName}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.gender}</TableCell>
                  <TableCell>{row.age}</TableCell>
                  <TableCell>
                    {getMembershipPlanName({
                      membershipIdVsPlan,
                      membershipPlanID: row.membershipPlanID,
                    })}
                  </TableCell>
                  <TableCell>{parseDate(row.membershipPlanValidity)}</TableCell>
                  <TableCell>
                    {parseExpiresAt(row.membershipPlanValidity)} Days
                  </TableCell>
                  <TableCell>{parseDate(row.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </AdminPageBoilerplate>
  );

  function processOutput() {
    let allData = result;

    return allData.map((item) => {
      let newItem = { ...item };
      newItem.id = newItem._id;
      newItem.age = calculateAge(newItem.dateOfBirth);

      return newItem;
    });
  }

  function openFilter() {
    setForm({
      title: "Filter",
      component: (
        <FilterUser
          membershipPlans={membershipPlans}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    });
  }

  async function loadFirstBatch(
    { minAge, maxAge, gender, membershipPlanID },
    pagination
  ) {
    setResult(null);
    let queryPath = ``;

    if (minAge) {
      queryPath += `minAge=${minAge}&`;
    }
    if (maxAge) {
      queryPath += `maxAge=${maxAge}&`;
    }

    if (membershipPlanID) {
      queryPath += `membershipPlanID=${membershipPlanID}&`;
    }

    // if (membershipType) {
    //   queryPath += `membershipType=${membershipType}&`
    // }

    if (gender) {
      queryPath += `gender=${gender}&`;
    }

    if (pagination) {
      if (pagination.page) queryPath += `page=${pagination.page}&`;
    }

    serverLine.get(`/users/?${queryPath}`).then(setResult);
  }
}
