import { useContext } from "react";
import styled from "styled-components";
import DetailsMobile from "./DetailsMobile";
import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";
import SecondaryText from "./SecondaryText";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 90vw;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
    padding: 0;
    width: 90vw;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TextContent = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SeatsLeft = styled.div`
  font-size: 18px;
  padding: 20px;
  color: #c9d400;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucentLight);
  border: 1px solid #222;
  border-radius: 10px;
  overflow: hidden;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  opacity: 0.7;
  white-space: pre-line;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

function DetailsPageBoilerplate({
  title,
  topRightData,
  secondaryTitle,
  buttons,
  children,
  imageData,
  description,
}) {
  const { isMobile } = useContext(Context);

  if (isMobile) {
    return (
      <DetailsMobile
        topRightData={topRightData}
        imageData={imageData}
        title={title}
        secondaryTitle={secondaryTitle}
        buttons={buttons}
      >
        {description ? <SecondaryText>{description}</SecondaryText> : null}
        {children}
      </DetailsMobile>
    );
  }

  return (
    <Container>
      <InfoSection>
        <Title>{title}</Title>
        {description ? <SecondaryText>{description}</SecondaryText> : null}
        <TextContent>{children}</TextContent>
      </InfoSection>

      <RightSection>
        <ImageSection>
          <Image src={getImageURL(imageData)} />
          {secondaryTitle ? <SeatsLeft>{secondaryTitle}</SeatsLeft> : null}
        </ImageSection>

        <Buttons>{buttons}</Buttons>
      </RightSection>
    </Container>
  );
}

export default DetailsPageBoilerplate;
