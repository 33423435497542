import { Capacitor } from "@capacitor/core";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import goTo from "../../../controllers/goTo";

const SwipeBack = ({ children }) => {
  const controls = useAnimation();
  const [isDraggable, setIsDraggable] = useState(false);

  const handleTouchStart = (event) => {
    if (event.touches[0].clientX < 300) {
      setIsDraggable(true); // Enable swipe only if touch starts from the left edge
    } else {
      setIsDraggable(false);
    }
  };

  let platform = Capacitor.getPlatform();

  if (platform !== "ios") return children;

  return (
    <motion.div
      drag={isDraggable ? "x" : false}
      dragConstraints={{ left: 0, right: 50 }}
      dragElastic={0.3}
      onTouchStart={handleTouchStart} // Detect if touch starts from edge
      onDragEnd={(_, info) => {
        if (info.offset.x > 100) {
          controls.start({
            x: 500,
            transition: { type: "spring", stiffness: 300 },
          });

          window.setTimeout(() => {
            goTo(-1)();
          }, 200);
          //
        } else {
          controls.start({
            x: 0,
            transition: { type: "spring", stiffness: 300 },
          }); // Snap back
        }
      }}
      animate={controls}
      className="fixed inset-0 bg-white"
    >
      {children}
    </motion.div>
  );
};

export default SwipeBack;
