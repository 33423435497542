import styled from "styled-components";

import CustomButton from "../../../helperComponents/CustomButton";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import InputCard from "../../../helperComponents/InputCard";
import logout from "../../../../controllers/logout";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useContext } from "react";
import Context from "../../../../Context";
import adminAccess from "../../../../data/adminAccess";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../helperComponents/TitleBar";
import LoadingSection from "../../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-top: 30px;
  align-items: center;
  gap: 50px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export default function SettingsPage() {
  const { loggedInUser, isAdmin } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUser)
    return (
      <LoggedInBoilerplate showBackgroundImage={true}>
        <TitleBar>{t("settingsPageTitle")}</TitleBar>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <TitleBar>{t("settingsPageTitle")}</TitleBar>
      <Container>
        <Inputs>
          <CustomButton onClick={logout}>{t("logout")}</CustomButton>
          {/* <CustomButton>Contact Support</CustomButton> */}
          <CustomButton href="/edit-profile">{t("editProfile")}</CustomButton>
          {isAdmin || loggedInUser.isEmployee ? (
            <CustomButton href="/admin/manage-activity-types">
              {t("adminPage")}
            </CustomButton>
          ) : null}
        </Inputs>
      </Container>
    </LoggedInBoilerplate>
  );
}
