import styled from "styled-components";
import Footer from "../loggedOut/landingPage/Footer";
import { useContext } from "react";
import Context from "../../../Context";
import { useTranslation } from "react-i18next";
import LoginWall from "./LoginWall";
import { motion } from "framer-motion";
import SwipeBack from "./SwipeBackGesture";

const Main = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Gap = styled.div`
  display: flex;
  height: 100px;
`;

export default function AnimatedPage({ children }) {
  return (
    <motion.div
      key="/page"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      initial={{ y: 0, x: 100, opacity: 0 }}
      animate={{ y: 0, x: 0, opacity: 1 }}
      exit={{ y: -10, x: -100, opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      {children}
    </motion.div>
  );
}
