import { useContext, useState } from "react";

import CustomDateInput from "../../../helperComponents/CustomDateInput";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import { BiCamera } from "react-icons/bi";
import styled from "styled-components";
import ImagePicker from "../../../editors/ImagePicker";
import { serverLine } from "../../../../controllers/network/serverLine";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../helperComponents/CustomButton";

const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const Text = styled.div`
  font-size: 15px;
  opacity: 0.7;
`;
export default function AskPhotoPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [value, setValue] = useState({ type: "DEFAULT", data: null });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <OnboardingBoilerplate
      title={t("onboardingPhotoTitle")}
      desc={t("onboardingPhotoDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <UploadSection>
        <ImagePicker
          forPerson={true}
          value={value}
          onChange={setValue}
        ></ImagePicker>
        {/* <Text>Toucher pour ajouter</Text> */}
      </UploadSection>

      <CustomButton
        style={{ marginBottom: "-10px", marginTop: "10px" }}
        onClick={skip}
        variant="minimal-underlined"
      >
        {t("onboardingSkip")}
      </CustomButton>
    </OnboardingBoilerplate>
  );

  async function skip() {
    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { skipProfileImage: true } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, skipProfileImage: true };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert(t("saved"));
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  async function onSubmit() {
    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { profileImage: value } });
      setLoading(false);

      let newLoggedInUser = { ...loggedInUser, profileImage: value };
      setLoggedInUser(newLoggedInUser);

      window.popupAlert(t("saved"));
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
