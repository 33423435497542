import { useTranslation } from "react-i18next";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import AdminCardsList from "../AdminCardsList";
import ManageActivityBookingCard from "./ManageActivityBookingCard";
import { useContext, useEffect, useState } from "react";
import ActivityTypeFilter from "./ActivityTypeFilter";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/network/serverLine";
import CustomButton from "../../../../helperComponents/CustomButton";
import { BiReset } from "react-icons/bi";
import BookingDateFilter from "./BookingDateFilter";
import getDateWithSlash from "../../../../../controllers/getDateWithSlash";
import Context from "../../../../../Context";

export default function ManageActivityBookingsPage({ dashboardType }) {
  const { isAdmin } = useContext(Context);
  const { t } = useTranslation();

  const [activityTypes, setActivityTypes] = useState(null);

  useEffect(() => {
    serverLine.get("/admin/activity-types").then(setActivityTypes);
  }, []);

  const [activityTypeID, setActivityTypeID] = useState(null);
  const [date, setDate] = useState(null);

  let urlQuery = getUrlQuery();

  useEffect(() => {
    if (urlQuery.activityTypeID) setActivityTypeID(urlQuery.activityTypeID);
    if (urlQuery.date) setDate(urlQuery.date);
  }, [urlQuery]);

  if (!activityTypes) return <LoadingSection />;

  console.log("activityTypeID", activityTypeID);

  let typeFilter = null;

  if (isAdmin) {
    //Employees can't use the type filter
    typeFilter = (
      <ActivityTypeFilter
        value={activityTypeID}
        onChange={setActivityTypeID}
        activityTypes={activityTypes}
      />
    );
  }

  let additionalButtons = [
    typeFilter,

    <BookingDateFilter value={date} onChange={setDate} />,

    <CustomButton
      icon={<BiReset />}
      onClick={() => {
        setActivityTypeID(null);
        setDate(null);
      }}
    />,
  ];

  return (
    <AdminCardsList
      additionalButtons={additionalButtons}
      title={t("adminActivityBookingTitle")}
      path={getPath()}
      dashboardType={dashboardType}
      CardComponent={ManageActivityBookingCard}
    />
  );

  function getPath() {
    if (!activityTypeID && !date) return `activity-bookings`;

    return `activity-bookings/?${objectToQueryString({ activityTypeID, date: date ? getDateWithSlash(new Date(date)) : null })}`;
  }

  function objectToQueryString(obj) {
    console.log("obj", obj);
    return Object.entries(obj)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  }
}
