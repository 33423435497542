import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { serverLine } from "../../../../controllers/network/serverLine";
import { useContext, useEffect, useState } from "react";
import LoadingSection from "../../../helperComponents/LoadingSection";
import ActivityRechargeBill from "../admin/purchaseRequests/ActivityRechargeBill";
import Context from "../../../../Context";
import { t } from "i18next";

const ExistingRequest = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  padding: 40px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExistingRequestText = styled.div`
  font-size: 12px;
  font-weight: 800;
  color: #c9d400;
  margin: 0;
  font-style: italic;
  text-align: center;

  text-transform: capitalize;

  font-family: "Montserrat", sans-serif;
`;

export default function ExistingActivityRecharge({ activityTypeIdVsData }) {
  const { loggedInUser } = useContext(Context);
  const [rechargeRequest, setRechargeRequest] = useState(null);

  const [loading, setLoading] = useState();

  let item = rechargeRequest;
  let setItem = setRechargeRequest;

  useEffect(() => {
    serverLine.get("/recharge-activity-request").then(setRechargeRequest);
  }, []);

  if (!item) return null;

  if (loading) return <LoadingSection />;

  return (
    <ExistingRequest>
      <ExistingRequestText>
        {t("activityRechargeRequestHasBeenSent")}
      </ExistingRequestText>

      <ActivityRechargeBill
        user={loggedInUser}
        rechargeRequestData={item.data}
        typeIdVsData={activityTypeIdVsData}
      />

      <PrimaryButton style={{ width: "150px" }} onClick={cancelRequest}>
        {t("cancel")}
      </PrimaryButton>
    </ExistingRequest>
  );

  async function cancelRequest() {
    setLoading(true);

    try {
      await serverLine.post("/cancel-recharge-activity-request");
    } catch (e) {
      window.popupAlert(e.message);
    }

    setItem(null);
    setLoading(false);
  }
}
