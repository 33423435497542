import BigToggle from "../../../../helperComponents/BigToggle";
import getDateWithSlash from "../../../../../controllers/getDateWithSlash";
import { useTranslation } from "react-i18next";
import SecondaryText from "../../../../detailsPage/SecondaryText";

export default function BookingDateOptions({
  value,
  onChange,
  label,
  activityType,
}) {
  const { t } = useTranslation();

  let cantDoMessage = <SecondaryText>{t("noUpcomingDates")}</SecondaryText>;

  if (!activityType.dates) return cantDoMessage;

  let allDates = getDatesList(activityType.dates);

  if (!allDates.length) return cantDoMessage;

  console.log("allDates", allDates, activityType, activityType.dates);
  return (
    <BigToggle
      title={label ? label : t("selectDate")}
      options={allDates.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(getDateWithSlash(item));
      },
      isSelected: value == getDateWithSlash(item),
      label: formatDateToDayMonth(item),
    };
  }
}

function formatDateToDayMonth(dateObj) {
  // Get the day of the month
  const day = dateObj.getDate();

  // Get the month name (short format)
  const month = dateObj.toLocaleString("en-US", { month: "short" });

  // Format and return the string as "12 Jun"
  return `${day} ${month}`;
}

function getDatesList(dateList) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight for accurate comparison

  dateList = dateList.filter((dateString) => {
    const date = new Date(dateString);
    return date >= today;
  });

  return dateList.map((item) => setToMidnight(new Date(item)));
}

function setToMidnight(dateObj) {
  // Set the time to 12:00 AM (00:00 hours)
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
}
