import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 20px;
  width: 650px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 21px;
  font-weight: 800;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function AdminSettingsCard({ item, index, items, setItems }) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    {
      type: "CATEGORY_EDITOR",
      name: "data.categories",
      label: t("adminSettingFormAdminCategories"),
    },
    {
      type: "STRING",
      name: "data.healthCheckupActivityTypeID",
      label: "Health Checkup Activity ID",
    },
  ];

  //

  if (!item.data) item.data = {};

  return (
    <Card>
      <Title> {t("adminSettingsTitle")} </Title>
      <Data>
        <FiledValue>
          Health Checkup Activity ID: {item.data.healthCheckupActivityTypeID}{" "}
        </FiledValue>

        <FiledValue>
          {t("adminSettingsCategories")}:{" "}
          {item.data.categories ? item.data.categories.length : 0}{" "}
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton onClick={editItem}>{t("editItem")}</CustomButton>
      </ActionButtons>
    </Card>
  );

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/settings"
        />
      ),
    });
  }
}
