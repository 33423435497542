import { useContext, useState } from "react";
import { styled } from "styled-components";
import Context from "../../Context.js";
import MaterialInput from "./MaterialInput.js";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const Searchbox = styled.input`
  padding: 15px 15px;
  background: transparent;
  border: none;
  border: 1px solid var(--translucentHard);
  border-radius: 10px;
  outline: none;
  color: var(--color);
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;
const Item = styled.div`
  padding: 15px 20px;
  cursor: pointer;

  background: linear-gradient(
    24deg,
    rgba(255, 255, 255, 0.1),
    rgb(255 255 255 / 4%)
  );
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  border-radius: 10px;

  &:hover {
    background: var(--accentColor);
    color: var(--bgColor);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
        background: var(--accentColor);
        color: var(--bgColor);
    `;
  }}
`;

export default function PopupSelect({ value, options, onChange, isLinkBased }) {
  const [query, setQuery] = useState("");
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  return (
    <Container>
      <MaterialInput
        autoFocus={true}
        onChange={({ target }) => {
          setQuery(target.value);
        }}
        label={t("typeHereToSearch")}
      />
      <List>{renderList()}</List>
    </Container>
  );

  function renderList() {
    let items = [];

    for (let item of options) {
      if (query) {
        let theQuery = query;
        theQuery = theQuery.toString();
        let toMatch = item.label.toString().toLowerCase();
        if (toMatch.indexOf(theQuery.toLowerCase()) === -1) {
          continue;
        }
      }
      items.push(
        <Item
          key={item.value}
          isActive={value == item.value}
          onClick={() => {
            if (!isLinkBased) setForm(null);
            onChange(item.value);
          }}
        >
          {item.label}
        </Item>
      );
    }

    return items;
  }
}
