import AddSession from "./AddSession";
import RechargeActivitySection from "./RechargeActivitySection";
import { useTranslation } from "react-i18next";

export default function AddSessionSections({
  activityTypes,
  newRecharge,
  setNewRecharge,
}) {
  const { t } = useTranslation();

  let items = [];

  for (let itemID in activityTypes) {
    let data = activityTypes[itemID];
    items.push(
      <AddSession
        type="SOLO"
        title={data.title}
        itemID={itemID}
        newRecharge={newRecharge}
        setNewRecharge={setNewRecharge}
      />
    );

    if (data.allowPairParticipation) {
      items.push(
        <AddSession
          type="PAIR"
          title={data.title + " (Pair) "}
          itemID={itemID}
          newRecharge={newRecharge}
          setNewRecharge={setNewRecharge}
        />
      );
    }

    if (data.allowTrioParticipation) {
      items.push(
        <AddSession
          type="TRIO"
          title={data.title + " (Trio)"}
          itemID={itemID}
          newRecharge={newRecharge}
          setNewRecharge={setNewRecharge}
        />
      );
    }
  }

  return (
    <RechargeActivitySection title={t("addActivity")}>
      {items}
    </RechargeActivitySection>
  );
}
