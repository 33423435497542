import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";
import CustomButton from "../../../../helperComponents/CustomButton";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Line1 = styled.div``;
const Line2 = styled.div`
  opacity: 0.5;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Quantity = styled.div`
  font-size: 15px;
  font-weight: 900;
  opacity: 0.5;
`;

export default function IncrementSession({
  type,
  activityTypes,
  quantity,
  activityTypeID,
  newRecharge,
  setNewRecharge,
}) {
  let activityType = activityTypes[activityTypeID];

  return (
    <Container>
      <Name>
        <Line1>
          {activityType.title} ({capitalizeFirstLetter(type.toLowerCase())})
        </Line1>
        <Line2>{formatNumber(getPrice())} CFA</Line2>
      </Name>

      <InputSection>
        <CustomButton onClick={doChange(-1)}>
          <AiOutlineLeft />
        </CustomButton>

        <Quantity>{quantity}</Quantity>

        <CustomButton onClick={doChange(1)}>
          <AiOutlineRight />
        </CustomButton>
      </InputSection>
    </Container>
  );

  function getPrice() {
    if (type == "SOLO") {
      return activityType.price;
    } else if (type == "PAIR") {
      return activityType.pairPrice;
    } else if (type == "TRIO") {
      return activityType.trioPrice;
    }
  }

  function doChange(theChange) {
    return () => {
      let newData = { ...newRecharge };
      let newQ = quantity + theChange;

      if (newQ < 0) return null;

      newData[type][activityTypeID] = newQ;
      setNewRecharge(newData);
    };
  }
}
