import BigToggle from "../../../../helperComponents/BigToggle";

export default function ScheduleHourPicker({
  value,
  onChange,
  rangeData,
  label,
}) {
  let beginAt = 1;
  let endAt = 24;

  if (rangeData) {
    if (rangeData.startingTime) {
      beginAt = rangeData.startingTime;
    }

    if (rangeData.endingTime) {
      endAt = rangeData.endingTime;
    }
  }

  let allHours = getHourSlots(beginAt, endAt);

  // return JSON.stringify(allHours);

  return (
    <BigToggle
      title={label ? label : "Select Hour"}
      options={allHours.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    if (item.isBreakTime)
      return {
        onClick: null,
        isSelected: null,
        label: "Break Time",
      };

    return {
      onClick: () => {
        onChange(item.value);
      },
      isSelected: value === item.value,
      label: item.label,
    };
  }

  function getHourSlots(startHour, endHour) {
    let timeSlots = [];

    // Loop through each hour from startHour to endHour
    for (let hour = startHour; hour < endHour; hour++) {
      // Format the slot as "hour - hour+1"

      let slot = `${addAdditionalZero(hour)}:00  -  ${addAdditionalZero(hour + 1)}:00`;

      if (!rangeData) slot = `${addAdditionalZero(hour)} : 00`;

      let isBreakTime = isBreakHour(hour);

      timeSlots.push({ label: slot, value: hour, isBreakTime });
    }

    return timeSlots;
  }

  function addAdditionalZero(num) {
    num = num.toString();

    if (num.length == 1) return `0${num}`;
    return num;
  }

  function isBreakHour(hourNumber) {
    if (!rangeData) return false;

    let start = rangeData.breakTimeStart;
    let ending = rangeData.breakTimeEnding;

    if (start && ending) {
      if (hourNumber >= start && hourNumber < ending) {
        return true;
      }
    }

    return false;
  }
}
