import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";
import formatDate from "../../../../../controllers/formatDate";
import calculateExactMinsForBooking from "../../../../../controllers/calculateExactMinsForBooking";
import getDateAndTimeOfBooking from "../../../../../controllers/getDateAndTimeOfBooking";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;

  flex-direction: column;
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const Name = styled.div`
  font-size: 21px;

  width: 90%;

  font-weight: 900;

  /* font-size: 18px; */
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
  opacity: 0.7;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

export default function ManageActivityBookingCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm, adminSettings } = useContext(Context);
  const { t } = useTranslation();

  let fields = getFields();

  if (forAddingNewItem) return null;

  if (!item.user) return <Card>The user does not exists</Card>;

  return (
    <Card>
      <ProfileContainer>
        {item.user ? (
          <Image
            src={getImageURL(item.user.profileImage, true)}
            alt="Profile Image"
          />
        ) : (
          <Image src={getImageURL(null, true)} alt="Profile Image" />
        )}

        <ProfileText>
          {item.user ? (
            <Name>
              {item.user.firstName} {item.user.lastName}
            </Name>
          ) : (
            item.nonMemberEmail
          )}

          <Status style={{ textTransform: "unset" }}>
            Email: {item.user.email}
          </Status>

          <Status>Participation Type : {item.participationCountType}</Status>

          <Status>
            {t("adminActivityBookingActivityType")} : {item.activityType.title}
          </Status>

          <Status>
            {getDateAndTimeOfBooking({
              activityType: item.activityType,
              bookingData: item,
            })}
          </Status>
        </ProfileText>
      </ProfileContainer>

      <ActionButtons>
        <CustomButton style={{ background: "#000" }} onClick={editItem}>
          {t("adminActivityBookingAddReport")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function getFields() {
    let generalReportFields = [
      {
        type: "FILE",
        name: "reportFile",
        label: t("adminActivityBookingReportPDF"),
      },
      {
        type: "LONG_STRING",
        name: "remark",
        label: t("adminActivityBookingFormRemark"),
        placeholder: "Type remark here",
      },
      {
        type: "LONG_STRING",
        name: "remark2",
        label: t("adminActivityBookingFormRemark2"),
        placeholder: "Type remark here",
      },
      {
        type: "LONG_STRING",
        name: "remark3",
        label: t("adminActivityBookingFormRemark3"),
        placeholder: "Type remark here",
      },
    ];

    let healthCheckupReportFields = [
      { type: "FILE", name: "reportFile", label: "PDF" },
      {
        type: "NUMBER",
        name: "reportData.weight",
        label: t("adminHealthCheckupsWeight"),
        // placeholder: "Type weight here",
      },
      {
        type: "NUMBER",
        name: "reportData.leanBodyMass",
        label: t("adminHealthCheckupsLeanBodyMass"),
        // placeholder: "Type lean body mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.muscleMass",
        label: t("adminHealthCheckupsMuscleMass"),
        // placeholder: "Type muscle mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.bodyWater",
        label: t("adminHealthCheckupsBodyWater"),
        // placeholder: "Type body water here",
      },
      {
        type: "NUMBER",
        name: "reportData.bodyFatMass",
        label: t("adminHealthCheckupsBodyFatMass"),
        // placeholder: "Type body fat mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.inorganicSalts",
        label: t("adminHealthCheckupsInorganicSalt"),
        // placeholder: "Type inorganic salt here",
      },
      {
        type: "NUMBER",
        name: "reportData.protein",
        label: t("adminHealthCheckupsProtein"),
        // placeholder: "Type protein here",
      },
      {
        type: "LONG_STRING",
        name: "remark",
        label: t("adminHealthCheckupsRemark"),
      },
    ];

    if (adminSettings) {
      if (
        adminSettings.data.healthCheckupActivityTypeID == item.activityType._id
      )
        return healthCheckupReportFields;
    }

    return generalReportFields;
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/activity-booking"
        />
      ),
    });
  }
}
