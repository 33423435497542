import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import getImageURL from "../../../../../controllers/getImageURL";
import { serverLine } from "../../../../../controllers/network/serverLine";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import goTo from "../../../../../controllers/goTo";
import SemanticButton from "../../../../helperComponents/SemanticButton";
import Context from "../../../../../Context";
import { IoTimeOutline } from "react-icons/io5";
import { HiOutlineUser } from "react-icons/hi2";
import DetailsSection from "../../../../detailsPage/DetailsSection";
import DetailsPageBoilerplate from "../../../../detailsPage/DetailsPageBoilerplate";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 90vw;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
    padding: 0;
    width: 90vw;
  }
`;

const CoachAndDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    gap: 80px;
    width: 100%;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  font-size: 30px;
  opacity: 0.7;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  opacity: 0.7;
  white-space: pre-line;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  &::before {
    content: "•";
    color: #c9d400;
    margin-right: 8px;
  }

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const EmployeeData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EmployeeName = styled.div`
  font-weight: 800;
  opacity: 0.5;
  font-style: italic;
  font-weight: 800;

  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
`;

const EmployeeDesc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;
  white-space: pre-line;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const IconMainText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  font-weight: 600;
  display: flex;
  opacity: 0.7;

  font-family: "Montserrat", sans-serif;

  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const IconSecondaryText = styled.div`
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  text-transform: capitalize;
  font-weight: 800;
  display: flex;

  font-family: "Montserrat", sans-serif;

  color: var(--accentColor);
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;

function ActivityDetails({ item, bookingData, doLoading }) {
  const { membershipPlans, updateMembershipPlans, isMobile } =
    useContext(Context);
  const [isBooked, setIsBooked] = useState(bookingData);
  const [state, setState] = useState(item);
  const [bookingInProgress, setBookingInProgress] = useState(false);

  useEffect(() => {
    updateMembershipPlans();
  }, []);

  const { t } = useTranslation();

  let bookButtons = [
    <PrimaryButton
      onClick={() => {
        doBooking("SOLO");
      }}
      style={{ width: "100%", borderRadius: "10px" }}
    >
      Réserver
    </PrimaryButton>,
  ];

  if (item.activityType.allowPairParticipation) {
    bookButtons.push(
      <PrimaryButton
        onClick={() => {
          doBooking("PAIR");
        }}
        style={{ width: "100%", borderRadius: "10px" }}
      >
        Réserver (Pair)
      </PrimaryButton>
    );
  }

  if (item.activityType.allowTrioParticipation) {
    bookButtons.push(
      <PrimaryButton
        onClick={() => {
          doBooking("TRIO");
        }}
        style={{ width: "100%", borderRadius: "10px" }}
      >
        Réserver (Trio)
      </PrimaryButton>
    );
  }

  let cancelBookingButton = null;

  if (bookingData)
    cancelBookingButton = (
      <PrimaryButton
        onClick={() => {
          if (bookingData) doBooking(bookingData.participationCountType);
        }}
        textStyle={{ color: "#999" }}
        icon={null}
        style={{
          width: "100%",
          borderRadius: "10px",
          background: "#000",
          border: "1px solid #333",
          boxShadow: "none",
        }}
      >
        {t("activityItemCancelBooking")} ({bookingData.participationCountType})
      </PrimaryButton>
    );

  if (!membershipPlans) return <LoadingSection />;
  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  let theButton = isBooked ? cancelBookingButton : bookButtons;

  if (bookingInProgress) theButton = <LoadingSection />;

  let showReportButton = null;

  if (bookingData) {
    if (bookingData.reportFile) {
      theButton = null;

      showReportButton = (
        <PrimaryButton
          onClick={goTo(getImageURL(bookingData.reportFile))}
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        >
          {t("activityItemShowReport")}
        </PrimaryButton>
      );
    }
  }

  let textContent = (
    <>
      <CoachAndDuration>
        <SemanticButton
          onClick={goTo("/employee/?itemID=" + item.employee._id)}
        >
          <IconAndText style={{ cursor: "pointer" }}>
            <Icon>
              <HiOutlineUser />
            </Icon>
            <IconText>
              <IconMainText> {t("activityItemCoach")} </IconMainText>
              <IconSecondaryText>{item.employee.name} </IconSecondaryText>
            </IconText>
          </IconAndText>
        </SemanticButton>

        <IconText>
          <IconAndText>
            <Icon>
              <IoTimeOutline />
            </Icon>

            <IconText>
              <IconMainText>{t("activityItemDuration")}</IconMainText>
              <IconSecondaryText>{item.duration} min</IconSecondaryText>
            </IconText>
          </IconAndText>
        </IconText>
      </CoachAndDuration>

      <Text>{item.activityType.description}</Text>

      <DetailsSection title={t("activityItemDetails")}>
        <List>
          <ListItem>
            {t("activityItemParticipationType")}:{" "}
            {item.participationType ? t(item.participationType) : "N/A"}
          </ListItem>
          <ListItem>
            {t("activityItemMembershipType")}:{" "}
            {item.activityType.membershipPlanIDs
              ? item.activityType.membershipPlanIDs
                  .map((itemID) => {
                    let item = membershipIdVsPlan[itemID];
                    return item.name;
                  })
                  .join(", ")
              : "N/A"}
          </ListItem>
        </List>
      </DetailsSection>

      {bookingData ? (
        bookingData.remark ? (
          <DetailsSection title={t("activityItemRemark")}>
            <List>
              <ListItem>{bookingData.remark}</ListItem>
              {bookingData.remark2 ? (
                <ListItem>{bookingData.remark2}</ListItem>
              ) : null}
              {bookingData.remark3 ? (
                <ListItem>{bookingData.remark3}</ListItem>
              ) : null}
            </List>
          </DetailsSection>
        ) : null
      ) : null}

      <DetailsSection title={t("activityItemAboutCoach")}>
        <EmployeeData>
          <EmployeeName>{item.employee.name}</EmployeeName>
          <EmployeeDesc>{item.employee.description}</EmployeeDesc>
        </EmployeeData>
      </DetailsSection>
    </>
  );

  return (
    <DetailsPageBoilerplate
      topRightData={
        item.participationType ? item.participationType.toLowerCase() : null
      }
      title={item.activityType.title}
      secondaryTitle={`${state.seats - state.seatsBooked} Seats Left`}
      imageData={item.activityType.image}
      buttons={[showReportButton, theButton]}
    >
      {textContent}
    </DetailsPageBoilerplate>
  );

  async function doBooking(participationCountType) {
    try {
      setBookingInProgress(true);

      await serverLine.post("/activity-booking", {
        activityID: item._id,
        participationCountType: participationCountType,
        action: isBooked ? "UNBOOK" : "BOOK",
      });

      let newState = { ...state };

      let peopleCount = 0;

      if (participationCountType === "PAIR") {
        peopleCount = 2;
      } else if (participationCountType === "TRIO") {
        peopleCount = 3;
      }

      if (isBooked) {
        newState.seatsBooked -= peopleCount;
      } else {
        newState.seatsBooked += peopleCount;
      }

      setState(newState);

      window.popupAlert("Done");

      setIsBooked(isBooked ? false : true);
      setBookingInProgress(false);
    } catch (e) {
      if (e.message.toUpperCase() == "PLEASE! PURCHASE ACTIVITY") {
        goTo("/recharge-activity")();
      }

      window.popupAlert(e.message);
      setBookingInProgress(false);
    }

    await doLoading(true);
  }
}

export default ActivityDetails;
