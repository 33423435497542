import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import calculateAge from "../../../../../controllers/calculateAge";
import parseDate from "../../../../../controllers/parseDate";
import parseExpiresAt from "../../../../../controllers/parseExpiresAt";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../helperComponents/CustomButton";
import MaterialInput from "../../../../helperComponents/MaterialInput";
import DropDownInput from "../../../../helperComponents/DropDownInput";
import { serverLine } from "../../../../../controllers/network/serverLine";
import ActivityRechargeBill from "./ActivityRechargeBill";
import calculateActivityRechargeTotal from "../../../../../controllers/payment/calculateActivityRechargeTotal";
import Context from "../../../../../Context";
import transformToIdValuePair from "../../../../../controllers/utils/transformToIdValuePair";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  align-items: center;
  gap: 40px;
  width: auto;
  border-radius: 10px;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ImageSection = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title2 = styled.div``;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
`;

export default function ActivityRechargeRequestCard({
  item,
  activityTypeIdVsData,
}) {
  const { membershipPlans } = useContext(Context);

  let membershipPlansDoc = transformToIdValuePair(membershipPlans);

  const [dealt, setDealt] = useState(false);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    calculateAmount();
  }, []);

  if (dealt) return null;

  return (
    <Section>
      <TopPart>
        <ImageSection
          src={getImageURL(item.author.profileImage, true)}
        ></ImageSection>

        <Text>
          <Title>
            {item.author.firstName} {item.author.lastName}
          </Title>

          <SubHeading>
            <Title2>{item.author.phoneNumber}</Title2>
            <Title2>Age: {calculateAge(item.author.dateOfBirth)}</Title2>
          </SubHeading>
        </Text>
      </TopPart>

      <ActivityRechargeBill
        user={item.author}
        typeIdVsData={activityTypeIdVsData}
        rechargeRequestData={item.data}
      />

      <Inputs>
        <CustomButton
          onClick={submit}
          style={{ width: "100%", background: "#000" }}
        >
          Accept
        </CustomButton>

        <CustomButton
          onClick={deleteItem}
          style={{ width: "100%", background: "#000" }}
        >
          Delete
        </CustomButton>
      </Inputs>
    </Section>
  );

  function calculateAmount() {
    let total = 0;

    total = calculateActivityRechargeTotal({
      activityTypes: activityTypeIdVsData,
      rechargeDoc: item.data,
    });

    if (item.author.membershipPlanID) {
      let membership = membershipPlansDoc[item.author.membershipPlanID];

      if (membership) {
        if (membership.isRoyalty) {
          total = total * 0.85;
        }
      }
    }

    return total;
  }

  function submit() {
    serverLine.post("/admin/activity-recharge-request", {
      requestID: item._id,

      action: "ACCEPT",
      amount,
    });
    window.popupAlert("Done");
    setDealt(true);
  }

  function deleteItem() {
    serverLine.post("/admin/activity-recharge-request", {
      requestID: item._id,
      action: "DENY",
    });
    window.popupAlert("Done");
    setDealt(true);
  }
}
