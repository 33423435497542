import styled from "styled-components";
import ActivityRechargeBalanceLine from "./ActivityRechargeBalanceLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function ActivityRechargeBalanceSlip({
  typeIdVsData,
  rechargeRequestData,
}) {
  let list = [];

  for (let type in rechargeRequestData) {
    let items = rechargeRequestData[type];

    for (let itemID in items) {
      let quantity = items[itemID];

      if (!quantity) continue;
      list.push(
        <ActivityRechargeBalanceLine
          type={type}
          typeIdVsData={typeIdVsData}
          itemID={itemID}
          quantity={quantity}
        />
      );
    }
  }

  return <Container>{list}</Container>;
}
