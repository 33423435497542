import { useContext } from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";
import getImageURL from "../../../../controllers/getImageURL";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const UserImage = styled.img`
  height: 40px;
  border-radius: 100px;
  width: 40px;
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  gap: 7px;
  text-transform: capitalize;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 3px;
  }
`;

const Hello = styled.div`
  opacity: 0.5;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 900px) {
    font-size: 9px;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export default function HelloUserSection() {
  const { loggedInUser } = useContext(Context);
  const { t } = useTranslation();
  let firstName = loggedInUser.firstName;

  return (
    <Container>
      <UserImage src={getImageURL(loggedInUser.profileImage)} />
      <Text>
        <Hello>{t("hello")}!</Hello> <Name> {firstName}</Name>
      </Text>
    </Container>
  );
}
