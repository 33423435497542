import BigToggle from "../../../../helperComponents/BigToggle";
import getDateWithSlash from "../../../../../controllers/getDateWithSlash";
import { useContext } from "react";
import Context from "../../../../../Context";
import daysArray from "../../../../../data/daysArray";

export default function ScheduleDatesPicker({
  value,
  onChange,
  label,
  forHealthCheckup,
}) {
  const { adminSettings } = useContext(Context);
  let allDates = getOneMonthDates();

  if (!value) value = [];

  return (
    <BigToggle
      title={label ? label : "Select Date"}
      options={allDates.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    let allowedDate = thisDateIsAvailable(item.getDay());

    if (!allowedDate)
      return {
        onClick: null,
        isSelected: false,
        label: formatDateToDayMonth(item),
      };

    return {
      onClick: () => {
        let theNewDate = getDateWithSlash(item);

        let newValue = [...value];

        newValue = ifArrayIsTooBigShortenIt({
          maxLength: 35,
          theArray: newValue,
        });

        if (!newValue.includes(theNewDate)) {
          newValue.push(theNewDate);
        } else {
          let indexOfItem = newValue.indexOf(theNewDate);
          newValue.splice(indexOfItem, 1);
        }
        onChange(newValue);
      },
      isSelected: value.includes(getDateWithSlash(item)),
      label: formatDateToDayMonth(item),
      secondaryLabel: getDayOfWeek(getDateWithSlash(item)),
    };
  }

  function thisDateIsAvailable(theDay) {
    let theDateStr = daysArray[theDay];

    console.log(theDateStr);

    if (!theDateStr) return true;

    if (!forHealthCheckup) return true;

    if (adminSettings) {
      let data = adminSettings.data;

      if (data.healthCheckupAvailableDays) {
        let theList = data.healthCheckupAvailableDays;
        if (!theList.includes(theDateStr)) return false;
      }
    }

    return true;
  }
}

function formatDateToDayMonth(dateObj) {
  // Get the day of the month
  const day = dateObj.getDate();

  // Get the month name (short format)
  const month = dateObj.toLocaleString("en-US", { month: "short" });

  // Format and return the string as "12 Jun"
  return `${day} ${month}`;
}

function getOneMonthDates() {
  let dates = [];

  let currentDate = new Date();
  // Loop through the next 7 days, including the current day
  for (let i = 0; i <= 31; i++) {
    let nextDate = new Date(); // Copy the current date
    nextDate.setDate(currentDate.getDate() + i); // Add 'i' days
    dates.push(setToMidnight(nextDate));
  }

  return dates;
}

function setToMidnight(dateObj) {
  // Set the time to 12:00 AM (00:00 hours)
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
}

function getDayOfWeek(dateString) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(dateString);
  return days[date.getDay()];
}

function ifArrayIsTooBigShortenIt({ maxLength, theArray }) {
  if (theArray.length <= maxLength) return theArray;

  theArray.slice(maxLength, theArray.length - 1);
}
