import styled from "styled-components";
import Footer from "../loggedOut/landingPage/Footer";
import { useContext } from "react";
import Context from "../../../Context";
import { useTranslation } from "react-i18next";
import LoginWall from "./LoginWall";
import { motion } from "framer-motion";
import SwipeBack from "./SwipeBackGesture";
import AnimatedPage from "./AnimatedPage";
import { Capacitor } from "@capacitor/core";

const Main = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Gap = styled.div`
  display: flex;
  height: 100px;
`;

export default function LoggedInBoilerplate({ children, showFooter }) {
  const { loggedInUserID, loggedInUser } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUserID) return <LoginWall />;

  let style = {};

  let platform = Capacitor.getPlatform();

  if (platform == "ios") {
    document.body.style.marginTop = "50px";
    // style.marginTop = "50px";
  }
  // return <LoadingSection />;
  return (
    <SwipeBack>
      <AnimatedPage>
        <Main style={style}>
          {children}
          <Gap />
          {showFooter ? <Footer /> : null}
        </Main>
      </AnimatedPage>
    </SwipeBack>
  );
}
