import styled from "styled-components";
import BrandContainer from "../../../brand/BrandContainer";
import WithBackground from "../../../core/boilerplate/WithBackground";
import InputCard from "../../../helperComponents/InputCard";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../helperComponents/CustomButton";
import logout from "../../../../controllers/logout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  height: 100dvh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 100px;
  width: 100vw;
`;

export default function OnboardingBoilerplate({
  children,
  onSubmit,
  title,
  desc,
  submitButtonText,
  loading,
  desktopWidth,
}) {
  const { t } = useTranslation();

  if (loading) {
    children = <LoadingSection />;
    onSubmit = null;
  }

  return (
    <WithBackground backgroundImage={"/dumbbells.jpg"}>
      <Container>
        <BrandContainer />
        <InputCard
          desktopWidth={desktopWidth}
          title={title}
          desc={desc}
          onSubmit={onSubmit}
          submitButtonText={
            submitButtonText ? submitButtonText : t("onboardingSubmit")
          }
        >
          {children}
        </InputCard>
        <CustomButton
          style={{ opacity: 0.3 }}
          variant="minimal"
          underlined={true}
          onClick={logout}
        >
          {t("logout")}
        </CustomButton>
      </Container>
    </WithBackground>
  );
}
