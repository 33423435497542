import { useTranslation } from "react-i18next";
import DropDownInput from "../../../../helperComponents/DropDownInput";

export default function ActivityTypeFilter({ value, onChange, activityTypes }) {
  const { t } = useTranslation();
  return (
    <DropDownInput
      style={{ background: "var(--translucent)" }}
      label={t("activityType")}
      placeholder={t("clickToSelect")}
      value={value}
      onChange={onChange}
      options={activityTypes.map((item) => getItem(item))}
    />
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(item._id);
      },
      value: item._id,
      isSelected: value === item._id,
      label: `${item.title}`,
    };
  }
}
