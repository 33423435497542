import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useEffect, useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import fetchDataWithCaching from "../../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";
import ActivityTypeDetails from "./ActivityTypeDetails";
import ActivityBookingDetails from "./ActivityBookingDetails";

export default function ActivityBookingPage() {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    doLoading(false);
  }, []);

  async function doLoading(skipCache) {
    if (skipCache) setLoading(true);
    let urlQuery = getUrlQuery();

    let newData = await fetchDataWithCaching({
      path: `/activity-booking/?itemID=${urlQuery.itemID}`,
      skipCache,
      setLoading,
      onFreshData: setItem,
    });

    setLoading(false);
    setItem(newData);
  }

  async function handleRefresh() {
    await doLoading(true);
  }

  if (loading || !item)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate>
      <PullToRefresh onRefresh={handleRefresh}>
        <ActivityBookingDetails
          doLoading={doLoading}
          activityType={item.activityType}
          bookingData={item}
        />
      </PullToRefresh>
    </LoggedInBoilerplate>
  );
}
