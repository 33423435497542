import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";
import SecondaryText from "../../../../detailsPage/SecondaryText";

export default function ActivityRechargeBalanceLine({
  type,
  typeIdVsData,
  quantity,
  itemID,
}) {
  return (
    <SecondaryText>
      {quantity} {typeIdVsData[itemID].title} (
      {capitalizeFirstLetter(type.toLowerCase())})
    </SecondaryText>
  );
}
