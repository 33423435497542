import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Title = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  color: var(--accentColor);
  margin: 0;
  padding: 0;
  font-style: italic;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
`;

export default function RechargeActivitySection({ children, title }) {
  return (
    <Container>
      <Title>{title}</Title>
      <List>{children}</List>
    </Container>
  );
}
