import { Capacitor } from "@capacitor/core";

export default function getLinkToS3(fileName) {
  let environment = "production";

  if (window.location.host.indexOf("localhost") !== -1) {
    environment = "development";
  }

  if (window.location.host.indexOf("192.168") !== -1) {
    environment = "development";
  }

  let platform = Capacitor.getPlatform();

  if (platform !== "web") {
    let isDev = window.location.href.startsWith("http://");

    if (isDev) {
      environment = "development";
    } else {
      environment = "production";
    }
  }

  console.log("environment");

  let useCDN = true;

  let base = "http://s3-storage.upon.one.s3.ap-south-1.amazonaws.com";

  if (useCDN) {
    base = `https://s3-storage.upon.one`;
  }

  base = "https://dfeufrvwhbhf8.cloudfront.net";

  return `${base}/${environment}/wellness/${fileName}`;
}
