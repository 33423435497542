import { useTranslation } from "react-i18next";
import MaterialInput from "../../../../helperComponents/MaterialInput";

export default function BookingDateFilter({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <MaterialInput
      style={{ width: "400px" }}
      label={t("selectDate")}
      type="date"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
