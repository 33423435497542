import styled from "styled-components";

const SectionTitle = styled.div`
  color: var(--accentColor);
  font-size: 19px;
  max-width: 300px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-transform: uppercase;
  font-style: italic;
`;

export default SectionTitle;
