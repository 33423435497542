import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { serverLine } from "../../../../controllers/network/serverLine";
import MembershipPlanRequested from "./MembershipPlanRequested";
import { useContext } from "react";
import Context from "../../../../Context";
import AskMembershipPlanConfirmation from "./AskMembershipPlanConfirmation";
import formatNumber from "../../../../controllers/utils/formatNumber";
import CheckoutPopup from "../../../purchase/CheckoutPopup";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
  /* padding-right: 50px; */

  color: #ffffff;

  gap: 30px;
  font-weight: bold;
  font-size: 20px;

  padding: 20px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;

  padding-bottom: 35px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const CardTitle = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const Features = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0.8;
  flex-direction: column;
`;

const PlanName = styled.span`
  color: var(--accentColor);
  font-size: 19px;
  max-width: 300px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-transform: uppercase;
  font-style: italic;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 700;
  max-width: 300px;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
`;

const PriceTag = styled.div`
  color: var(--accentColor);
  font-size: 13px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const Validity = styled.div`
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;

  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const LoggedInMembershipPlanCard = ({
  onChange,
  price,
  validityInWeeks,
  title,
  desc,
  name,
  features,
  setLoading,
  membershipPlanID,
  loadStuff,
}) => {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();
  return (
    <Card
      onClick={() => {
        if (onChange) onChange(name);
      }}
    >
      <CardTitle>
        <PlanName>{title}</PlanName>
        <PriceTag>{formatNumber(price)} CFA</PriceTag>
        <Validity>
          {t("validity")} : {convertWeeks(validityInWeeks)}
        </Validity>
      </CardTitle>
      <PlanDescription>{desc}</PlanDescription>

      <Features>{features}</Features>

      <PrimaryButton
        onClick={showPopup}
        style={{ width: "100%", borderRadius: "10px" }}
      >
        {t("requestMembership")}
      </PrimaryButton>
    </Card>
  );

  function convertWeeks(weeks) {
    if (weeks >= 52) {
      const year = Math.round(weeks / 52);
      return `${year} An`;
    } else if (weeks >= 12) {
      const months = Math.round(weeks / 4);
      return `${months} Mois`;
    } else {
      return `${weeks} ${t("weeks")}`;
    }
  }

  function showPopup() {
    setForm({
      // title: "Request plan",
      component: (
        <CheckoutPopup
          purchaseDetails={title}
          onCashSelection={sendRequest}
          totalAmount={price}
        />
      ),
    });
  }

  async function sendRequest(settings = {}) {
    let { onSuccess, onFailure } = settings;

    let isDone = false;

    try {
      await serverLine.post("/membership-request", {
        membershipPlanID: membershipPlanID,
      });

      isDone = true;
    } catch (e) {
      onFailure();
      window.popupAlert(e.message);
      setLoading(false);
    }

    await loadStuff();

    if (isDone) {
      if (onSuccess) {
        onSuccess(true);
      }
    }
  }
};

export default LoggedInMembershipPlanCard;
