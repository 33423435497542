import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import universalLocalStorage from "./utils/lib/universalLocalStorage";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      aRequestIsPending: "There is a request already pending",

      noMembershipPlanSelected: "No membership plan selected",
      youHaveSubscribedTo: "Your activity balance is following",
      noUpcomingDates: "There is no upcoming dates for this activity",
      activityType: "Activity Type",
      clickToSelect: "Click to select",
      duration: "Duration",
      checkout: "Checkout",
      typeHereToSearch: "Type Here To Search",
      submit: "Submit",
      activities: "Activities",
      addActivity: "Add Activity",
      save: "Save",
      saved: "Saved",
      cancel: "Cancel",
      weeks: "Weeks",
      booking: "Booking",
      bookings: "Bookings",
      allFieldsAreRequired: "All Fields Are Required",
      pleasePurchase3dVisbodyFirst: "Please! Purchase 3D Visbody First",
      yourRequestHasBeenSent: "Your request has been sent",

      activityRechargeRequestHasBeenSent:
        "Activity Recharge Request Has Been Sent",
      currentMembershipPlan: "Current Membership Plan", //
      membershipPageActivityTitle: "Choose individual activities",
      membershipPageActivitySecondaryTitle:
        "Discover our activities without membership",
      activityBalance: "Activity Balance",
      recharge: "Recharge",
      recharge2: "Recharge",
      currentPlan: "Current Plan",
      //Logged Out Hero Section
      hello: "Hello",
      noMembershipPlan: "No plan",
      price: "Price",
      validity: "Validity",

      loggedOutHeroTitle: "Move Better, Live Better",
      loggedOutHeroTitleLine1: "Move Better",
      loggedOutHeroTitleLine2: "Live Better",
      loggedOutHeroDesc:
        "Join us for a wellness journey that brings you closer to your health goals.",
      becomeAMember: "Become a member",
      alreadyAMember: "Already a member",
      eliteTrainer: "Elite Trainers",
      regularHealthCheckups: "Regular Health Checkups",
      cuttingEdgeEquipments: "Cutting Edge Equipment",
      exclusiveEvents: "Exclusive Events",

      //buy-activity
      checkEmailForFurtherDetails: "Check your email for further details",

      //Logged Out Header
      whyChooseUs: "Why Choose Us?",
      ourTrackRecord: "Our Track Record",
      bookActivity: "Book Activity",
      membership: "Membership",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      loginOrSignup: "Login / Signup",

      //Logged Out Why Choose Us Section
      whyChooseUsSectionTitle: "Let's Talk Details",
      whyChooseUsLine1: "Elite Trainers, Extraordinary Results",
      whyChooseUsLine1Desc:
        "Our world-class trainers are the cornerstone of your fitness journey. With extensive experience and top-notch certifications, they tailor workout plans to your unique goals and needs. Whether you're aiming for weight loss, muscle gain, or overall wellness, our trainers provide the motivation, expertise, and support to help you achieve remarkable results.",
      whyChooseUsLine2: "Holistic Health: Regular Checkups Included",
      whyChooseUsLine2Desc:
        "Your health is our top priority. That's why we offer regular health checkups to all our members, ensuring you stay on track with your fitness goals while maintaining overall well-being. Our professional health assessments include detailed evaluations and personalized advice from our experienced medical staff.",
      whyChooseUsLine3: "Cutting-Edge Equipment for Peak Performance",
      whyChooseUsLine3Desc:
        "we believe that the right equipment is essential for achieving optimal fitness. Our gym is outfitted with the latest and most advanced fitness machines, ensuring a comprehensive and effective workout experience. From high-tech cardio machines to state-of-the-art strength training gear, we provide everything you need to push your limits and reach new heights in your fitness journey.",

      //Our Track Record
      ourTrackRecordSectionTitle: "Out Track Record",
      ourTrackRecord1: "4 Plus years",
      ourTrackRecord1Desc: "Of Experience",
      ourTrackRecord2: "500 Plus",
      ourTrackRecord2Desc: "Happy Members",
      ourTrackRecord3: "20 Plus",
      ourTrackRecord3Desc: "Personal Trainers",

      //Our Track Record
      testimonialsSectionTitle: "Testimonials",
      testimonialsSectionDesc: "Customer Testimonials",

      //Membership Plan Title
      //Plan one
      membershipPlanTitle: "Membership Plan",
      mostPopular: "Most Popular",
      choosePlan: "Choose Plan",
      membershipPlanOnePrice: "CFA 75000/month",
      membershipPlanOneName: "Platinum",
      membershipPlanOneDesc: "Unlock your full potential",
      membershipPlanOneBullet1: "Gold Plan Benefits",
      membershipPlanOneBullet2: "Weekly Health Checkup",
      membershipPlanOneBullet3: "Exclusive Workshops",
      membershipPlanOneBullet4: "Top Trainers",
      membershipPlanOneBullet5: "Discount on Products",

      //plan two
      membershipPlanTwoPrice: "CFA 30000/month",
      membershipPlanTwoName: "Gold",
      membershipPlanTwoDesc: "Get Serious",
      membershipPlanTwoBullet1: "Basic Plan Benefits",
      membershipPlanTwoBullet2: "Nutritional Guidance",
      membershipPlanTwoBullet3: "2 Health Checkups",
      membershipPlanTwoBullet4: "Advanced Training",

      //plan three
      membershipPlanThreePrice: "CFA 15000/month",
      membershipPlanThreeName: "Silver",
      membershipPlanThreeDesc: "Light On Budget",
      membershipPlanThreeBullet1: "Access to Gym",
      membershipPlanThreeBullet2: "One Health Checkup",
      membershipPlanThreeBullet3: "Locker Room Access",

      //Take Membership Popup
      confirmMembershipTitle: "Confirm subscribing to this plan",
      confirmMembershipConfirmButton: "Subscribe",
      confirmMembershipCancelButton: "Cancel",

      membershipRequestSentTitle: "Your request has been sent",
      membershipRequestSentText: "Please! deposit your money on the reception",

      //About us section
      aboutUsSectionTitle: "About Us",
      aboutUsSectionDesc: `In the bustling life of a city, where life moved at a relentless pace, there existed a gym unlike any other. It wasn’t just a place filled with weights, treadmills, and mirrors reflecting sweat-drenched faces. This gym, known as Pulse, was built on the belief that true fitness was more than just physical—it was about holistic growth, where the mind, body, and soul worked in harmony. The Beginning: A Vision for People Pulse was the brainchild of Maya Patel, a fitness enthusiast with a heart as big as her dreams. Maya had spent years working in traditional gyms, where the focus was often on quick gains and flashy results. But she knew there was something missing—a genuine connection with the people who walked through the doors. One day, as she was training a client who seemed more stressed and tired than usual, Maya had an epiphany. “Fitness isn’t just about looking good,” she thought. “It’s about feeling good, inside and out.” With this in mind, she decided to create a space where the well-being of people came first.
      `,

      aboutUsSectionDesc2: `A New Kind of Gym When Pulse opened its doors, it quickly became clear that this was no ordinary gym. The moment you walked in, you were greeted by a sense of warmth and community. The trainers knew your name, your goals, and more importantly, they knew your story. Every member was treated as an individual, with unique needs and aspirations. The equipment was top-notch, but it wasn’t the star of the show—people were. Maya made sure that every aspect of the gym, from the layout to the programs offered, was designed to benefit the members in the most meaningful way. Classes were small and personal, focusing on strength, flexibility, and mental wellness.
      `,

      aboutUsSectionDesc3: `There were meditation sessions after intense workouts, nutritional guidance tailored to each person, and even motivational talks that encouraged members to pursue balance in their lives.
       `,

      //About us section
      contactUsTitle: "Get In Touch",
      contactUsDesc: `If you have a query, a suggestion, no matter it is, we would love to hear from you.`,
      contactUsEmailInputLabel: "Your Email",
      contactUsMessageInputLabel: "Your Message",
      contactUsSendButton: "Send",

      //Footer Section
      footerSectionPrivacyPolicy: "Privacy Policy",
      footerSectionTermsAndConditions: "Terms And Conditions",
      footerSectionRefundPolicy: "Refund Policy",

      //auth page
      login: "Login",
      signup: "Sign Up",
      authEmailInputLabel: "Email",
      authPasswordInputLabel: "Password",
      authConfirmPasswordInputLabel: "Confirm Password",
      authForgotPassword: "Forgot Password",
      authSubmit: "Submit",

      //forgot password page
      forgotPasswordPageTitle: "Forgot Password",
      forgotPasswordPageDesc:
        "Tell us your email & we will send you a link though which you will be able to change the password",
      forgotPasswordEmailSent: "Email sent, please check your inbox.",
      forgotPasswordEmailInputLabel: "Your Email",
      forgotPasswordSubmit: "Submit",
      forgotPasswordPleaseEnterYourEmail: "Please enter your email",

      //Change Password
      changePasswordTitle: "Change Password",
      changePasswordSubmit: "Submit",
      password: "Password",
      confirmPassword: "Confirm Password",
      yourPasswordHasBeenChanged:
        "Your password has been changed, go ahead and login",

      //onboarding
      onboardingSubmit: "Submit",

      //onboarding / DOB
      onboardingDobTitle: "What is your age?",
      onboardingDobDesc:
        "This will help us provide you with a more personalized experience.",
      onboardingDobPleaseEnterYourDob: "Please enter your date of birth",
      onboardingDobAgeLimit: "You need to at least 10 years old",

      //onboarding / Membership
      onboardingMembershipTitle: "Choose your membership",
      onboardingMembershipDesc:
        "Choose how you would like to enjoy our services",
      onboardingMembershipWarning: "It is a required field",

      //onboarding / Name & Gender
      onboardingNameGenderTitle: "Tell us your name and gender.",
      onboardingNameGenderDesc:
        "This will help us provide you with a more personalized experience.",
      onboardingNameGenderWarning: "Please! enter the value",
      onboardingFirstNameInput: "First Name",
      onboardingLastNameInput: "Last Name",

      //onboarding / Phone Number
      onboardingPhoneNumberTitle: "What is your phone number?",
      onboardingPhoneNumberDesc:
        "This will help us provide you with a more personalized experience.",
      onboardingPhoneNumberWarning: "Please! enter the value",
      onboardingPhoneNumberInput: "Number",

      //onboarding / Photo
      onboardingPhotoTitle: "Please! Add a photo of yourself.",
      onboardingPhotoDesc:
        "This will help us provide you with a more personalized experience.",

      //onboarding / Weight
      onboardingWeightTitle: "What is your weight?",
      onboardingWeightDesc:
        "This will help us provide you with a more personalized experience.",
      onboardingWeightWarning: "It is a required field",
      onboardingWeightInput: "Weight",

      onboardingSkip: "Skip",
      //onboarding / Verify Email
      onboardingVerifyEmailMainTitle: "Please! Verify your email",
      onboardingVerifyEmailDesc:
        "Please check your email inbox, you will find an email that will allow us to verify that you genuinely own that email.",
      onboardingVerifyEmailDesc2:
        "Only after that we can allow you to use the features of this platform.",
      onboardingVerifyEmailSent:
        "Verification email sent, please check your email",

      onboardingVerifyEmailWhileConfirming: "Verifying your email",

      //Edit Profile
      onboardingEditProfileTitle: "Edit Profile",
      editProfileGender: "Gender",
      editProfileDOB: "Date of birth",
      editProfileName: "Name",
      editProfilePhoto: "Photo",
      editProfileWeight: "Weight",
      editProfilePhoneNumber: "Phone Number",
      editProfileSave: "Save",
      //Home Page -----------------------------------------------
      menu: "Menu",

      //Menu Items
      menuItemReserveHealthCheckUp: "Reserve Checkup",
      menuItemHealthReports: "Health Report",
      menuItemMyActivities: "My Bookings",
      menuItemMyOrders: "My Orders",
      menuItemAnnouncements: "Announcements",
      menuItemShopping: "Shopping",

      //Subscription Mini Info
      subscriptionMiniInfoTitle: "Membership",
      subscriptionMiniInfoExpiration: "Expiration",

      //Schedule Checkup Page
      scheduleCheckupTime: "Time",
      scheduleCheckupStatus: "Status",
      scheduleCheckupCancelBooking: "Cancel Booking",
      scheduleCheckupYouHaveUpcomingCheckup: "You Have Upcoming Checkup",
      scheduleCheckupYouHaveGreatExperience:
        "We hope you have a great experience",
      scheduleCheckupTitle: "Schedule Checkup",
      scheduleCheckupSubtitle: "Select a date, time & slot",
      scheduleCheckupButton: "Schedule",
      scheduleCheckupSelectDate: "Date",
      scheduleCheckupSelectTime: "Time",
      scheduleCheckupSelectSlot: "Slot",

      requestMembership: "Request",

      //Upcoming Activities Section
      upcomingActivitiesSectionTitle: "Upcoming Activities",
      upcomingActivitiesShowAll: "Show All",

      //Membership Page
      membershipPageTitle: "Membership Page",

      //Shopping Page
      shoppingPageTitle: "Shopping Page",
      shoppingPageSearchInputLabel: "Search",
      shoppingPageSearchInputPlaceholder: "Filter by search",
      shoppingPageFilterLabel: "Filter By Category",
      shoppingPageFilterPlaceholder: "Select Category",
      shoppingPageReset: "Reset",
      outOfStock: "Out Of Stock",

      //Cart Page
      cartPageTitle: "Your Cart",
      cartIsEmpty: "Your cart is empty",
      pay: "Pay",
      cartPageAddress: "Address",

      //Settings Page
      settingsPageTitle: "Settings Page",
      logout: "Logout",
      editProfile: "Edit Profile",
      adminPage: "Management",

      //upcoming activities Page
      upcomingActivitiesTitle: "Upcoming Activities",
      upcomingActivitiesFilterLabel: "Filter By Coach",
      upcomingActivitiesFilterPlaceholder: "Select Coach",
      upcomingActivitiesReset: "Reset",
      upcomingActivitiesBooked: "Booked",
      upcomingActivitiesSeatsLeft: "Seats Left",
      upcomingActivitiesCoach: "Coach",

      //Add Edit Form Inputs
      selectDate: "Select Date",
      selectHour: "Select Hour",
      selectSlot: "Select Exact Time",

      //Book Health Checkup page
      bookHealthCheckupTitle: "Health Checkup",
      bookHealthCheckupButton: "Schedule",
      bookHealthCheckupTime: "Time",
      bookHealthCheckupStatus: "Status",
      bookHealthCheckupReport: "Report",
      bookHealthCheckupSlot: "Slot",

      //Health Reports page
      healthCheckupReportTitle: "Health Checkups",
      healthCheckupReportTime: "Filter By Coach",
      healthCheckupReportStatus: "Select Coach",
      healthCheckupReportReport: "Reset",
      healthCheckupReportAPPROVED: "Approved",
      healthCheckupReportPENDING: "Pending",
      healthCheckupReportAvailable: "Available",

      // Activities Page
      activitiesPageTitle: "Activities",

      //My Activities Page
      myActivitiesTitle: "My Activities",
      myActivitiesBooked: "Booked",

      //My Orders Page
      myOrdersTitle: "My Orders",
      myOrdersPrice: "Price",
      myOrdersQuantity: "Quantity",
      myOrdersStatus: "Status",
      myOrdersAddress: "Address",
      myOrdersDeliveryDate: "Delivery Date",

      //order status
      ORDER_PENDING: "Pending",
      ORDER_APPROVED: "Accepted",
      DISPATCHED: "Dispatched",
      OUT_FOR_DELIVER: "Out For Delivery",

      //Announcements Page
      announcementsPageTitle: "Announcements",
      announcementPageTitle: "Announcement",
      //Announcement Page
      announcementsPageDescription: "Description",

      //Shopping Item Page
      shoppingItemPageStock: "Stock",
      shoppingItemPageDescription: "Description",
      addToCart: "Add to cart",
      itemIsOutOfStock: "Item is out of stock",
      thatManyStockIsNotAvailable: "That many stock is not available",
      addedToCart: "Added to cart",

      //

      //Health report Item Page
      healthReportItemTitle: "Health Checkup",
      healthReportItemDate: "Date",
      healthReportItemTime: "Time",
      healthReportItemStatus: "Status",
      healthReportItemRemark: "Remark",
      healthReportItemOpenReport: "Open Report",
      healthReportItemWeight: "Weight",
      healthReportItemLearnBodyMass: "Lean Body Mass",
      healthReportItemMuscleMass: "Muscle Mass",
      healthReportItemBodyWater: "Body Water",
      healthReportItemBodyFatMass: "Body Fat Mass",
      healthReportItemInorganicSalt: "Inorganic Salt",
      healthReportItemProtein: "Protein",

      //Activity Item Page
      activityItemCoach: "Coach",
      activityItemDuration: "Duration",
      activityItemDescription: "Description",
      activityItemDetails: "Details",
      activityItemParticipationType: "Participation Type",
      activityItemMembershipType: "Membership Type",
      activityItemRemark: "Remarks",
      activityItemAboutCoach: "Coach",
      activityItemCoachName: "Coach",
      activityItemShowReport: "Show Report",
      activityItemCancelBooking: "Cancel",
      PRIVATE_GROUP: "Groupe Privé",
      SOLO: "Solo",
      GROUP: "Groupe",

      //Admin Common
      showMore: "Show More",
      clickHereToAddNew: "Add",
      uploadFile: "Upload File",
      editItem: "Edit Item",
      openReportPDF: "Open Report PDF",

      //Admin Menu
      adminPageName: "Management",
      adminPageHealthCheckupTitle: "Health Checkups",
      adminPageManageUsersTitle: "Users",
      adminPagePaymentEntryTitle: "Payment Entry",
      adminPageMembershipReqTitle: "Membership Requests",
      rechargeActivityRequest: "Recharge Activity Requests",

      adminPageMembershipPlansTitle: "Membership Plans",

      employeeDashboard: "Employee Dashboard",
      me: "Me",

      adminPageUpcomingHealthCheckupTitle: "Upcoming Checkups",
      adminPageEmployeesTitle: "Employees",
      adminPageActivityTypesTitle: "Activity Types",
      adminPageActivitiesTitle: "Activities",
      adminPageStoreTitle: "Store",
      adminPageAnnouncementsTitle: "Announ. & Blog",
      adminPageOrdersTitle: "Orders",
      adminPageSettingsTitle: "Settings",

      //Admin / Health Checkups
      adminHealthCheckupsAPPROVED: "Approved",
      adminHealthCheckupsDECLINED: "Declined",
      adminHealthCheckupsStatus: "Status",
      adminHealthCheckupsDate: "Date",
      adminHealthCheckupsTime: "Time",
      adminHealthCheckupsDecline: "Decline",
      adminHealthCheckupsUploadReport: "Upload Report",
      adminHealthCheckupsChangeDateAndTime: "Change Date & Time",

      //Admin / Health Checkups / Upload Report Form
      adminHealthCheckupsWeight: "Weight",
      adminHealthCheckupsLeanBodyMass: "Lean Body Mass",
      adminHealthCheckupsMuscleMass: "Muscle Mass",
      adminHealthCheckupsBodyWater: "Body Water",
      adminHealthCheckupsBodyFatMass: "Body Fat Mass",
      adminHealthCheckupsInorganicSalt: "Inorganic Salt",
      adminHealthCheckupsProtein: "Protein",
      adminHealthCheckupsRemark: "Remark",

      //Admin / Health Checkups / Change Date Time Form
      adminHealthCheckupsSelectDate: "Select Date",
      adminHealthCheckupsSelectHour: "Select Hour",
      adminHealthCheckupsSelectSlot: "Select Exact Time",

      //Admin / Employees
      adminPageEmployeeDescription: "Description",
      adminPageEmployeeDesignation: "Designation",
      adminPageEmployeeEditItem: "Edit Item",

      //------------------------------------------------------------

      //Admin / Users / Table
      adminPageUserTableName: "Name",
      adminPageUserTablePhone: "Phone No.",
      adminPageUserTableEmail: "Email",
      adminPageUserTableMembershipType: "Membership Type",
      adminPageUserTableAge: "Age",
      adminPageUserTableGender: "Gender",

      //Admin / Membership Type / Edit Membership Type
      adminPageMembershipTypeFormName: "Name",
      adminPageMembershipTypeFormDescription: "Description",
      adminPageMembershipTypeFormPrice: "Price",

      //

      adminPageActivityTypePairPrice: "Pair Participation Price",
      adminPageActivityTypeTrioPrice: "Trio Participation Price",
      //
      adminPageActivityTypeAllowPairParticipation: "Allow Pair Participation",
      adminPageActivityTypeAllowTrioParticipation: "Allow Trio Participation",

      //
      adminPageMembershipTypeValidityInWeeks: "Validity (In Weeks)",

      adminPageMembershipTypeIsRoyalty: "Is Royalty",

      adminPageMembershipTypeFormFeature1: "Feature",
      adminPageMembershipTypeFormFeature2: "Feature 2",
      adminPageMembershipTypeFormFeature3: "Feature 3",
      adminPageMembershipTypeFormFeature4: "Feature 4",
      status: "Status",

      //------------------------------------------------------------

      //Admin / Employees / Edit Employee
      adminPageEmployeeFormImage: "Image",
      adminPageEmployeeFormName: "Name",
      adminPageEmployeeFormDesignation: "Designation",
      adminPageEmployeeFormDescription: "Description",

      //Admin / Activity Types
      adminPageActivityTypeTitle: "Activity Types",
      adminPageActivityTypeDescription: "Description",
      adminPageActivityTypeWillHaveReport: "Will Have Report",
      adminPageActivityTypeEditItem: "Edit Item",

      //Admin / Activity Types / Edit Activity type form
      adminPageActivityTypeImage: "Image",
      adminPageActivityTypeFormName: "Title",
      adminPageActivityTypeFormDescription: "Description",
      adminPageActivityTypeFormWillHaveReport: "Will Have Report",
      adminPageActivityFormSelectEmployee: "Select Employee",
      adminPageActivityFormSelectMembershipPlan: "Select Membership Plan",

      activityTypeSessionsPerHour: "Sessions Per Hour",
      activityTypeStartTime: "Start Time",
      activityTypeEndTime: "End Time",
      activityTypeBreakTimeStart: "Break Time Start",
      activityTypeBreakTimeEnding: "Break Time Ending",
      activityTypeWeekdayAvailability: "Available On",

      //Admin / Activities
      adminPageActivityType: "Type",
      adminPageActivityEmployee: "Employee",
      adminPageActivityDate: "Date",
      adminPageActivitySeats: "Seats",
      adminPageActivityTime: "Time",
      adminPageActivityDuration: "Duration",
      adminPageActivitySeatsBooked: "Seats Booked",
      adminPageActivityShowBookings: "Show Bookings",
      adminPageActivityEditItem: "Edit Item",
      adminPageActivityPickEmployee: "Pick Employee",

      //Admin / Activities / Edit Form
      adminPageActivityFormSilver: "Silver",
      adminPageActivityFormGold: "Gold",
      adminPageActivityFormPlatinum: "Platinum",

      adminPageActivityFormSolo: "Solo",
      adminPageActivityFormGroup: "Group",
      adminPageActivityFormPrivateGroup: "Private Group",

      adminPageActivityFormDuration: "Duration in Minutes",
      adminPageActivityFormNumberOfSeats: "No. of seats",
      adminPageActivityFormDate: "Date",

      adminPageActivityFormStartTime: "Start Time",
      adminPageActivityFormSelectMinutes: "Start Minutes",

      adminPageActivityFormSelectActivityType: "Select Activity Type",
      adminPageActivityFormSelectParticipationType: "Select Participation Type",

      adminPageActivityFormSelectMembershipType: "Select Membership Type",
      adminPageActivityFormPriceForNonMember: "Price For Non Member",

      //Admin / Store

      adminPageStorePrice: "Price",
      adminPageStoreDescription: "Description",
      adminPageStoreCurrentStock: "Current Stock",
      adminPageStoreCategory: "Category",
      adminPageStoreEditItem: "Edit Item",
      adminPageStoreCreateNew: "Add New Item",
      adminPageStoreAddCategory: "Add Category",
      adminPageStoreEditCategory: "Edit Category",
      adminPageStoreEditAddItem: "Add Item",
      adminPageStoreCategoryName: "Category Name",
      adminPageStoreSelectCategory: "Select Category",

      //Admin / Store / Edit Form
      adminPageStoreFormImage: "Image",
      adminPageStoreFormTitle: "Title",
      adminPageStoreFormDescription: "Description",
      adminPageStoreFormPrice: "Price",
      adminPageStoreFormStock: "Stock",
      adminPageStoreFormHeight: "Height",
      adminPageStoreFormWidth: "Width",
      adminPageStoreFormSelectCategory: "Category",

      //Admin / Announcements
      adminPageAnnouncementImage: "Image",

      adminPageAnnouncementsDesc: "Description",
      adminPageAnnouncementsEditItem: "Edit",

      //Admin / Announcements / Edit Form
      adminPageAnnouncementFormImage: "Image",
      adminPageAnnouncementFormTitle: "Title",
      adminPageAnnouncementFormDescription: "Short Description",
      adminPageAnnouncementFormLongDescription: "Description",

      //Admin / Orders

      adminPageOrdersStatus: "Status",
      adminPageOrdersQuantity: "Quantity",
      adminPageOrdersAddress: "Address",
      adminPageOrdersEditItem: "Edit Item",

      //Admin / Orders / Edit Form
      adminPageOrdersFormOrderPending: "Pending",
      adminPageOrdersFormOrderApproved: "Accepted",
      adminPageOrdersFormDispatched: "Dispatched",
      adminPageOrdersFormOutForDelivery: "Out For Delivery",

      adminPageOrdersFormQuantity: "Quantity",
      adminPageOrdersFormAddress: "Address",
      adminPageOrdersFormNote: "Note",
      adminPageOrdersFormStatus: "Status",
      adminPageOrdersFormDeliveryDate: "Delivery Date",

      //Admin / Settings

      adminSettingsTitle: "Settings",
      adminSettingsCheckupsPerHour: "Checkups Per Hour",
      adminSettingsHealthCheckupTiming: "Health Checkup Timing",
      adminSettingsHealthCheckupBreakTime: "Health Checkup Break Time",
      adminSettingsHealthCheckupsAvailableOn: "Health Checkup Available On",
      adminSettingsCategories: "Categories",

      //Admin / Settings / Edit Form
      adminSettingFormCheckupsPerHour: "Checkups Per Hour",

      adminSettingFormHealthCheckupStartTime: "Health Checkup Start Time",
      adminSettingFormHealthCheckupEndTime: "Health Checkup End Time",

      healthCheckupBreakTimeStart: "Health Checkup Break Time Start",
      healthCheckupBreakTimeEnding: "Health Checkup Break Time Ending",

      adminSettingFormHealthCheckupsAvailableOn: "Health Checkup Available On",
      adminSettingFormAdminCategories: "Categories",

      //admin activity booking page
      adminActivityBookingTitle: "Activity Bookings",
      adminActivityBookingActivityType: "Activity Type",
      adminActivityBookingAddReport: "Add Report",

      //admin activity booking page / Edit Form
      adminActivityBookingReportPDF: "reportPDF",
      adminActivityBookingFormRemark: "Remark",
      adminActivityBookingFormRemark2: "Remark Two",
      adminActivityBookingFormRemark3: "Remark Three",

      //Footer Pages
      privacyPolicyTitle: "Privacy Policy",
      privacyPolicyDescription: `
      <p>At Wellness Care Center, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website or use our services, including logging in, paying for gym memberships, purchasing items from our gym store, and booking health checkups.</p>

  <p>By using our website, you agree to the terms outlined in this Privacy Policy.</p>

  <h2>1. Information We Collect</h2>
  
  <h3>a. Personal Information</h3>
  <p>When you create an account, pay for services, or make a purchase, we collect personal information such as:</p>
  <ul>
    <li>Name</li>
    <li>Email address</li>
    <li>Phone number</li>
    <li>Payment information (e.g., credit/debit card details)</li>
    <li>Health information (related to health checkups)</li>
  </ul>

  <h3>b. Non-Personal Information</h3>
  <p>We may also collect non-personal data such as:</p>
  <ul>
    <li>Browser type and version</li>
    <li>IP address</li>
    <li>Device information</li>
    <li>Website usage statistics and logs</li>
  </ul>

  <h2>2. How We Use Your Information</h2>
  <p>We use your information to:</p>
  <ul>
    <li>Provide, manage, and improve our services, including processing gym memberships and purchases.</li>
    <li>Schedule and manage health checkups.</li>
    <li>Send payment confirmations and transaction-related emails.</li>
    <li>Respond to customer inquiries and provide customer support.</li>
    <li>Enhance website functionality and optimize user experience.</li>
    <li>Comply with legal obligations.</li>
  </ul>

  <h2>3. How We Protect Your Information</h2>
  <p>We take the security of your personal data seriously and employ the following measures:</p>
  <ul>
    <li>Encryption of sensitive data (e.g., payment details) using SSL technology.</li>
    <li>Secure servers and firewalls to protect your information from unauthorized access.</li>
    <li>Regular security assessments to detect potential vulnerabilities.</li>
  </ul>
  <p>While we strive to protect your personal information, no method of transmission over the internet is 100% secure. We cannot guarantee absolute security, and you share information with us at your own risk.</p>

  <h2>4. Sharing Your Information</h2>
  <p>We do not sell or rent your personal information to third parties. We may, however, share your data in the following circumstances:</p>
  <ul>
    <li><strong>Service providers</strong>: We may share your information with trusted third-party vendors who help us provide services (e.g., payment processors, shipping companies).</li>
    <li><strong>Legal compliance</strong>: We may disclose your information if required by law, such as in response to a court order or government request.</li>
  </ul>

  <h2>5. Cookies and Tracking Technologies</h2>
  <p>Our website uses cookies and other tracking technologies to:</p>
  <ul>
    <li>Improve website functionality and performance.</li>
    <li>Personalize your experience.</li>
    <li>Understand how users interact with our website.</li>
  </ul>
  <p>You can choose to disable cookies through your browser settings; however, doing so may limit the functionality of our website.</p>

  <h2>6. Your Rights</h2>
  <p>You have the following rights regarding your personal information:</p>
  <ul>
    <li><strong>Access</strong>: Request access to the personal information we hold about you.</li>
    <li><strong>Correction</strong>: Request correction of any inaccurate or incomplete information.</li>
    <li><strong>Deletion</strong>: Request the deletion of your personal data under certain circumstances.</li>
    <li><strong>Withdraw consent</strong>: You may withdraw your consent for us to process your data at any time, though this may affect your ability to use our services.</li>
  </ul>
  <p>To exercise your rights, please contact us at [contact email].</p>

  <h2>7. Children’s Privacy</h2>
  <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

  <h2>8. Changes to This Privacy Policy</h2>
  <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any changes will be posted on this page, and the "Last updated" date will be revised.</p>
      `,

      termsAndConditionsTitle: "Terms And Conditions",
      termsAndConditionsDescription: `
      
      <p>Welcome to Wellness Care Center! These Terms and Conditions govern your use of our website and services. By accessing our platform, you agree to abide by these terms, so please read them carefully.</p>

  <h2>1. Acceptance of Terms</h2>
  <p>By using the Wellness Care Center website, creating an account, or purchasing services, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree, please refrain from using our services.</p>

  <h2>2. Services Provided</h2>
  <p>Wellness Care Center offers the following services through its platform:</p>
  <ul>
    <li>Gym membership management</li>
    <li>Purchasing products from our gym store</li>
    <li>Booking health checkups</li>
  </ul>
  <p>We reserve the right to modify or discontinue any of our services without prior notice.</p>

  <h2>3. User Accounts</h2>
  <p>To access certain features of our platform, such as managing your membership or booking health checkups, you must create an account. You agree to:</p>
  <ul>
    <li>Provide accurate and up-to-date information during registration.</li>
    <li>Keep your login credentials confidential and not share them with others.</li>
    <li>Notify us immediately if you suspect unauthorized access to your account.</li>
  </ul>
  <p>You are responsible for all activities that occur under your account.</p>

  <h2>4. Payments</h2>
  <p>All payments for gym memberships, purchases, or services must be made via the available payment methods on our platform. You agree to provide valid payment information and authorize Wellness Care Center to charge your selected payment method for all applicable fees.</p>

  <p>If there are issues processing your payment, your access to services may be restricted until the matter is resolved. All transactions are final, and refunds will be issued only in accordance with our Refund Policy.</p>

  <h2>5. Use of the Platform</h2>
  <p>You agree to use the Wellness Care Center website and services for lawful purposes only. You are prohibited from:</p>
  <ul>
    <li>Misusing the website or its content.</li>
    <li>Interfering with or disrupting the functionality of the platform.</li>
    <li>Attempting to hack or breach the security of the website.</li>
    <li>Engaging in fraudulent or illegal activities through the platform.</li>
  </ul>

  <h2>6. Intellectual Property</h2>
  <p>All content on the Wellness Care Center website, including but not limited to text, images, graphics, logos, and software, is the intellectual property of Wellness Care Center. You may not reproduce, distribute, or modify any of our content without prior written consent.</p>

  <h2>7. Limitation of Liability</h2>
  <p>Wellness Care Center is not liable for any direct, indirect, incidental, or consequential damages arising from the use of or inability to use our website or services. This includes but is not limited to damage caused by viruses or errors in the platform, loss of data, or unauthorized access to your account.</p>

  <p>We make no warranties, express or implied, regarding the accuracy or reliability of the services provided on our platform. Your use of our website and services is at your own risk.</p>

  <h2>8. Cancellation and Termination</h2>
  <p>You may cancel your gym membership or account at any time by contacting us. Wellness Care Center reserves the right to terminate or suspend your account or access to services if you violate these Terms and Conditions.</p>

  <h2>9. Changes to the Terms and Conditions</h2>
  <p>We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on this page with the updated "Last updated" date. Continued use of our services after such modifications constitutes your acceptance of the updated terms.</p>

  <h2>10. Governing Law</h2>
  <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.</p>

      `,

      refundPolicyTitle: "Refund Policy",
      refundPolicyDescription: ` <p>At Wellness Care Center, we aim to provide excellent service to all our customers. This Refund Policy outlines the conditions under which refunds are provided for services such as gym memberships, store purchases, and health checkups. By using our services, you agree to this Refund Policy.</p>

  <h2>1. Gym Memberships</h2>
  <p>All gym membership payments are final and non-refundable. We do not offer refunds for unused membership time, early cancellations, or missed classes or sessions. However, under certain exceptional circumstances, partial refunds may be considered at the discretion of Wellness Care Center. These circumstances may include:</p>
  <ul>
    <li>Medical emergencies (with valid proof)</li>
    <li>Relocation outside the service area</li>
  </ul>
  <p>If you believe you are eligible for a refund under these conditions, please contact us at [email address] and provide the necessary documentation. Requests will be reviewed on a case-by-case basis.</p>

  <h2>2. Purchases from Gym Store</h2>
  <p>We offer a <strong>[Number]-day</strong> refund policy for products purchased from our gym store. To be eligible for a refund, the following conditions must be met:</p>
  <ul>
    <li>The item must be unused and in the same condition as you received it.</li>
    <li>The item must be in its original packaging.</li>
    <li>A receipt or proof of purchase is required.</li>
  </ul>
  <p>Refunds for store purchases will be issued to the original payment method within [Number] business days once the returned product is received and inspected.</p>

  <p><strong>Non-Refundable Items:</strong> Certain items such as health products, supplements, or personalized items may not be eligible for a refund. Please review the product description carefully before purchase.</p>

  <h2>3. Health Checkup Services</h2>
  <p>Health checkup bookings are non-refundable once confirmed. If you need to reschedule your appointment, please contact us at least 24 hours in advance. Failure to notify us within this time frame may result in the loss of payment for the service.</p>

  <h2>4. Refund Process</h2>
  <p>To request a refund, please follow these steps:</p>
  <ul>
    <li>Email us at [email address] with your refund request, including your order details, receipt, and the reason for the refund.</li>
    <li>If applicable, return the item to our physical address: [Physical Address].</li>
    <li>Once we receive your request or returned item, we will notify you via email about the approval or rejection of your refund.</li>
  </ul>

  <h2>5. Late or Missing Refunds</h2>
  <p>If you haven’t received a refund yet, please follow these steps:</p>
  <ul>
    <li>Check your bank account or payment method for any pending transactions.</li>
    <li>Contact your bank or credit card provider, as processing times can vary.</li>
    <li>If you have done all of the above and still have not received your refund, please contact us at [email address].</li>
  </ul>
`,
    },
  },
  fr: {
    translation: {
      aRequestIsPending: "Une requête est déjà en cours",

      noMembershipPlanSelected: "Aucun plan d'adhésion sélectionné",

      youHaveSubscribedTo: "Vous avez souscrit à",
      noUpcomingDates: "Il n'y a pas de dates à venir pour cette activité",
      activityType: "Type d'activité",
      clickToSelect: "Cliquez pour sélectionner",

      booking: "Réservation",
      bookings: "Réservations",
      duration: "Durée",
      yourRequestHasBeenSent: "Votre demande a été envoyée",
      checkout: "Finaliser l'achat",
      typeHereToSearch: "Chercher un mot clé",
      submit: "Soumettre",
      activities: "Activités",
      addActivity: "Ajouter des activités",
      save: "Enregistrer",
      saved: "Enregistré",
      allFieldsAreRequired: "Tous les champs sont requis",
      pleasePurchase3dVisbodyFirst:
        "Veuillez d’abord souscrire à l’offre 3D Visbody Scan",

      weeks: "Semaines",
      cancel: "Annuler",
      activityRechargeRequestHasBeenSent:
        "La demande de rechargement d'activité a été envoyée",
      currentMembershipPlan: "Plan d'adhésion Actuel",
      activityBalance: "Bilan d'activité",
      membershipPageActivityTitle: "À la carte ",
      membershipPageActivitySecondaryTitle:
        "Découvrez nos activités sans abonnement",
      recharge: "À la carte",
      recharge2: "DÉCOUVRIR",

      noMembershipPlan: "Aucun plan",
      hello: "Bonjour",
      price: "Prix",
      currentPlan: "Abonnement",
      validity: "Validité",

      //Logged Out Hero Section
      loggedOutHeroTitle: "Bougez Mieux, Vivez Mieux",
      loggedOutHeroTitleLine1: "Bougez Mieux",
      loggedOutHeroTitleLine2: "Vivez Mieux",
      loggedOutHeroDesc:
        "Rejoignez-nous pour un parcours bien-être qui vous rapproche de vos objectifs de santé.",
      becomeAMember: "Devenir membre",
      alreadyAMember: "Déjà membre",
      eliteTrainer: "Entraîneurs d'élite",
      regularHealthCheckups: "Bilans de santé réguliers",
      cuttingEdgeEquipments: "Équipements de pointe",
      exclusiveEvents: "Événements exclusifs",

      //buy-activity
      checkEmailForFurtherDetails:
        "Vérifiez votre courrier électronique pour plus de détails",

      //Logged Out Header
      whyChooseUs: "Pourquoi Nous Choisir ?",
      ourTrackRecord: "Notre Expérience",
      bookActivity: "Activité de réserve",
      membership: "Adhésion",
      aboutUs: "À propos de nous",
      contactUs: "Nous contacter",
      loginOrSignup: "Connexion / Inscription",

      //Logged Out Why Choose Us Section
      whyChooseUsSectionTitle: "Parlons Détails",
      whyChooseUsLine1: "Entraîneurs d'élite, résultats extraordinaires",
      whyChooseUsLine1Desc:
        "Nos entraîneurs de classe mondiale sont la pierre angulaire de votre parcours de remise en forme. Forts d'une vaste expérience et de certifications de premier ordre, ils adaptent les plans d'entraînement à vos objectifs et besoins uniques. Que vous visiez une perte de poids, un gain musculaire ou un bien-être général, nos entraîneurs vous apportent la motivation, l'expertise et le soutien nécessaires pour vous aider à obtenir des résultats remarquables.",
      whyChooseUsLine2: "Santé Holistique : Bilan Régulier Inclus",
      whyChooseUsLine2Desc:
        "Votre santé est notre priorité absolue. C'est pourquoi nous proposons des bilans de santé réguliers à tous nos membres, garantissant que vous restez sur la bonne voie avec vos objectifs de remise en forme tout en maintenant votre bien-être général. Nos évaluations de santé professionnelles comprennent des évaluations détaillées et des conseils personnalisés de notre personnel médical expérimenté.",
      whyChooseUsLine3: "Équipements De Pointe Pour Une Performance Optimale",
      whyChooseUsLine3Desc:
        "Nous pensons que le bon équipement est essentiel pour atteindre une forme physique optimale. Notre salle de sport est équipée des appareils de fitness les plus récents et les plus avancés, garantissant une expérience d'entraînement complète et efficace. Des machines cardio de haute technologie aux équipements de musculation de pointe, nous fournissons tout ce dont vous avez besoin pour repousser vos limites et atteindre de nouveaux sommets dans votre parcours de remise en forme.",

      //Our Track Record
      ourTrackRecordSectionTitle: "Notre Parcours",
      ourTrackRecord1: "Plus de 4 ans",
      ourTrackRecord1Desc: "D'expérience",
      ourTrackRecord2: "Plus de 500",
      ourTrackRecord2Desc: "Membres Satisfaits",
      ourTrackRecord3: "Plus de 20",
      ourTrackRecord3Desc: "Entraîneurs Personnels",

      //Testimonials Section
      testimonialsSectionTitle: "Témoignages",
      testimonialsSectionDesc: "Témoignages Clients",

      //Membership Plan Title
      membershipPlanTitle: "Plan d'adhésion",
      mostPopular: "Le Plus Populaire",
      choosePlan: "Choisir un plan",
      membershipPlanOnePrice: "CFA 75000/mois",
      membershipPlanOneName: "Platine",
      membershipPlanOneDesc: "Débloquez votre plein potentiel",
      membershipPlanOneBullet1: "Avantages du plan Or",
      membershipPlanOneBullet2: "Bilan de santé hebdomadaire",
      membershipPlanOneBullet3: "Ateliers Exclusifs",
      membershipPlanOneBullet4: "Entraîneurs Haut de Gamme",
      membershipPlanOneBullet5: "Réduction sur les produits",

      //Plan two
      membershipPlanTwoPrice: "CFA 30000/mois",
      membershipPlanTwoName: "Or",
      membershipPlanTwoDesc: "Devenez Sérieux",
      membershipPlanTwoBullet1: "Avantages du plan de base",
      membershipPlanTwoBullet2: "Conseils nutritionnels",
      membershipPlanTwoBullet3: "2 Bilans de santé",
      membershipPlanTwoBullet4: "Entraînement avancé",

      //Plan three
      membershipPlanThreePrice: "CFA 15000/mois",
      membershipPlanThreeName: "Argent",
      membershipPlanThreeDesc: "Petit Budget",
      membershipPlanThreeBullet1: "Accès à la salle de sport",
      membershipPlanThreeBullet2: "Un Bilan de santé",
      membershipPlanThreeBullet3: "Accès au vestiaire",

      //Take Membership Popup
      confirmMembershipTitle: "Confirmer l'abonnement à ce plan",
      confirmMembershipConfirmButton: "S'abonner",
      confirmMembershipCancelButton: "Annuler",
      membershipRequestSentTitle: "Votre demande a été envoyée",
      membershipRequestSentText: "Vous êtes en attente de paiement.",

      //About us section
      aboutUsSectionTitle: "À Propos de Nous",
      aboutUsSectionDesc: `Dans la vie trépidante d’une ville, où la vie avançait à un rythme implacable, il existait une salle de sport pas comme les autres. Ce n'était pas seulement un endroit rempli de poids, de tapis roulants et de miroirs reflétant des visages trempés de sueur. Cette salle de sport, connue sous le nom de Pulse, a été construite sur la conviction que la véritable forme physique ne se limite pas à la forme physique : il s'agit d'une croissance holistique, où l'esprit, le corps et l'âme travaillent en harmonie. Le début : une vision pour les gens Pulse est l'idée originale de Maya Patel, une passionnée de fitness au cœur aussi grand que ses rêves. Maya avait passé des années à travailler dans des gymnases traditionnels, où l'accent était souvent mis sur des gains rapides et des résultats éclatants. Mais elle savait qu’il manquait quelque chose : un véritable lien avec les personnes qui franchissaient les portes. Un jour, alors qu'elle entraînait un client qui semblait plus stressé et fatigué que d'habitude, Maya a eu une révélation. « La forme physique ne consiste pas seulement à bien paraître », pensa-t-elle. "Il s'agit de se sentir bien, à l'intérieur comme à l'extérieur." C’est dans cette optique qu’elle a décidé de créer un espace où le bien-être des personnes passe avant tout.`,
      aboutUsSectionDesc2:
        "A New Kind of Gym When Pulse opened its doors, it quickly became clear that this was no ordinary gym. The moment you walked in, you were greeted by a sense of warmth and community. The trainers knew your name, your goals, and more importantly, they knew your story. Every member was treated as an individual, with unique needs and aspirations. The equipment was top-notch, but it wasn’t the star of the show—people were. Maya made sure that every aspect of the gym, from the layout to the programs offered, was designed to benefit the members in the most meaningful way. Classes were small and personal, focusing on strength, flexibility, and mental wellness.",
      aboutUsSectionDesc3:
        "There were meditation sessions after intense workouts, nutritional guidance tailored to each person, and even motivational talks that encouraged members to pursue balance in their lives.",

      //Contact us section
      contactUsTitle: "Contactez-Nous",
      contactUsDesc:
        "Si vous avez une question ou une suggestion, nous aimerions avoir de vos nouvelles.",
      contactUsEmailInputLabel: "Votre email",
      contactUsMessageInputLabel: "Votre message",
      contactUsSendButton: "Envoyer",

      //Footer Section
      footerSectionPrivacyPolicy: "Politique de confidentialité",
      footerSectionTermsAndConditions: "Conditions Générales",
      footerSectionRefundPolicy: "Politique de remboursement",

      //Authentication Page
      login: "Connexion",
      signup: "S'inscrire",
      authEmailInputLabel: "Email",
      authPasswordInputLabel: "Mot de passe",
      authConfirmPasswordInputLabel: "Confirmez le mot de passe",
      authForgotPassword: "Mot de passe oublié",
      authSubmit: "Soumettre",

      // forgot password page
      forgotPasswordPageTitle: "Mot de passe oublié",
      forgotPasswordPageDesc:
        "Indiquez-nous votre email et nous vous enverrons un lien pour changer votre mot de passe",
      forgotPasswordEmailSent:
        "Email envoyé, veuillez vérifier votre boîte de réception.",
      forgotPasswordEmailInputLabel: "Votre Email",
      forgotPasswordSubmit: "Soumettre",
      forgotPasswordPleaseEnterYourEmail: "Veuillez entrer votre email",

      // Change Password
      changePasswordTitle: "Changer le mot de passe",
      changePasswordSubmit: "Soumettre",
      password: "Mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      yourPasswordHasBeenChanged:
        "Votre mot de passe a été changé, connectez-vous maintenant",

      // onboarding
      onboardingSubmit: "Suivant",

      // onboarding / DOB
      onboardingDobTitle: "Votre date de naissance",
      onboardingDobDesc:
        "Cela nous aidera à vous offrir une expérience plus personnalisée.",
      onboardingDobPleaseEnterYourDob:
        "Veuillez entrer votre date de naissance",
      onboardingDobAgeLimit: "Vous devez avoir au moins 10 ans",

      // onboarding / Membership
      onboardingMembershipTitle: "Choisissez votre abonnement",
      onboardingMembershipDesc:
        "Choisissez comment vous souhaitez profiter de nos services",
      onboardingMembershipWarning: "Ce champ est obligatoire",

      // onboarding / Name & Gender
      onboardingNameGenderTitle: "Indiquez-nous votre nom et votre sexe.",
      onboardingNameGenderDesc:
        "Cela nous aidera à vous offrir une expérience plus personnalisée.",
      onboardingNameGenderWarning: "Veuillez entrer une valeur",
      onboardingFirstNameInput: "Prénom",
      onboardingLastNameInput: "Nom de famille",

      // onboarding / Phone Number
      onboardingPhoneNumberTitle: "Quel est votre numéro de téléphone ?",
      onboardingPhoneNumberDesc:
        "Cela nous aidera à vous offrir une expérience plus personnalisée.",
      onboardingPhoneNumberWarning: "Veuillez entrer une valeur",
      onboardingPhoneNumberInput: "Numéro",

      // onboarding / Photo
      onboardingPhotoTitle: "Veuillez ajouter une photo de vous.",
      onboardingPhotoDesc:
        "Cela nous aidera à vous offrir une expérience plus personnalisée.",

      // onboarding / Weight
      onboardingWeightTitle: "Quel est votre poids ?",
      onboardingWeightDesc:
        "Cela nous aidera à vous offrir une expérience plus personnalisée.",
      onboardingWeightWarning: "Ce champ est obligatoire",
      onboardingWeightInput: "Poids",
      onboardingSkip: "Sauter",

      // onboarding / Verify Email
      onboardingVerifyEmailMainTitle: "Veuillez vérifier votre email",
      onboardingVerifyEmailDesc:
        "Veuillez vérifier votre boîte de réception, vous y trouverez un email qui nous permettra de vérifier que vous possédez bien cet email.",
      onboardingVerifyEmailDesc2:
        "Nous ne pourrons vous permettre d’utiliser les fonctionnalités de cette plateforme qu’après cette vérification.",
      onboardingVerifyEmailSent:
        "Email de vérification envoyé, veuillez vérifier votre email",

      onboardingVerifyEmailWhileConfirming:
        "Vérification de votre email en cours",

      // Edit Profile
      onboardingEditProfileTitle: "Modifier le profil",
      editProfileGender: "Sexe",
      editProfileDOB: "Date de naissance",
      editProfileName: "Nom",
      editProfilePhoto: "Photo",
      editProfileWeight: "Poids",
      editProfilePhoneNumber: "Numéro de téléphone",
      editProfileSave: "Enregistrer",

      // Home Page -----------------------------------------------
      menu: "Menu",

      // Menu Items
      menuItemReserveHealthCheckUp: "RÉSERVER UN CONTRÔLE",
      menuItemHealthReports: "RAPPORTS",
      menuItemMyActivities: "Mes Réservations",
      menuItemMyOrders: "Mes commandes",
      menuItemAnnouncements: "Annonce",
      menuItemShopping: "Achats",

      // Subscription Mini Info
      subscriptionMiniInfoTitle: "Adhésion",
      subscriptionMiniInfoExpiration: "Expiration",

      // Schedule Checkup Page
      scheduleCheckupTime: "Heure",
      scheduleCheckupStatus: "Statut",
      scheduleCheckupCancelBooking: "Annuler la réservation",
      scheduleCheckupYouHaveUpcomingCheckup:
        "Vous avez un prochain bilan de santé",
      scheduleCheckupYouHaveGreatExperience:
        "Nous espérons que vous aurez une excellente expérience",
      scheduleCheckupTitle: "Planifier un bilan de santé",
      scheduleCheckupSubtitle: "Sélectionnez une date, une heure et un créneau",
      scheduleCheckupButton: "Planifier",
      scheduleCheckupSelectDate: "Date",
      scheduleCheckupSelectTime: "Heure",
      scheduleCheckupSelectSlot: "Créneau",

      requestMembership: "Souscrire",

      // Upcoming Activities Section
      upcomingActivitiesSectionTitle: "Activité à venir",
      upcomingActivitiesShowAll: "Voir plus",

      // Membership Page
      membershipPageTitle: "Page d'abonnement",

      // Shopping Page
      shoppingPageTitle: "Page de shopping",
      shoppingPageSearchInputLabel: "Rechercher",
      shoppingPageSearchInputPlaceholder: "Filtrer par recherche",
      shoppingPageFilterLabel: "Filtrer par catégorie",
      shoppingPageFilterPlaceholder: "Sélectionner une catégorie",
      shoppingPageReset: "Réinitialiser",
      outOfStock: "En rupture de stock",

      // Cart Page
      cartPageTitle: "Votre panier",
      cartIsEmpty: "Votre panier est vide",
      pay: "Payer",
      cartPageAddress: "Adresse",

      // Settings Page
      settingsPageTitle: "Page des paramètres",
      logout: "Déconnexion",
      editProfile: "Modifier le profil",
      adminPage: "Gestion",

      // upcoming activities Page
      upcomingActivitiesTitle: "Activités à venir",
      upcomingActivitiesFilterLabel: "Filtrer par coach",
      upcomingActivitiesFilterPlaceholder: "Sélectionner un coach",
      upcomingActivitiesReset: "Réinitialiser",
      upcomingActivitiesBooked: "Réservé",
      upcomingActivitiesSeatsLeft: "Places restantes",
      upcomingActivitiesCoach: "Coach",

      // Add Edit Form Inputs
      selectDate: "Sélectionner une date",
      selectHour: "Sélectionner une heure",
      selectSlot: "Du matin",

      // Book Health Checkup page
      bookHealthCheckupTitle: "Bilan de santé",
      bookHealthCheckupButton: "Planifier",
      bookHealthCheckupTime: "Heure",
      bookHealthCheckupStatus: "Statut",
      bookHealthCheckupReport: "Rapport",
      bookHealthCheckupSlot: "Créneau",

      // Health Reports page
      healthCheckupReportTitle: "Bilans de santé",
      healthCheckupReportTime: "Filtrer par coach",
      healthCheckupReportStatus: "Sélectionner un coach",
      healthCheckupReportReport: "Réinitialiser",
      healthCheckupReportAPPROVED: "Approuvé",
      healthCheckupReportPENDING: "En attente",
      healthCheckupReportAvailable: "Disponible",

      // Activities Page
      activitiesPageTitle: "Activités",

      // My Activities Page
      myActivitiesTitle: "Mes activités",
      myActivitiesBooked: "Réservé",

      // My Orders Page
      myOrdersTitle: "Mes commandes",
      myOrdersPrice: "Prix",
      myOrdersQuantity: "Quantité",
      myOrdersStatus: "Statut",
      myOrdersAddress: "Adresse",
      myOrdersDeliveryDate: "Date de livraison",

      //order status
      ORDER_PENDING: "En attente",
      ORDER_APPROVED: "Accepté",
      DISPATCHED: "Expédié",
      OUT_FOR_DELIVER: "En cours de livraison",

      // Announcements Page
      announcementsPageTitle: "Annonces",
      announcementPageTitle: "Annonce",
      // Announcement Page
      announcementsPageDescription: "Description",

      // Shopping Item Page
      shoppingItemPageStock: "Stock",
      shoppingItemPageDescription: "Description",
      addToCart: "Ajouter au panier",
      itemIsOutOfStock: "L'article est en rupture de stock",
      thatManyStockIsNotAvailable: "Cette quantité n'est pas disponible",
      addedToCart: "Ajouté au panier",

      // Health report Item Page
      healthReportItemTitle: "Bilan de santé",
      healthReportItemDate: "Date",
      healthReportItemTime: "Heure",
      healthReportItemStatus: "Statut",
      healthReportItemRemark: "Remarque",
      healthReportItemOpenReport: "Ouvrir le rapport",
      healthReportItemWeight: "Poids",
      healthReportItemLearnBodyMass: "Masse maigre",
      healthReportItemMuscleMass: "Masse musculaire",
      healthReportItemBodyWater: "Eau corporelle",
      healthReportItemBodyFatMass: "Masse graisseuse",
      healthReportItemInorganicSalt: "Sel inorganique",
      healthReportItemProtein: "Protéine",

      // Activity Item Page
      activityItemCoach: "Coach",
      activityItemDuration: "Durée",
      activityItemDescription: "Description",
      activityItemDetails: "Détails",
      activityItemParticipationType: "Type de participation",
      activityItemMembershipType: "Type d'adhésion",
      activityItemRemark: "Remarques",
      activityItemAboutCoach: "À propos du coach",
      activityItemCoachName: "Coach",
      activityItemShowReport: "Afficher le rapport",
      activityItemCancelBooking: "Annuler",
      PRIVATE_GROUP: "Private Group",
      SOLO: "Solo",
      GROUP: "Group",
      // Admin Common
      showMore: "Voir plus",
      clickHereToAddNew: "Ajouter",
      uploadFile: "Téléverser un fichier",
      editItem: "Modifier l'article",
      openReportPDF: "Ouvrir le rapport PDF",

      // Admin Menu
      adminPageName: "Gestion",
      adminPageHealthCheckupTitle: "Bilans de santé",
      adminPageManageUsersTitle: "Users",
      adminPagePaymentEntryTitle: "Saisie De Paiement",
      adminPageMembershipReqTitle: "Demandes d'adhésion",
      rechargeActivityRequest: "Demandes d'activité de recharge",
      adminPageMembershipPlansTitle: "Formules d'adhésion",
      adminPageUpcomingHealthCheckupTitle: "de santé à venir",
      adminPageEmployeesTitle: "Employés",
      adminPageActivityTypesTitle: "Types d'activités",
      adminPageActivitiesTitle: "Activités",
      adminPageStoreTitle: "Magasin",
      adminPageAnnouncementsTitle: "Annonce et blog",
      adminPageOrdersTitle: "Commandes",
      adminPageSettingsTitle: "Paramètres",

      employeeDashboard: "Tableau de bord des employés",
      me: "Moi",
      // Admin / Health Checkups
      adminHealthCheckupsAPPROVED: "Approuvé",
      adminHealthCheckupsDECLINED: "Refusé",
      adminHealthCheckupsStatus: "Statut",
      adminHealthCheckupsDate: "Date",
      adminHealthCheckupsTime: "Heure",
      adminHealthCheckupsDecline: "Refuser",
      adminHealthCheckupsUploadReport: "Téléverser un rapport",
      adminHealthCheckupsChangeDateAndTime: "Changer la date et l'heure",

      //Admin / Health Checkups / Upload Report Form
      adminHealthCheckupsWeight: "Poids",
      adminHealthCheckupsLeanBodyMass: "Masse Corporelle Maigre",
      adminHealthCheckupsMuscleMass: "Masse Musculaire",
      adminHealthCheckupsBodyWater: "Eau Corporelle",
      adminHealthCheckupsBodyFatMass: "Masse Grasse",
      adminHealthCheckupsInorganicSalt: "Sel Inorganique",
      adminHealthCheckupsProtein: "Protéine",
      adminHealthCheckupsRemark: "Remarque",

      //Admin / Health Checkups / Change Date Time Form
      adminHealthCheckupsSelectDate: "Sélectionner la Date",
      adminHealthCheckupsSelectHour: "Sélectionner l'Heure",
      adminHealthCheckupsSelectSlot: "Sélectionnez l'heure exacte",

      //Admin / Employees
      adminPageEmployeeDescription: "Description",
      adminPageEmployeeDesignation: "Désignation",
      adminPageEmployeeEditItem: "Modifier l'Élément",

      //Admin / Employees / Edit Employee
      adminPageEmployeeFormImage: "Image",
      adminPageEmployeeFormName: "Nom",
      adminPageEmployeeFormDesignation: "Désignation",
      adminPageEmployeeFormDescription: "Description",

      //---------------------------------------------------------------

      //Admin / Users / Table
      adminPageUserTableName: "Nom",
      adminPageUserTablePhone: "N° de téléphone",
      adminPageUserTableEmail: "E-mail",
      adminPageUserTableMembershipType: "Type d'adhésion",
      adminPageUserTableAge: "Âge",
      adminPageUserTableGender: "Genre",

      //Admin / Membership Type / Edit Membership Type
      adminPageMembershipTypeFormName: "Nom",
      adminPageMembershipTypeFormDescription: "Description",
      adminPageMembershipTypeFormPrice: "Prix",

      //

      adminPageActivityTypePairPrice: "Prix ​​de participation par paire",
      adminPageActivityTypeTrioPrice: "Prix ​​de participation au trio",
      //
      adminPageActivityTypeAllowPairParticipation:
        "Autoriser la participation des paires",
      adminPageActivityTypeAllowTrioParticipation:
        "Autoriser la participation du trio",
      //

      adminPageMembershipTypeValidityInWeeks: "Validité (en semaines)",
      adminPageMembershipTypeIsRoyalty: "Est-ce la royauté",

      adminPageMembershipTypeFormFeature1: "Caractéristique",
      adminPageMembershipTypeFormFeature2: "Caractéristique 2",
      adminPageMembershipTypeFormFeature3: "Caractéristique 3",
      adminPageMembershipTypeFormFeature4: "Caractéristique 4",
      status: "Statut",

      //---------------------------------------------------------------

      //Admin / Activity Types
      adminPageActivityTypeTitle: "Types d'Activité",
      adminPageActivityTypeDescription: "Description",
      adminPageActivityTypeWillHaveReport: "Aura un Rapport",
      adminPageActivityTypeEditItem: "Modifier l'Élément",
      adminPageActivityFormSelectEmployee: "Sélectionner l'Employé",
      adminPageActivityFormSelectMembershipPlan:
        "Sélectionnez le plan d'adhésion",

      //Admin / Activity Types / Edit Activity type form
      adminPageActivityTypeImage: "Image",
      adminPageActivityTypeFormName: "Titre",
      adminPageActivityTypeFormDescription: "Description",
      adminPageActivityTypeFormWillHaveReport: "Aura un Rapport",

      activityTypeSessionsPerHour: "Sessions par heure",
      activityTypeStartTime: "Heure de début",
      activityTypeEndTime: "Heure de fin",
      activityTypeBreakTimeStart: "Début de la pause",
      activityTypeBreakTimeEnding: "Fin de la pause",
      activityTypeWeekdayAvailability: "Disponible le",

      //Admin / Activities
      adminPageActivityType: "Type",
      adminPageActivityEmployee: "Employé",
      adminPageActivityDate: "Date",
      adminPageActivitySeats: "Sièges",
      adminPageActivityTime: "Heure",
      adminPageActivityDuration: "Durée",
      adminPageActivitySeatsBooked: "Sièges Réservés",
      adminPageActivityShowBookings: "Afficher les Réservations",
      adminPageActivityEditItem: "Modifier l'Élément",
      adminPageActivityPickEmployee: "Choisir un Employé",

      //Admin / Activities / Edit Form
      adminPageActivityFormSilver: "Argent",
      adminPageActivityFormGold: "Or",
      adminPageActivityFormPlatinum: "Platine",

      adminPageActivityFormSolo: "Solo",
      adminPageActivityFormGroup: "Groupe",
      adminPageActivityFormPrivateGroup: "Groupe Privé",

      adminPageActivityFormDuration: "Durée en minutes",
      adminPageActivityFormNumberOfSeats: "Nombre de Sièges",
      adminPageActivityFormDate: "Date",

      adminPageActivityFormStartTime: "Heure de Début",
      adminPageActivityFormSelectMinutes: "Minutes de Début",

      adminPageActivityFormSelectActivityType:
        "Sélectionner le Type d'Activité",
      adminPageActivityFormSelectParticipationType:
        "Sélectionner le Type de Participation",

      adminPageActivityFormSelectMembershipType:
        "Sélectionner le Type d'Adhésion",

      adminPageActivityFormPriceForNonMember: "Prix ​​pour les non-membres",

      //Admin / Store
      adminPageStorePrice: "Prix",
      adminPageStoreDescription: "Description",
      adminPageStoreCurrentStock: "Stock Actuel",
      adminPageStoreCategory: "Catégorie",
      adminPageStoreEditItem: "Modifier l'Élément",
      adminPageStoreCreateNew: "Ajouter un Nouvel Élément",
      adminPageStoreAddCategory: "Ajouter une Catégorie",
      adminPageStoreEditCategory: "Modifier la Catégorie",
      adminPageStoreEditAddItem: "Ajouter un Élément",
      adminPageStoreCategoryName: "Nom de la Catégorie",
      adminPageStoreSelectCategory: "Sélectionner la Catégorie",

      //Admin / Store / Edit Form
      adminPageStoreFormImage: "Image",
      adminPageStoreFormTitle: "Titre",
      adminPageStoreFormDescription: "Description",
      adminPageStoreFormPrice: "Prix",
      adminPageStoreFormStock: "Stock",
      adminPageStoreFormHeight: "Hauteur",
      adminPageStoreFormWidth: "Largeur",
      adminPageStoreFormSelectCategory: "Catégorie",

      //Admin / Announcements
      adminPageAnnouncementImage: "Image",

      adminPageAnnouncementsDesc: "Description",
      adminPageAnnouncementsEditItem: "Modifier",

      //Admin / Announcements / Edit Form
      adminPageAnnouncementFormImage: "Image",
      adminPageAnnouncementFormTitle: "Titre",
      adminPageAnnouncementFormDescription: "Brève Description",
      adminPageAnnouncementFormLongDescription: "Description",

      //Admin / Orders
      adminPageOrdersStatus: "Statut",
      adminPageOrdersQuantity: "Quantité",
      adminPageOrdersAddress: "Adresse",
      adminPageOrdersEditItem: "Modifier l'Élément",

      //Admin / Orders / Edit Form
      adminPageOrdersFormOrderPending: "En Attente",
      adminPageOrdersFormOrderApproved: "Accepté",
      adminPageOrdersFormDispatched: "Expédié",
      adminPageOrdersFormOutForDelivery: "En Livraison",

      adminPageOrdersFormQuantity: "Quantité",
      adminPageOrdersFormAddress: "Adresse",
      adminPageOrdersFormNote: "Remarque",
      adminPageOrdersFormStatus: "Statut",
      adminPageOrdersFormDeliveryDate: "Date de Livraison",

      //Admin / Settings
      adminSettingsTitle: "Paramètres",
      adminSettingsCheckupsPerHour: "Examens par Heure",
      adminSettingsHealthCheckupTiming: "Heures des Examens de Santé",
      adminSettingsHealthCheckupBreakTime:
        "Temps de Pause pour les Examens de Santé",
      adminSettingsHealthCheckupsAvailableOn: "Examens de Santé Disponibles le",
      adminSettingsCategories: "Catégories",

      //Admin / Settings / Edit Form
      adminSettingFormCheckupsPerHour: "Examens par Heure",

      adminSettingFormHealthCheckupStartTime:
        "Heure de Début de l'Examen de Santé",
      adminSettingFormHealthCheckupEndTime: "Heure de Fin de l'Examen de Santé",

      healthCheckupBreakTimeStart:
        "Début du Temps de Pause pour les Examens de Santé",
      healthCheckupBreakTimeEnding:
        "Fin du Temps de Pause pour les Examens de Santé",

      adminSettingFormHealthCheckupsAvailableOn:
        "Examens de Santé Disponibles le",
      adminSettingFormAdminCategories: "Catégories",

      //admin activity booking page
      adminActivityBookingTitle: "Réservations d'Activités",
      adminActivityBookingActivityType: "Type d'Activité",
      adminActivityBookingAddReport: "Ajouter un Rapport",

      //admin activity booking page / Edit Form
      adminActivityBookingReportPDF: "rapportPDF",
      adminActivityBookingFormRemark: "Remarque",
      adminActivityBookingFormRemark2: "Remarque Deux",
      adminActivityBookingFormRemark3: "Remarque Trois",

      privacyPolicyTitle: "Politique de Confidentialité",
      privacyPolicyDescription: `
      <p>Chez Wellness Care Center, la protection de votre vie privée est notre priorité absolue. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations personnelles lorsque vous visitez notre site web ou utilisez nos services, y compris la connexion, le paiement des abonnements à la salle de sport, l'achat d'articles dans notre magasin de sport et la réservation de bilans de santé.</p>
      
      <p>En utilisant notre site web, vous acceptez les termes énoncés dans cette politique de confidentialité.</p>
  
      <h2>1. Informations que nous collectons</h2>
      
      <h3>a. Informations personnelles</h3>
      <p>Lorsque vous créez un compte, payez des services ou effectuez un achat, nous collectons des informations personnelles telles que :</p>
      <ul>
        <li>Nom</li>
        <li>Adresse e-mail</li>
        <li>Numéro de téléphone</li>
        <li>Informations de paiement (par exemple, détails de carte de crédit/débit)</li>
        <li>Informations de santé (liées aux bilans de santé)</li>
      </ul>
      
      <h3>b. Informations non personnelles</h3>
      <p>Nous pouvons également collecter des données non personnelles telles que :</p>
      <ul>
        <li>Type et version de navigateur</li>
        <li>Adresse IP</li>
        <li>Informations sur l'appareil</li>
        <li>Statistiques d'utilisation du site web et journaux</li>
      </ul>
      
      <h2>2. Comment nous utilisons vos informations</h2>
      <p>Nous utilisons vos informations pour :</p>
      <ul>
        <li>Fournir, gérer et améliorer nos services, y compris le traitement des abonnements à la salle de sport et des achats.</li>
        <li>Programmer et gérer les bilans de santé.</li>
        <li>Envoyer des confirmations de paiement et des e-mails liés aux transactions.</li>
        <li>Répondre aux demandes des clients et fournir un support client.</li>
        <li>Améliorer la fonctionnalité du site web et optimiser l'expérience utilisateur.</li>
        <li>Respecter les obligations légales.</li>
      </ul>
      
      <h2>3. Comment nous protégeons vos informations</h2>
      <p>Nous prenons au sérieux la sécurité de vos données personnelles et mettons en œuvre les mesures suivantes :</p>
      <ul>
        <li>Chiffrement des données sensibles (par exemple, détails de paiement) à l'aide de la technologie SSL.</li>
        <li>Serveurs sécurisés et pare-feux pour protéger vos informations contre tout accès non autorisé.</li>
        <li>Évaluations régulières de la sécurité pour détecter les vulnérabilités potentielles.</li>
      </ul>
      <p>Bien que nous nous efforcions de protéger vos informations personnelles, aucune méthode de transmission sur Internet n'est 100% sécurisée. Nous ne pouvons pas garantir une sécurité absolue, et vous partagez vos informations à vos propres risques.</p>
      
      <h2>4. Partage de vos informations</h2>
      <p>Nous ne vendons ni ne louons vos informations personnelles à des tiers. Cependant, nous pouvons partager vos données dans les circonstances suivantes :</p>
      <ul>
        <li><strong>Fournisseurs de services</strong> : Nous pouvons partager vos informations avec des fournisseurs de services tiers de confiance qui nous aident à fournir des services (par exemple, processeurs de paiement, entreprises de livraison).</li>
        <li><strong>Conformité légale</strong> : Nous pouvons divulguer vos informations si la loi l'exige, par exemple en réponse à une ordonnance judiciaire ou à une demande gouvernementale.</li>
      </ul>
      
      <h2>5. Cookies et technologies de suivi</h2>
      <p>Notre site web utilise des cookies et d'autres technologies de suivi pour :</p>
      <ul>
        <li>Améliorer la fonctionnalité et la performance du site web.</li>
        <li>Personnaliser votre expérience.</li>
        <li>Comprendre comment les utilisateurs interagissent avec notre site web.</li>
      </ul>
      <p>Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur ; cependant, cela peut limiter la fonctionnalité de notre site web.</p>
      
      <h2>6. Vos droits</h2>
      <p>Vous avez les droits suivants concernant vos informations personnelles :</p>
      <ul>
        <li><strong>Accès</strong> : Demander l'accès aux informations personnelles que nous détenons à votre sujet.</li>
        <li><strong>Correction</strong> : Demander la correction de toute information inexacte ou incomplète.</li>
        <li><strong>Suppression</strong> : Demander la suppression de vos données personnelles dans certaines circonstances.</li>
        <li><strong>Retrait du consentement</strong> : Vous pouvez retirer votre consentement pour que nous traitions vos données à tout moment, bien que cela puisse affecter votre capacité à utiliser nos services.</li>
      </ul>
      <p>Pour exercer vos droits, veuillez nous contacter à [adresse e-mail].</p>
      
      <h2>7. Confidentialité des enfants</h2>
      <p>Nos services ne sont pas destinés aux enfants de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnelles auprès des enfants. Si nous prenons connaissance qu'un enfant de moins de 13 ans nous a fourni des informations personnelles, nous prendrons des mesures pour supprimer ces informations.</p>
      
      <h2>8. Modifications de cette politique de confidentialité</h2>
      <p>Nous pouvons mettre à jour cette politique de confidentialité périodiquement pour refléter les changements dans nos pratiques ou les exigences légales. Toutes les modifications seront publiées sur cette page, et la date de "Dernière mise à jour" sera révisée.</p>
      `,

      termsAndConditionsTitle: "Termes et Conditions",
      termsAndConditionsDescription: `<p>Bienvenue sur Wellness Care Center ! Ces Termes et Conditions régissent votre utilisation de notre site Web et de nos services. En accédant à notre plateforme, vous acceptez de vous conformer à ces termes, veuillez donc les lire attentivement.</p>

<h2>1. Acceptation des termes</h2>
<p>En utilisant le site Web de Wellness Care Center, en créant un compte ou en achetant des services, vous acceptez d'être lié par ces Termes et Conditions ainsi que par notre Politique de confidentialité. Si vous n'êtes pas d'accord, veuillez vous abstenir d'utiliser nos services.</p>

<h2>2. Services fournis</h2>
<p>Wellness Care Center offre les services suivants via sa plateforme :</p>
<ul>
  <li>Gestion des abonnements au gym</li>
  <li>Achat de produits dans notre magasin de gym</li>
  <li>Réservation de bilans de santé</li>
</ul>
<p>Nous nous réservons le droit de modifier ou de suspendre l'un de nos services sans préavis.</p>

<h2>3. Comptes utilisateurs</h2>
<p>Pour accéder à certaines fonctionnalités de notre plateforme, telles que la gestion de votre abonnement ou la réservation de bilans de santé, vous devez créer un compte. Vous acceptez de :</p>
<ul>
  <li>Fournir des informations exactes et à jour lors de l'inscription.</li>
  <li>Garder vos identifiants de connexion confidentiels et ne pas les partager avec d'autres.</li>
  <li>Nous informer immédiatement si vous suspectez un accès non autorisé à votre compte.</li>
</ul>
<p>Vous êtes responsable de toutes les activités effectuées sous votre compte.</p>

<h2>4. Paiements</h2>
<p>Tous les paiements pour les abonnements au gym, les achats ou les services doivent être effectués via les méthodes de paiement disponibles sur notre plateforme. Vous acceptez de fournir des informations de paiement valides et d'autoriser Wellness Care Center à débiter votre mode de paiement sélectionné pour tous les frais applicables.</p>

<p>Si des problèmes surviennent lors du traitement de votre paiement, l'accès à nos services peut être restreint jusqu'à ce que la situation soit résolue. Toutes les transactions sont définitives et les remboursements seront effectués conformément à notre Politique de remboursement.</p>

<h2>5. Utilisation de la plateforme</h2>
<p>Vous acceptez d'utiliser le site Web et les services de Wellness Care Center à des fins légales uniquement. Il vous est interdit de :</p>
<ul>
  <li>Utiliser abusivement le site Web ou son contenu.</li>
  <li>Interférer avec la fonctionnalité de la plateforme ou la perturber.</li>
  <li>Tenter de pirater ou de violer la sécurité du site Web.</li>
  <li>Vous engager dans des activités frauduleuses ou illégales via la plateforme.</li>
</ul>

<h2>6. Propriété intellectuelle</h2>
<p>Tout le contenu du site Web de Wellness Care Center, y compris mais sans s'y limiter les textes, images, graphiques, logos et logiciels, est la propriété intellectuelle de Wellness Care Center. Vous ne pouvez pas reproduire, distribuer ou modifier l'un de nos contenus sans autorisation écrite préalable.</p>

<h2>7. Limitation de responsabilité</h2>
<p>Wellness Care Center n'est pas responsable des dommages directs, indirects, accidentels ou consécutifs résultant de l'utilisation ou de l'incapacité d'utiliser notre site Web ou nos services. Cela inclut, mais sans s'y limiter, les dommages causés par des virus ou des erreurs sur la plateforme, la perte de données ou l'accès non autorisé à votre compte.</p>

<p>Nous ne faisons aucune garantie, expresse ou implicite, concernant l'exactitude ou la fiabilité des services fournis sur notre plateforme. Vous utilisez notre site Web et nos services à vos propres risques.</p>

<h2>8. Annulation et résiliation</h2>
<p>Vous pouvez annuler votre abonnement au gym ou votre compte à tout moment en nous contactant. Wellness Care Center se réserve le droit de résilier ou de suspendre votre compte ou votre accès aux services si vous enfreignez ces Termes et Conditions.</p>

<h2>9. Modifications des Termes et Conditions</h2>
<p>Nous nous réservons le droit de modifier ces Termes et Conditions à tout moment. Les modifications seront publiées sur cette page avec la date de mise à jour. La poursuite de l'utilisation de nos services après ces modifications constitue votre acceptation des termes mis à jour.</p>

<h2>10. Loi applicable</h2>
<p>Ces Termes et Conditions sont régis et interprétés conformément aux lois de [Votre Pays/État], sans tenir compte de ses dispositions en matière de conflits de lois.</p>
`,
      refundPolicyTitle: "Politique de remboursement",
      refundPolicyDescription: `<p>Chez Wellness Care Center, nous visons à fournir un service de qualité à tous nos clients. Cette Politique de remboursement décrit les conditions sous lesquelles des remboursements sont accordés pour des services tels que les abonnements au gym, les achats en magasin et les bilans de santé. En utilisant nos services, vous acceptez cette Politique de remboursement.</p>

<h2>1. Abonnements au gym</h2>
<p>Tous les paiements d'abonnement au gym sont définitifs et non remboursables. Nous n'offrons pas de remboursements pour le temps d'abonnement non utilisé, les annulations anticipées ou les cours ou sessions manqués. Cependant, dans certaines circonstances exceptionnelles, des remboursements partiels peuvent être envisagés à la discrétion de Wellness Care Center. Ces circonstances peuvent inclure :</p>
<ul>
  <li>Urgences médicales (avec justificatif valide)</li>
  <li>Déménagement hors de la zone de service</li>
</ul>
<p>Si vous pensez être éligible à un remboursement dans ces conditions, veuillez nous contacter à [email address] et fournir les documents nécessaires. Les demandes seront examinées au cas par cas.</p>

<h2>2. Achats dans le magasin du gym</h2>
<p>Nous offrons une politique de remboursement de <strong>[Nombre]-jours</strong> pour les produits achetés dans notre magasin de gym. Pour être éligible à un remboursement, les conditions suivantes doivent être remplies :</p>
<ul>
  <li>L'article doit être inutilisé et dans le même état que vous l'avez reçu.</li>
  <li>L'article doit être dans son emballage d'origine.</li>
  <li>Un reçu ou une preuve d'achat est requis.</li>
</ul>
<p>Les remboursements pour les achats en magasin seront effectués sur le mode de paiement d'origine dans un délai de [Nombre] jours ouvrables une fois le produit retourné reçu et inspecté.</p>

<p><strong>Articles non remboursables :</strong> Certains articles tels que les produits de santé, les suppléments ou les articles personnalisés peuvent ne pas être éligibles à un remboursement. Veuillez lire attentivement la description du produit avant l'achat.</p>

<h2>3. Services de bilans de santé</h2>
<p>Les réservations de bilans de santé ne sont pas remboursables une fois confirmées. Si vous avez besoin de reprogrammer votre rendez-vous, veuillez nous contacter au moins 24 heures à l'avance. Le non-respect de ce délai peut entraîner la perte du paiement pour le service.</p>

<h2>4. Processus de remboursement</h2>
<p>Pour demander un remboursement, veuillez suivre ces étapes :</p>
<ul>
  <li>Envoyez-nous un e-mail à [email address] avec votre demande de remboursement, y compris les détails de votre commande, le reçu et la raison du remboursement.</li>
  <li>Si nécessaire, retournez l'article à notre adresse physique : [Adresse physique].</li>
  <li>Une fois que nous avons reçu votre demande ou l'article retourné, nous vous informerons par e-mail de l'approbation ou du rejet de votre remboursement.</li>
</ul>

<h2>5. Retards ou remboursements manquants</h2>
<p>Si vous n'avez pas encore reçu votre remboursement, veuillez suivre ces étapes :</p>
<ul>
  <li>Vérifiez votre compte bancaire ou votre mode de paiement pour voir si des transactions sont en attente.</li>
  <li>Contactez votre banque ou fournisseur de carte de crédit, car les délais de traitement peuvent varier.</li>
  <li>Si vous avez suivi toutes ces étapes et que vous n'avez toujours pas reçu votre remboursement, veuillez nous contacter à [email address].</li>
</ul>`,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

universalLocalStorage.getItem("langPreference").then((languagePreference) => {
  if (!languagePreference) languagePreference = "fr";

  console.log("using the lang", languagePreference);

  if (languagePreference) {
    i18n.changeLanguage(languagePreference);
  } else {
    i18n.changeLanguage();
  }
});

//forces the use of language detector

export default i18n;
