import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { useTranslation } from "react-i18next";
import goTo from "../../../../controllers/goTo";
import DetailsPageBoilerplate from "../../../detailsPage/DetailsPageBoilerplate";

function EmployeeDetails({ item }) {
  const { t } = useTranslation();

  let bookButton = (
    <PrimaryButton
      onClick={goTo(`/upcoming-activities/?employeeID=${item._id}`)}
      style={{ width: "100%", borderRadius: "10px" }}
    >
      Réserver
    </PrimaryButton>
  );

  return (
    <DetailsPageBoilerplate
      secondaryTitle={item.designation}
      topRightData={item.designation}
      title={item.name}
      description={item.description}
      imageData={item.image}
      buttons={bookButton}
    ></DetailsPageBoilerplate>
  );
}

export default EmployeeDetails;
