import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Img = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  border-radius: 10px;
  height: 163px;
  width: 257px;
  color: var(--accentColor);
  /* background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824); */
  border: 1px solid #222;
  background-color: var(--highlightBackgroundDark);
  /* cursor: pointer; */

  @media (max-width: 900px) {
    height: 40vw;
    width: 40vw;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;

  font-size: 12px;
  text-align: center;
  width: 90%;
  text-transform: uppercase;
  font-weight: 900;

  /* font-size: 18px; */
  font-weight: 700;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
`;

export default function HealthReportButton({ icon, children, title }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Img src={"/health-report-icons" + icon} />
      <Info>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </Info>
    </Container>
  );
}
