import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import formatDate from "../../../../controllers/formatDate";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;

  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: calc((85vw - 20px) / 1.5);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #303030;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: capitalize;
  font-size: 10px;
  color: #c9d400;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    font-size: 8px;
  }
`;

const Info = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(1deg, black, 50%, transparent);

  @media (max-width: 900px) {
    gap: 8px;
    padding: 20px 13px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-style: italic;

  @media (max-width: 900px) {
    font-size: 13px;
    margin-bottom: 0;
  }
`;

const Subtitle = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #808080;
  font-weight: 700;
  opacity: 0.7;
  @media (max-width: 900px) {
    font-size: 13px;
    font-weight: 700;
  }
`;

const Badge2 = styled(Badge)`
  left: 15px;
  right: unset;
  background: #c9d400;
  color: #303030;
`;

export default function ActivityTypeCardSmall({ item }) {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  return (
    <SemanticButton
      style={isMobile ? { width: "100%" } : null}
      href={`/activity-type/?itemID=${item._id}`}
    >
      <Card>
        <ImageContainer>
          <Image src={getImageURL(item.image)} alt="" />

          {item.bookingData ? (
            <Badge>{t("upcomingActivitiesBooked")}</Badge>
          ) : null}
        </ImageContainer>
        <Info>
          <Title>{item.title}</Title>
          <Subtitle>
            {t("duration")} : {item.duration} Mins
          </Subtitle>
        </Info>
      </Card>
    </SemanticButton>
  );
}
