import styled from "styled-components";
import formatNumber from "../../../../../controllers/utils/formatNumber";
import RechargeActivitySection from "./RechargeActivitySection";
import { useContext } from "react";
import Context from "../../../../../Context";
import getUserMembership from "../../../../../controllers/getMembershipData";
import calculateActivityRechargeTotal from "../../../../../controllers/payment/calculateActivityRechargeTotal";

export default function ShowDiscountedPrice({ activityTypes, newRecharge }) {
  const { membershipPlans, loggedInUser } = useContext(Context);

  let amount = calculateActivityRechargeTotal({
    activityTypes,
    rechargeDoc: newRecharge,
  });

  let membershipData = getUserMembership({
    membershipPlans,
    user: loggedInUser,
  });

  if (!membershipData) return null;

  if (!membershipData.isRoyalty) return null;

  return (
    <RechargeActivitySection title="After Royalty Discount">
      {formatNumber(amount * 0.85)} CFA
    </RechargeActivitySection>
  );
}
