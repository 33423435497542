import styled from "styled-components";
import { useContext } from "react";
import HealthReportButton from "../HealthReportButton";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";
import DetailsSection from "../../../detailsPage/DetailsSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReportIsPending = styled.div`
  font-size: 14px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(--accentColor);
  text-align: left;
  font-style: italic;
  margin: 0;
  text-transform: uppercase;
  background: var(--highlightBackground);
  padding: 10px;
  border-radius: 10px;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Medium = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const SmallTitle = styled.div`
  font-size: 14px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;
`;

const ReportData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 1200px;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 50vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  @media (max-width: 900px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 55;
    padding: 20px;
    box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
    background-color: var(--rootBackgroundColor);
    width: 100vw;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ListItem = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
  white-space: pre-line;
  font-family: Montserrat, sans-serif;

  &::before {
    content: "•";
    color: #c9d400;
    margin-right: 8px;
  }

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

export default function ReportSection({ bookingData, activityType }) {
  const { adminSettings } = useContext(Context);

  const { t } = useTranslation();

  if (!adminSettings) return null;

  if (!adminSettings.data) return null;

  if (!bookingData.remark)
    return (
      <DetailsSection title={t("activityItemRemark")}>
        <ReportIsPending>Report is pending</ReportIsPending>
      </DetailsSection>
    );

  if (adminSettings.data.healthCheckupActivityTypeID == activityType._id)
    return getHealthReport();

  return getGeneralReport();

  function getGeneralReport() {
    return bookingData ? (
      bookingData.remark ? (
        <DetailsSection title={t("activityItemRemark")}>
          <List>
            <ListItem>{bookingData.remark}</ListItem>
            {bookingData.remark2 ? (
              <ListItem>{bookingData.remark2}</ListItem>
            ) : null}
            {bookingData.remark3 ? (
              <ListItem>{bookingData.remark3}</ListItem>
            ) : null}
          </List>
        </DetailsSection>
      ) : null
    ) : null;
  }

  function getHealthReport() {
    let reportData = null;

    if (bookingData.reportData) {
      reportData = (
        <>
          <HealthReportButton
            icon="/weight.png"
            title={t("healthReportItemWeight")}
          >
            {bookingData.reportData.weight} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/lean-body-mass.png"
            title={t("healthReportItemLearnBodyMass")}
          >
            {bookingData.reportData.leanBodyMass} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/muscle-mass.png"
            title={t("healthReportItemMuscleMass")}
          >
            {bookingData.reportData.muscleMass} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/body-water.png"
            title={t("healthReportItemBodyWater")}
          >
            {bookingData.reportData.bodyWater} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/body-fat-mass.png"
            title={t("healthReportItemBodyFatMass")}
          >
            {bookingData.reportData.bodyFatMass} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/inorganic-salts.png"
            title={t("healthReportItemInorganicSalt")}
          >
            {bookingData.reportData.inorganicSalts} lbs
          </HealthReportButton>
          <HealthReportButton
            icon="/protein.png"
            title={t("healthReportItemProtein")}
          >
            {bookingData.reportData.protein} lbs
          </HealthReportButton>
        </>
      );
    }

    return (
      <Container>
        {reportData ? (
          <DetailsSection title={t("healthReportItemRemark")}>
            <Info>
              <Medium>
                {bookingData.remark
                  ? bookingData.remark
                  : "Remark Not Available"}
              </Medium>
            </Info>
            <ReportData>{reportData}</ReportData>
          </DetailsSection>
        ) : (
          <ReportIsPending>Report is pending</ReportIsPending>
        )}
      </Container>
    );
  }
}
