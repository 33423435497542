import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MdDone } from "react-icons/md";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding-right: 50px; */

  gap: 30px;
  font-weight: bold;
  font-size: 20px;

  padding: 20px 30px;
  border-radius: 20px;
  padding-bottom: 50px;
  width: 100%;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const Features = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0.8;
  flex-direction: column;
`;

const PlanName = styled.span`
  color: var(--accentColor);
  font-size: 19px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
`;

const PriceTag = styled.div`
  color: var(--accentColor);
  font-size: 13px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: var(--accentColor);
  text-align: center;
  text-transform: capitalize;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const Icon = styled.div`
  background-color: #e3f004;
  height: 100px;
  width: 100px;
  color: #000;
  border-radius: 100px;
  font-size: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  /* margin-top: -3px; */
`;

const PurchaseDone = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Icon>
        <MdDone />
      </Icon>

      <CardTitle>
        <PlanName>{t("yourRequestHasBeenSent")} </PlanName>
        <PriceTag> {children} </PriceTag>
      </CardTitle>
    </Card>
  );
};

export default PurchaseDone;
