import styled from "styled-components";
import IncrementSession from "./IncrementSession";
import RechargeActivitySection from "./RechargeActivitySection";
import { useTranslation } from "react-i18next";

const Container = styled.div``;

export default function IncrementSessionSection({
  activityTypes,
  newRecharge,
  setNewRecharge,
}) {
  const { t } = useTranslation();
  let items = [];

  for (let type in newRecharge) {
    let allItems = newRecharge[type];

    for (let itemID in allItems) {
      let quantity = allItems[itemID];
      items.push(
        <IncrementSession
          type={type}
          activityTypeID={itemID}
          activityTypes={activityTypes}
          quantity={quantity}
          newRecharge={newRecharge}
          setNewRecharge={setNewRecharge}
        />
      );
    }
  }

  return (
    <RechargeActivitySection title={t("activities")}>
      {items}
    </RechargeActivitySection>
  );
}
