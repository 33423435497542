import { GoArrowRight } from "react-icons/go";
import CustomButton from "./CustomButton";
import { Button } from "@mui/material";
import styled from "styled-components";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import goTo from "../../controllers/goTo";

const Text = styled.div`
  /* text-transform: lowercase; */
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;

  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 13px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PrimaryButton({
  onClick,
  children,
  style = {},
  textStyle,
  icon = <GoArrowRight />,
  iconStyle,
  href,
}) {
  if (!textStyle) textStyle = {};

  if (icon) icon = <Icon iconStyle={iconStyle}>{icon}</Icon>;

  if (href && !onClick) {
    onClick = () => {
      goTo(href)();
    };
  }

  return (
    <Button
      onClick={onClick}
      style={{
        minWidth: "250px",
        padding: "15px 0",
        gap: "20px",
        borderRadius: "50px",
        boxShadow: "1px 6px 0px 0 #6e8a0e",
        flexDirection: "row-reverse",
        background: "linear-gradient(45deg, #ffee11, #82b507)",
        ...style,
      }}
      variant="contained"
      startIcon={icon}
    >
      <Text style={textStyle}>{capitalizeFirstLetter(children)}</Text>
    </Button>
  );

  // return (
  //   <CustomButton
  //     containerStyle={containerStyle}
  //     onClick={onClick}
  //     iconStyle={{ fontSize: "21px" }}
  //     textStyle={{
  //       fontSize: "17px",
  //       fontWeight: "800",
  //       fontFamily: "Montserrat",
  //       ...textStyle,
  //     }}
  //     onHoverStyle={`

  //       transform:scale(0.95);

  //       `}
  //     style={{
  //       color: "var(--bgColor)",
  //       borderRadius: "100px",
  //       width: "200px",
  //       gap: "20px",
  //       border: "none",
  //       flexDirection: "row-reverse",
  //       fontWeight: "900",
  //       background: "linear-gradient(90deg, #ced400 0%, #75a500 100%)",
  //       transition: "all 0.25s ease-in-out",
  //       boxShadow: "rgb(120 166 0 / 72%) 1px 5ppx 50px 1px",
  //       textTransform: "capitalize",
  //       padding: "13px 0",
  //       ...style,
  //     }}
  //     icon={icon}
  //   >
  //     {children}
  //   </CustomButton>
  // );
}
