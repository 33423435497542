import CustomButton from "../../../../helperComponents/CustomButton";

export default function AddSession({
  type,
  itemID,
  title,
  newRecharge,
  setNewRecharge,
}) {
  if (newRecharge[type]) {
    if (newRecharge[type][itemID] == 0 || newRecharge[type][itemID]) {
      return null;
    }
  }

  return <CustomButton onClick={addItem}>{title}</CustomButton>;

  function addItem() {
    let newData = { ...newRecharge };
    if (!newData[type]) newData[type] = {};
    newData[type][itemID] = 1;
    setNewRecharge(newData);
  }
}
