import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Link from "../../helperComponents/Link";
import { useTranslation } from "react-i18next";
import { serverLine } from "../../../controllers/network/serverLine";
import getMembershipPlanName from "../../../controllers/getMembershipPlanName";
import Context from "../../../Context";
import parseExpiresAt from "../../../controllers/parseExpiresAt";
import CustomButton from "../../helperComponents/CustomButton";

const Card = styled.div`
  padding: 0 20px;
  border-radius: 10px;
  width: auto;
  color: white;
  height: 65px;
  background-color: var(--highlightBackgroundDark);
  border: 1px solid #222;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: fadeIn 0.6s ease-in;
  /* align-items: center; */

  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  @media (max-width: 900px) {
    height: 50px;
    padding: 0 15px;
  }
`;

const Content = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  @media (max-width: 900px) {
    padding: 0 0;
  }
`;

const Title = styled.h2`
  font-size: 15px;
  font-weight: 900;
  color: #c9d400;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const Expiration = styled.p`
  font-size: 12px;
  color: #808080;
  margin: 0;
  /* text-transform: uppercase; */

  @media (max-width: 900px) {
    font-size: 7px;
  }
`;

const Highlight = styled.span`
  color: #c9d400;
  font-weight: bold;
  margin: 0;
  /* text-transform: uppercase; */
`;

const MembershipMiniInfo = ({}) => {
  const { loggedInUser, isMobile, membershipPlans, updateMembershipPlans } =
    useContext(Context);

  const { t } = useTranslation();

  useEffect(() => {
    updateMembershipPlans();
  }, []);

  if (!membershipPlans) return null;

  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  let planName = getMembershipPlanName({
    membershipIdVsPlan,
    membershipPlanID: loggedInUser.membershipPlanID,
  });

  return (
    <CustomButton
      href={"/membership"}
      style={{ borderRadius: "10px", height: isMobile ? "40px" : "65px" }}
      textStyle={{ fontSize: "9px" }}
    >
      {getContent()}
    </CustomButton>
  );

  function getContent() {
    let expiry = parseExpiresAt(loggedInUser.membershipPlanValidity);

    if (!planName || !expiry || planName == "NA") return t("noMembershipPlan");

    return `${planName}: ${parseExpiresAt(loggedInUser.membershipPlanValidity)} Days`;
  }
};

/*

Old content
*/

export default MembershipMiniInfo;
