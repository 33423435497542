import { useState } from "react";
import styled from "styled-components";
import SectionTitle from "../detailsPage/SectionTitle";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../helperComponents/PrimaryButton";
import { BsCash } from "react-icons/bs";
import LoadingSection from "../helperComponents/LoadingSection";
import PurchaseDone from "./PurchaseDone";
import formatNumber from "../../controllers/utils/formatNumber";
import SecondaryText from "../detailsPage/SecondaryText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 35px;
`;
const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
`;

let disabledButtonStyle = {
  opacity: 0.5,
  pointerEvents: "none",
};

const Total = styled.div`
  border-bottom: 1px solid #333333;
  padding: 20px 0;
`;

const PurchaseDetails = styled.div`
  border-bottom: 1px solid #333333;
  padding: 20px 0;
  margin-top: 30px;
`;

export default function CheckoutPopup({
  purchaseDetails,
  totalAmount,
  onCashSelection,
}) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  let { t } = useTranslation();

  let onClick = () => {
    setLoading(true);
    onCashSelection({
      onSuccess: () => {
        setLoading(false);
        setDone(true);
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  if (done) {
    return <PurchaseDone>{purchaseDetails}</PurchaseDone>;
  }

  if (!done) {
    return (
      <Container>
        <SectionTitle>{t("checkout")}</SectionTitle>
        <PurchaseDetails>
          <SecondaryText>{purchaseDetails}</SecondaryText>
        </PurchaseDetails>

        {totalAmount ? (
          <Total>
            <SecondaryText>
              Total : {formatNumber(totalAmount)} CFA{" "}
            </SecondaryText>
          </Total>
        ) : null}

        <Options>
          <PrimaryButton onClick={onClick} icon={<BsCash />}>
            Cash
          </PrimaryButton>
          <PrimaryButton style={disabledButtonStyle}>
            Orange Money
          </PrimaryButton>
          <PrimaryButton style={disabledButtonStyle}>Wave</PrimaryButton>
        </Options>
      </Container>
    );
  }
}
