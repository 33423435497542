import { Capacitor } from "@capacitor/core";

let productionServer = "https://wellness-backend.upon.one";
let devServer = "http://192.168.29.21:8080";
function getServerURL() {
  let base = productionServer;

  // base = "https://eden-wellness-care-center-8r7z.vercel.app"

  if (window.location.origin.indexOf("localhost") !== -1) {
    base = devServer;
  }

  if (window.location.origin.indexOf("192.168") !== -1) {
    base = devServer;
  }

  let platform = Capacitor.getPlatform();

  if (platform !== "web") {
    //Is case of production in app the url is https://localhost
    //So if there is http in the android app it is likely using livereload
    //otherwise it is a build apk, and in the build apk we will
    //use hosted backend
    let isDev = window.location.href.startsWith("http://");
    if (isDev) {
      base = devServer;
    } else {
      base = productionServer;
    }
  }

  // console.log(base);
  return base;
}

export default getServerURL;
