import { useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/network/serverLine";
import styled from "styled-components";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import AdminPageBoilerplate from "../AdminPageBoilerplate";
import { t } from "i18next";
import MembershipRequestCard from "./ActivityRechargeRequestCard";
import ActivityRechargeRequestCard from "./ActivityRechargeRequestCard";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  /* width: 62vw; */

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

export default function ManageActivityRechargeRequests() {
  const [activityTypes, setActivityTypes] = useState(null);
  const [loading] = useState(false);
  const [rechargeRequests, setRechargeRequests] = useState(null);

  useEffect(() => {
    serverLine.get("/activity-types").then(setActivityTypes);
    serverLine
      .get("/admin/activity-recharge-requests")
      .then(setRechargeRequests);
  }, []);

  if (!activityTypes || loading || !rechargeRequests)
    return (
      <AdminPageBoilerplate title={t("rechargeActivityRequest")}>
        <LoadingSection />
      </AdminPageBoilerplate>
    );

  let activityTypeIdVsData = {};

  for (let item of activityTypes) {
    activityTypeIdVsData[item._id] = item;
  }

  return (
    <AdminPageBoilerplate title={t("rechargeActivityRequest")}>
      <Container>
        {rechargeRequests.map((item) => (
          <ActivityRechargeRequestCard
            item={item}
            activityTypeIdVsData={activityTypeIdVsData}
          />
        ))}
      </Container>
    </AdminPageBoilerplate>
  );
}
