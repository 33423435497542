export default function calculateExactMinsForBooking({ divisions, mark }) {
  if (divisions <= 0) {
    throw new Error("Divisions must be greater than zero.");
  }
  if (mark < 0 || mark >= divisions) {
    throw new Error("Mark must be within the range of divisions.");
  }

  let minute = Math.floor((mark * 60) / divisions);
  return minute < 10 ? `0${minute}` : `${minute}`;
}
