import { useContext, useState } from "react";
import WithBackground from "../../../core/boilerplate/WithBackground";
import CustomToggle from "../../../helperComponents/CustomToggle";
import LoggedOutHeader from "../landingPage/LoggedOutHeader";
import styled from "styled-components";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import InputCard from "../../../helperComponents/InputCard";
import CustomButton from "../../../helperComponents/CustomButton";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/network/serverLine";
import saveUserAuth from "../../../../controllers/auth/saveUserAuth";
import { GrGoogle } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import BrandContainer from "../../../brand/BrandContainer";
import Context from "../../../../Context";
import doGoogleLogin from "../../../../utils/loginWithGoogle/doGoogleLogin";
import SwipeBack from "../../loggedIn/SwipeBackGesture";
import AnimatedPage from "../../loggedIn/AnimatedPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 40px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-end;
`;

const AppUIContainer = styled.div`
  display: flex;
  height: 100vh;
`;
const AnimatedHeroImage = styled.img`
  border-radius: 15px;

  height: 90vh;
  width: 100vw;
  object-fit: cover;
`;
const FloatingAppUISection = styled.div`
  position: absolute;
  bottom: 0;

  padding: 0;
  width: 100vw;
  background: #000;
  height: auto;
  display: flex;
  padding-top: 15px;
  border-top: 1px solid #d1da30;
  box-shadow: 0px -10px 30px 10px rgba(0, 0, 0, 0.71);
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  padding-top: 20px;
  background: linear-gradient(0deg, transparent, 20%, #000, #000);
  height: 200px;
`;

export default function LoginSignupPage({ initialType }) {
  const { isMobile } = useContext(Context);
  const [type, setType] = useState(initialType ? initialType : "LOGIN");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const options = [
    { value: "LOGIN", label: t("login") },
    { value: "SIGNUP", label: t("signup") },
  ];

  let loginWithGoogle = (
    <CustomButton
      onClick={doGoogleLogin({ setLoading })}
      style={{
        color: "var(--accentColor)",
        height: "65px",
        width: "65px",
        padding: "0",
        borderRadius: "500px",
      }}
      icon={<GrGoogle />}
    ></CustomButton>
  );

  let core = (
    <InputCard
      submitButtonText={t("authSubmit")}
      onSubmit={submit}
      additionalButtons={loginWithGoogle}
    >
      <Form>
        <CustomToggle options={options} value={type} onChange={setType} />
        {getInputs()}
      </Form>
    </InputCard>
  );

  if (loading)
    core = (
      <InputCard>
        <LoadingSection />
      </InputCard>
    );

  if (isMobile) return getAppUI();

  return (
    <WithBackground backgroundImage="/dumbbells.jpg">
      <Container>
        <LoggedOutHeader />

        {core}
      </Container>
    </WithBackground>
  );

  function getAppUI() {
    return (
      <SwipeBack>
        <AnimatedPage>
          <AppUIContainer>
            <AnimatedHeroImage src="/hero/guy1.jpg" />

            <Top>
              <BrandContainer />
            </Top>
            <FloatingAppUISection>{core}</FloatingAppUISection>
          </AppUIContainer>
        </AnimatedPage>
      </SwipeBack>
    );
  }

  function submit() {
    if (type == "LOGIN") return login();
    signup();
  }

  async function login() {
    if (!email) return window.popupAlert("Please input the email");
    if (!password) return window.popupAlert("Please input the password");

    try {
      setLoading(true);
      let authData = await serverLine.post("/email-login", { email, password });

      setLoading(false);
      saveUserAuth(authData);
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }

  async function signup() {
    if (!email) return window.popupAlert("Please input the email");
    if (!password) return window.popupAlert("Please input the password");

    if (password !== confirmPassword)
      return window.popupAlert("Confirm password did not match");

    try {
      setLoading(true);
      let authData = await serverLine.post("/email-signup", {
        email,
        password,
      });

      setLoading(false);
      saveUserAuth(authData);
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }

  function getInputs() {
    if (type == "LOGIN")
      return (
        <Inputs>
          <MaterialInput
            label={t("authEmailInputLabel")}
            // placeholder={"Type Email Here"}
            value={email}
            onChange={extractEventValue(setEmail)}
          />
          <MaterialInput
            type="password"
            label={t("authPasswordInputLabel")}
            // placeholder={"Type Password Here"}
            value={password}
            onChange={extractEventValue(setPassword)}
          />
          <CustomButton variant="minimal-underlined" href="/forgot-password">
            {t("authForgotPassword")}
          </CustomButton>
        </Inputs>
      );

    if (type == "SIGNUP")
      return (
        <Inputs>
          <MaterialInput
            label={t("authEmailInputLabel")}
            // placeholder={"Type Email Here"}
            value={email}
            onChange={extractEventValue(setEmail)}
          />
          <MaterialInput
            type="password"
            label={t("authPasswordInputLabel")}
            // placeholder={"Type Password Here"}
            value={password}
            onChange={extractEventValue(setPassword)}
          />
          <MaterialInput
            type="password"
            label={t("authConfirmPasswordInputLabel")}
            // placeholder={"Type Confirm Password Here"}
            value={confirmPassword}
            onChange={extractEventValue(setConfirmPassword)}
          />
        </Inputs>
      );
  }
}
