import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useEffect, useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import ActivityDetails from "./ActivityDetails";
import fetchDataWithCaching from "../../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";

export default function ActivityDetailsPage() {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    doLoading(false);
  }, []);

  async function doLoading(skipCache) {
    if (skipCache) setLoading(true);
    let urlQuery = getUrlQuery();

    let newData = await fetchDataWithCaching({
      path: `/activity/?itemID=${urlQuery.itemID}`,
      skipCache,
      setLoading,
      onFreshData: setItem,
    });

    setLoading(false);
    setItem(newData);
  }

  async function handleRefresh() {
    await doLoading(true);
  }

  if (loading)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (!item) return <LoggedInBoilerplate></LoggedInBoilerplate>;

  return (
    <LoggedInBoilerplate>
      <PullToRefresh onRefresh={handleRefresh}>
        <ActivityDetails
          doLoading={doLoading}
          item={item.activity}
          bookingData={item.booking}
        />
      </PullToRefresh>
    </LoggedInBoilerplate>
  );
}
