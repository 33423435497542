import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import getImageURL from "../../../../controllers/getImageURL";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import StoreItemImageGallery from "./StoreItemImageGallery";
import fetchDataWithCaching from "../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";
import StoreItemPageMobile from "./StoreItemPageMobile";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  padding: 40px 0;
  gap: 50px;
  color: #fff;
  width: 90vw;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;

    padding-top: 20px;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
`;

const Details = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    max-width: unset;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-size: 23px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
`;

const Price = styled.div`
  font-size: 30px;
  font-size: 18px;
  color: var(--accentColor);
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
`;

const Description = styled.div`
  margin: 20px 0;
`;

const Subtitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
`;

const ShortDesc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const LongDesc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;
  white-space: pre-line;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  /* background: var(--translucent); */
  /* border: var(--highlightBorder); */
  border-radius: 10px;
  /* overflow: hidden; */
`;

const SmallHeading = styled.div`
  color: var(--accentColor);
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
`;

const MainData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
`;

const FloatingButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 55;
  padding: 20px;
  box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
  background-color: var(--rootBackgroundColor);
  width: 100vw;
`;

export default function StoreItemPage() {
  const { loggedInUser, setLoggedInUser, isMobile } = useContext(Context);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(null);
  const [item, setItem] = useState(null);
  const [addingToCart, setAddingToCart] = useState(false);

  useEffect(() => {
    doLoading();
  }, []);

  async function doLoading(skipCache) {
    let urlQuery = getUrlQuery();
    let data = await fetchDataWithCaching({
      path: `/store-item/?itemID=${urlQuery.itemID}`,
      setLoading,
      skipCache,
      onFreshData: setItem,
    });
    setItem(data);
  }

  async function handleRefresh() {
    await doLoading(true);
  }

  if (loading || !loggedInUser)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (!item) return null;

  let mainButton = (
    <PrimaryButton
      style={{ width: "100%", borderRadius: "10px" }}
      onClick={addToCart}
    >
      {t("addToCart")}
    </PrimaryButton>
  );

  if (addingToCart) mainButton = <LoadingSection />;

  let details = (
    <Details>
      <LongDesc>{item.description}</LongDesc>
    </Details>
  );

  if (isMobile)
    return (
      <StoreItemPageMobile
        mainButton={mainButton}
        item={item}
        details={details}
        handleRefresh={handleRefresh}
      />
    );

  return (
    <LoggedInBoilerplate>
      <PullToRefresh onRefresh={handleRefresh}>
        <Container>
          <RightSection>
            <ImageSection>
              <StoreItemImageGallery images={item.images} />
            </ImageSection>

            {mainButton}
          </RightSection>

          <InfoSection>
            <MainData>
              <Title>{item.title} </Title>
              <Price>{item.price} CFA</Price>
            </MainData>
            {details}
          </InfoSection>
        </Container>
      </PullToRefresh>
    </LoggedInBoilerplate>
  );

  async function addToCart() {
    let featureEnabled = false;

    if (!featureEnabled)
      return window.popupAlert("This feature is not yet available.");

    if (!item.currentStock) return window.popupAlert(t("itemIsOutOfStock"));

    let itemID = item._id;

    let cartData = {};

    if (loggedInUser.cart) cartData = { ...loggedInUser.cart };

    let newQuantity = 0;

    if (cartData[itemID]) {
      cartData[itemID].quantity++;

      newQuantity = cartData[itemID].quantity;
    } else {
      newQuantity = 1;
      cartData[itemID] = { quantity: 1 };
    }

    if (newQuantity > item.currentStock)
      return window.popupAlert(t("thatManyStockIsNotAvailable"));

    setAddingToCart(true);
    try {
      await serverLine.patch("/me", { changes: { cart: cartData } });
      let newLoggedInUser = { ...loggedInUser, cart: cartData };

      setLoggedInUser(newLoggedInUser);
      window.popupAlert(t("addedToCart"));
    } catch (e) {
      window.popupAlert(e.message);
    }

    setAddingToCart(false);
  }
}
