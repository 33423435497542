import { useEffect, useState } from "react";
import BigToggle from "../../../../helperComponents/BigToggle";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/network/serverLine";
import addAdditionalZero from "../../../../../controllers/addAdditionalZero";

export default function ScheduleSlotPicker({
  value,
  onChange,
  date,
  hour,
  label,
  activityType,
}) {
  const [existingBookings, setExistingBookings] = useState(null);

  useEffect(() => {
    setExistingBookings(null);
    serverLine
      .get(
        `/all-upcoming-booking/?activityTypeID=${activityType._id}&data=${date}&hour=${hour}`
      )
      .then(setExistingBookings);
  }, [date, hour]);

  useEffect(() => {
    onChange(null);
  }, [date, hour]);

  if (!date || !hour) return null;

  if (!existingBookings) return <LoadingSection />;

  let existingBookingMap = {};

  for (let item of existingBookings) {
    let id = generateID(item);
    existingBookingMap[id] = true;
  }

  if (!hour || !date) return null;

  let items = getSlots(getNumberOfSlots());

  return (
    <BigToggle
      title={label}
      options={items.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    let booked = isBooked(item);

    if (value === item && booked) {
      booked = false;
    }

    // let booked = false;

    // console.log(item, value, item == value);

    let oneSlotVal = getValueOfOneSlotInMins();

    return {
      onClick: booked
        ? null
        : () => {
            onChange(item);
          },
      isSelected: value === item,
      label: booked
        ? "BOOKED"
        : `${addAdditionalZero(hour)} : ${addAdditionalZero(oneSlotVal * (item - 1))}`,
    };
  }

  function getValueOfOneSlotInMins() {
    let totalSlots = getNumberOfSlots();

    return Math.round(60 / totalSlots);
  }

  function isBooked(slotNumber) {
    let thisID = generateID({ hour, date, slot: slotNumber });

    console.log(slotNumber, thisID, existingBookingMap[thisID]);
    if (existingBookingMap[thisID]) {
      return true;
    }

    return false;
  }

  function generateID({ hour, date, slot }) {
    return `${date}-${hour}-${slot}`;
  }

  function getNumberOfSlots() {
    let slots = 4;

    if (activityType) {
      if (activityType.slotsPerHour) {
        slots = activityType.slotsPerHour;
      }
    }

    return slots;
  }
}

function getSlots(max) {
  let timeSlots = [];

  for (let i = 1; i <= max; i++) {
    timeSlots.push(i);
  }

  return timeSlots;
}
