import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MembershipSelectionCard from "./MembershipSelectionCard";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0;
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const PlanFeatures = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 200px;
`;

const PlanFeature = styled.div`
  margin-bottom: 5px;
  display: flex;

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    height: 25px;
    width: 25px;
    content: "✓";
    color: #ffffff;
    border-radius: 100px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: -3px;
  }
`;

const MembershipSelection = ({ value, onChange }) => {
  const [plans, setPlans] = useState(null);

  useEffect(() => {
    serverLine.get("/membership-plans").then(setPlans);
  }, []);

  if (!plans)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      {plans.map((item, index) => {
        if (item.status == "DISABLED") return null;
        return (
          <MembershipSelectionCard
            key={index}
            title={item.name}
            desc={item.description}
            price={item.price}
            name={item.name.toUpperCase()}
            features={
              <PlanFeatures>
                <PlanFeature>{item.feature1}</PlanFeature>
                <PlanFeature>{item.feature2}</PlanFeature>
                <PlanFeature>{item.feature3}</PlanFeature>
                <PlanFeature>{item.feature4}</PlanFeature>
              </PlanFeatures>
            }
          />
        );
      })}
    </Container>
  );
};

export default MembershipSelection;
