import React from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import formatDate from "../../../../controllers/formatDate";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";
import calculateExactMinsForBooking from "../../../../controllers/calculateExactMinsForBooking";
import getDateAndTimeOfBooking from "../../../../controllers/getDateAndTimeOfBooking";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  height: 300px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;

  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
    height: 90vw;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #303030;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #c9d400;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(1deg, black, 50%, transparent);

  @media (max-width: 900px) {
    gap: 8px;
    padding: 20px 13px;
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-style: italic;

  @media (max-width: 900px) {
    font-size: 13px;
    margin-bottom: 0;
  }
`;

const Subtitle = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #808080;
  font-weight: 700;
  opacity: 0.7;
  @media (max-width: 900px) {
    font-size: 13px;
    font-weight: 700;
  }
`;

export default function ActivityBookingCard({ item }) {
  const { t } = useTranslation();

  return (
    <SemanticButton href={`/booking-and-report/?itemID=${item._id}`}>
      <Card>
        <ImageContainer>
          <Image src={getImageURL(item.activityType.image)} alt="" />
          {/* <Badge>{t("myActivitiesBooked")}</Badge> */}
        </ImageContainer>
        <InfoContainer>
          <Title>{item.activityType.title}</Title>
          <Subtitle>
            {getDateAndTimeOfBooking({
              bookingData: item,
              activityType: item.activityType,
            })}
          </Subtitle>
        </InfoContainer>
      </Card>
    </SemanticButton>
  );
}
