import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import getImageURL from "../../../../../controllers/getImageURL";
import { serverLine } from "../../../../../controllers/network/serverLine";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import goTo from "../../../../../controllers/goTo";
import SemanticButton from "../../../../helperComponents/SemanticButton";
import Context from "../../../../../Context";
import { IoTimeOutline } from "react-icons/io5";
import { HiOutlineUser } from "react-icons/hi2";
import DetailsSection from "../../../../detailsPage/DetailsSection";
import DetailsPageBoilerplate from "../../../../detailsPage/DetailsPageBoilerplate";
import SecondaryText from "../../../../detailsPage/SecondaryText";
import BookingCreationPopup from "../../../../booking/BookingCreationPopup";
import formatDate from "../../../../../controllers/formatDate";
import calculateExactMinsForBooking from "../../../../../controllers/calculateExactMinsForBooking";
import ReportSection from "../../booking/ReportSection";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 90vw;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
    padding: 0;
    width: 90vw;
  }
`;

const CoachAndDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    gap: 80px;
    width: 100%;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  font-size: 30px;
  opacity: 0.7;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  opacity: 0.7;
  white-space: pre-line;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const EmployeeData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EmployeeName = styled.div`
  font-weight: 800;
  opacity: 0.5;
  font-style: italic;
  font-weight: 800;

  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
`;

const EmployeeDesc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;
  white-space: pre-line;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const IconMainText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  font-weight: 600;
  display: flex;
  opacity: 0.7;

  font-family: "Montserrat", sans-serif;

  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const IconSecondaryText = styled.div`
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  text-transform: capitalize;
  font-weight: 800;
  display: flex;

  font-family: "Montserrat", sans-serif;

  color: var(--accentColor);
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;

function ActivityBookingDetails({ activityType, bookingData }) {
  const { membershipPlans, updateMembershipPlans } = useContext(Context);

  useEffect(() => {
    updateMembershipPlans();
  }, []);

  const { t } = useTranslation();

  if (!membershipPlans) return <LoadingSection />;
  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  let showReportButton = null;

  if (bookingData) {
    if (bookingData.reportFile) {
      showReportButton = (
        <PrimaryButton
          onClick={goTo(getImageURL(bookingData.reportFile))}
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        >
          {t("activityItemShowReport")}
        </PrimaryButton>
      );
    }
  }

  let textContent = (
    <>
      <CoachAndDuration>
        <SemanticButton
          onClick={goTo("/employee/?itemID=" + activityType.employee._id)}
        >
          <IconAndText style={{ cursor: "pointer" }}>
            <Icon>
              <HiOutlineUser />
            </Icon>
            <IconText>
              <IconMainText> {t("activityItemCoach")} </IconMainText>
              <IconSecondaryText>
                {activityType.employee.name}{" "}
              </IconSecondaryText>
            </IconText>
          </IconAndText>
        </SemanticButton>

        <IconText>
          <IconAndText>
            <Icon>
              <IoTimeOutline />
            </Icon>

            <IconText>
              <IconMainText>{t("activityItemDuration")}</IconMainText>
              <IconSecondaryText>{activityType.duration} min</IconSecondaryText>
            </IconText>
          </IconAndText>
        </IconText>
      </CoachAndDuration>

      <SecondaryText>{activityType.description}</SecondaryText>

      <DetailsSection title={t("activityItemDetails")}>
        <List>
          <SecondaryText>
            {t("activityItemParticipationType")} :{" "}
            {activityType.participationType
              ? t(activityType.participationType)
              : "N/A"}
          </SecondaryText>
          <SecondaryText>
            {t("activityItemMembershipType")} :{" "}
            {activityType.membershipPlanIDs
              ? activityType.membershipPlanIDs
                  .map((itemID) => {
                    let item = membershipIdVsPlan[itemID];
                    return item.name;
                  })
                  .join(", ")
              : "N/A"}
          </SecondaryText>
        </List>
      </DetailsSection>

      <ReportSection bookingData={bookingData} activityType={activityType} />

      <DetailsSection title={t("activityItemAboutCoach")}>
        <EmployeeData>
          <EmployeeName>{activityType.employee.name}</EmployeeName>
          <SecondaryText>{activityType.employee.description}</SecondaryText>
        </EmployeeData>
      </DetailsSection>
    </>
  );

  let secondaryTitle = `
    ${formatDate(bookingData.date, true)} @ ${bookingData.hour} :
              ${calculateExactMinsForBooking({
                divisions: activityType.slotsPerHour,
                mark: bookingData.slot,
              })}
  `;

  return (
    <DetailsPageBoilerplate
      topRightData={
        activityType.participationType
          ? activityType.participationType.toLowerCase()
          : null
      }
      title={activityType.title}
      secondaryTitle={secondaryTitle}
      imageData={activityType.image}
      buttons={showReportButton}
    >
      {textContent}
    </DetailsPageBoilerplate>
  );
}

export default ActivityBookingDetails;
