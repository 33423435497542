import styled from "styled-components";
import WithBackground from "../../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../../LoggedInHeader";
import InputCard from "../../../../helperComponents/InputCard";
import ScheduleDatePicker from "./ScheduleDatePicker";
import { useEffect, useState } from "react";
import ScheduleHourPicker from "./ScheduleHourPicker";
import ScheduleSlotPicker from "./ScheduleSlotPicker";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import submitData from "../../../../../controllers/request/submitData";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/network/serverLine";
import ExistingCheckupBooking from "./ExistingCheckupBooking";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../../helperComponents/TitleBar";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import fetchDataWithCaching from "../../../../../controllers/network/fetchDataWithCaching";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 900px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 55;
    padding: 20px;
    box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
    background-color: var(--rootBackgroundColor);
    width: 100vw;
  }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #222;
  border-radius: 10px;
  width: 38vw;
  /* height: 50vh; */
  padding: 20px;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    width: 90vw;
    height: auto;
    border: none;
    padding: 0;
  }
`;

export default function ScheduleCheckupPage() {
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [slot, setSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [upcomingChecksUps, setUpcomingChecksUps] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    resetAndDoQuery();
  }, []);

  async function resetAndDoQuery() {
    setDate(null);
    setHour(null);
    setSlot(null);
    // setLoading(true);
    let newData = await fetchDataWithCaching({
      path: "/my-upcoming-health-checkups/?onlyPending=true",
      onFreshData: setUpcomingChecksUps,
      // setLoading,
    });
    setUpcomingChecksUps(newData);
    // setLoading(false);
  }

  let titleBar = <TitleBar>{t("scheduleCheckupTitle")} </TitleBar>;

  if (loading)
    return (
      <LoggedInBoilerplate showBackgroundImage>
        {titleBar}
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (upcomingChecksUps)
    if (upcomingChecksUps.length)
      return (
        <LoggedInBoilerplate showBackgroundImage>
          {titleBar}
          <InputCard
            style={{ width: "38vw" }}
            textContainerStyle={{ width: "100%" }}
            title={t("scheduleCheckupYouHaveUpcomingCheckup")}
            desc={t("scheduleCheckupYouHaveGreatExperience")}
          >
            <ExistingCheckupBooking
              value={upcomingChecksUps[0]}
              resetAndDoQuery={resetAndDoQuery}
            />
          </InputCard>
        </LoggedInBoilerplate>
      );

  return (
    <LoggedInBoilerplate showBackgroundImage>
      {titleBar}

      <Container>
        <MainSection>
          <Inputs>
            <ScheduleDatePicker
              forHealthCheckup={true}
              value={date}
              onChange={setDate}
              label={t("scheduleCheckupSelectDate")}
            />

            <ScheduleHourPicker
              value={hour}
              onChange={setHour}
              label={t("scheduleCheckupSelectTime")}
            />

            <ScheduleSlotPicker
              date={date}
              hour={hour}
              value={slot}
              onChange={setSlot}
              label={t("adminHealthCheckupsSelectSlot")}
            />
          </Inputs>
        </MainSection>

        <Buttons>
          <PrimaryButton onClick={onSubmit}>
            {t("scheduleCheckupButton")}
          </PrimaryButton>
        </Buttons>

        <br />
        <br />
        <br />
      </Container>
    </LoggedInBoilerplate>
  );

  function onSubmit() {
    if (!hour || !date || !slot)
      return window.popupAlert(t("allFieldsAreRequired"));

    submitData({
      method: "post",
      path: "/health-checkup-booking",
      data: { date, hour, slot },
      onSuccess: async () => {
        setLoading(true);
        resetAndDoQuery();
        setLoading(false);

        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        // window.location.reload();
      },
      setLoading,
    });
  }
}
