import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScheduleDatePicker from "../pages/loggedIn/booking/scheduleCheckup/ScheduleDatePicker";
import ScheduleHourPicker from "../pages/loggedIn/booking/scheduleCheckup/ScheduleHourPicker";
import ScheduleSlotPicker from "../pages/loggedIn/booking/scheduleCheckup/ScheduleSlotPicker";
import LoggedInBoilerplate from "../pages/loggedIn/LoggedInBoilerplate";
import submitData from "../../controllers/request/submitData";
import LoadingSection from "../helperComponents/LoadingSection";
import TitleBar from "../helperComponents/TitleBar";
import PrimaryButton from "../helperComponents/PrimaryButton";
import fetchDataWithCaching from "../../controllers/network/fetchDataWithCaching";
import BookingDateOptions from "../pages/loggedIn/booking/scheduleCheckup/BookingDateOptions";
import goTo from "../../controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 25px;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 10px;

  /* height: 50vh; */
  padding: 20px;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    width: 90vw;
    height: auto;
    border: none;
  }
`;

export default function BookingCreationPopup({
  activityType,
  onSuccess,
  participationCountType,
}) {
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [slot, setSlot] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  let titleBar = <TitleBar>{activityType.title}</TitleBar>;

  if (loading)
    return (
      <Container>
        {titleBar}
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      {titleBar}

      <MainSection>
        <Inputs>
          <BookingDateOptions
            activityType={activityType}
            value={date}
            onChange={setDate}
            label={t("selectDate")}
          />

          {date ? (
            <>
              <ScheduleHourPicker
                rangeData={activityType}
                value={hour}
                onChange={setHour}
                label={t("selectHour")}
              />
            </>
          ) : null}

          {date && hour ? (
            <>
              <ScheduleSlotPicker
                activityType={activityType}
                date={date}
                hour={hour}
                value={slot}
                onChange={setSlot}
                label={t("selectSlot")}
              />
            </>
          ) : null}
        </Inputs>
      </MainSection>

      <Buttons>
        <PrimaryButton onClick={onSubmit}>{t("submit")}</PrimaryButton>
      </Buttons>

      <br />
      <br />
      <br />
    </Container>
  );

  function onSubmit() {
    if (!hour || !date || !slot)
      return window.popupAlert(t("allFieldsAreRequired"));

    submitData({
      onSuccessMessage: "Booking Done",
      method: "post",
      path: "/activity-booking",
      data: {
        date,
        hour,
        slot,
        activityTypeID: activityType._id,
        participationCountType,
        action: "BOOK",
      },
      onSuccess: async () => {
        goTo(-1)();

        onSuccess();
        setLoading(false);

        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        // window.location.reload();
      },
      setLoading,
    });
  }
}
