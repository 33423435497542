import { serverLine } from "../../../../controllers/network/serverLine";
import { useContext, useEffect, useState } from "react";
import LoadingSection from "../../../helperComponents/LoadingSection";
import ExistingActivityRecharge from "./ExistingActivityRecharge";
import CustomButton from "../../../helperComponents/CustomButton";
import styled from "styled-components";
import AddSessionSections from "./editor/AddSessionSections";
import IncrementSessionSection from "./editor/IncrementSessionSection";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import ShowPrice from "./editor/ShowPrice";
import transformToIdValuePair from "../../../../controllers/utils/transformToIdValuePair";
import InputCard from "../../../helperComponents/InputCard";
import ShowDiscountedPrice from "./editor/ShowDiscountedPrice";
import { t } from "i18next";
import TitleBar from "../../../helperComponents/TitleBar";
import Context from "../../../../Context";
import CheckoutPopup from "../../../purchase/CheckoutPopup";
import ActivityRechargeBill from "../admin/purchaseRequests/ActivityRechargeBill";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
`;

export default function RechargeActivityPage() {
  const { setForm, loggedInUser } = useContext(Context);
  const [newRecharge, setNewRecharge] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqSent, setReqSent] = useState(false);
  const [activityTypes, setActivityTypes] = useState(null);

  useEffect(() => {
    serverLine.get("/activity-types").then(setActivityTypes);
  }, []);

  if (!activityTypes || loading) return <LoadingSection />;

  let activityTypesObj = transformToIdValuePair(activityTypes);

  if (reqSent) {
    return (
      <LoggedInBoilerplate showBackgroundImage={true}>
        <TitleBar> {t("recharge")} </TitleBar>
        <Container>
          <ExistingActivityRecharge activityTypeIdVsData={activityTypesObj} />
        </Container>
      </LoggedInBoilerplate>
    );
  }

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <TitleBar> {t("recharge")} </TitleBar>
      <Container>
        <ExistingActivityRecharge activityTypeIdVsData={activityTypesObj} />

        <InputCard onSubmit={showPopup} submitButtonText={t("submit")}>
          <Sections>
            <AddSessionSections
              activityTypes={activityTypesObj}
              newRecharge={newRecharge}
              setNewRecharge={setNewRecharge}
            />

            <IncrementSessionSection
              activityTypes={activityTypesObj}
              newRecharge={newRecharge}
              setNewRecharge={setNewRecharge}
            />

            <ShowPrice
              activityTypes={activityTypesObj}
              newRecharge={newRecharge}
            />

            <ShowDiscountedPrice
              activityTypes={activityTypesObj}
              newRecharge={newRecharge}
            />
          </Sections>
        </InputCard>
      </Container>
    </LoggedInBoilerplate>
  );

  function showPopup() {
    setForm({
      // title: "Request plan",
      component: (
        <CheckoutPopup
          purchaseDetails={
            <ActivityRechargeBill
              user={loggedInUser}
              typeIdVsData={activityTypesObj}
              rechargeRequestData={newRecharge}
            />
          }
          onCashSelection={sendRequest}
          totalAmount={null}
        />
      ),
    });
  }

  async function sendRequest({ onSuccess, onFailure }) {
    setLoading(true);

    try {
      let data = await serverLine.post("/recharge-activity-request", {
        data: newRecharge,
      });

      setNewRecharge(data);
      onSuccess();
    } catch (e) {
      onFailure();
      window.popupAlert(e.message);
    }

    setReqSent(true);
    setLoading(false);
  }
}
