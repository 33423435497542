import { useContext, useState } from "react";

import CustomDateInput from "../../../helperComponents/CustomDateInput";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import { serverLine } from "../../../../controllers/network/serverLine";
import Context from "../../../../Context";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import InputCard from "../../../helperComponents/InputCard";
import LoadingSection from "../../../helperComponents/LoadingSection";
import MaterialInput from "../../../helperComponents/MaterialInput";
import CustomToggle from "../../../helperComponents/CustomToggle";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import ImagePicker from "../../../editors/ImagePicker";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TitleBar from "../../../helperComponents/TitleBar";

let thisDate = new Date();

const options = [
  { value: "MALE", label: "Mr" },
  { value: "FEMALE", label: "Mme" },
];

export default function EditProfile() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const { t } = useTranslation();
  const [dateOfBirth, setDateOfBirth] = useState(loggedInUser.dateOfBirth);
  const [firstName, setFirstName] = useState(loggedInUser.firstName);
  const [lastName, setLastName] = useState(loggedInUser.lastName);

  const [phoneNumber, setPhoneNumber] = useState(loggedInUser.phoneNumber);
  const [weight, setWeight] = useState(loggedInUser.weight);
  const [profileImage, setProfileImage] = useState(loggedInUser.profileImage);
  const [gender, setGender] = useState(loggedInUser.gender);

  const [loading, setLoading] = useState(false);

  let titleBar = <TitleBar>{t("onboardingEditProfileTitle")}</TitleBar>;

  if (loading)
    return (
      <LoggedInBoilerplate showBackgroundImage={true}>
        {titleBar}
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      {titleBar}

      <InputCard
        style={{ width: "600px" }}
        onSubmit={onSubmit}
        submitButtonText={t("save")}
      >
        <ImagePicker
          forPerson={true}
          value={profileImage}
          onChange={setProfileImage}
        ></ImagePicker>

        <br />

        <CustomToggle
          name={t("editProfileGender")}
          options={options}
          value={gender}
          onChange={setGender}
        />

        <CustomDateInput
          label={t("editProfileDOB")}
          value={dateOfBirth}
          onChange={setDateOfBirth}
        />

        <MaterialInput
          label={t("onboardingFirstNameInput")}
          // placeholder={"Please Type Your Name Here"}
          value={firstName}
          onChange={extractEventValue(setFirstName)}
        />

        <MaterialInput
          label={t("onboardingLastNameInput")}
          // placeholder={"Please Type Your Name Here"}
          value={lastName}
          onChange={extractEventValue(setLastName)}
        />

        {/* <MaterialInput
          type="number"
          label={t("editProfilePhoneNumber")}
          // placeholder={"Please! Type your phone number here"}
          value={phoneNumber}
          onChange={extractEventValue(setPhoneNumber)}
        /> */}

        <MaterialInput
          type="number"
          label={t("editProfileWeight")}
          // placeholder={"Please! Type your weight in kg"}
          value={weight}
          onChange={extractEventValue(setWeight)}
        />

        <PhoneInput
          international
          defaultCountry="CI"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </InputCard>
    </LoggedInBoilerplate>
  );

  async function onSubmit() {
    setLoading(true);
    try {
      await serverLine.patch("/me", {
        changes: {
          dateOfBirth,
          profileImage,
          phoneNumber,
          gender,
          firstName,
          lastName,
          weight,
        },
      });
      setLoading(false);
      let newLoggedInUser = {
        ...loggedInUser,
        dateOfBirth,
        profileImage,
        phoneNumber,
        gender,
        firstName,
        lastName,
        weight,
      };
      window.popupAlert(t("saved"));
      setLoggedInUser(newLoggedInUser);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  function calculateAge(dateOfBirthStr) {
    let dateOfBirth = new Date(dateOfBirthStr);
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDifference = today.getMonth() - dateOfBirth.getMonth();

    // If the current month is before the birth month or
    // if it's the same month but the day is before the birth day,
    // then subtract one from the age.
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    }

    return age;
  }
}
