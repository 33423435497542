import { useContext } from "react";
import EntryPage from "./routes/EntryPage.js";
import SendAuthCode from "./routes/SendAuthCode.js";

import Context from "../../Context.js";
import getPopupType from "../../controllers/getPopupType.js";
import styled from "styled-components";

import PageNotFound from "../helperComponents/PageNotFound.js";
import LoginSignupPage from "../pages/loggedOut/auth/LoginSignupPage.js";
import ForgotPassword from "../pages/loggedOut/auth/ForgotPassword.js";
import ChangePassword from "../pages/loggedOut/auth/ChangePassword.js";
import ScheduleCheckupPage from "../pages/loggedIn/booking/scheduleCheckup/ScheduleCheckupPage.js";
import LoggedInMembershipPage from "../pages/loggedIn/membership/LoggedInMembershipPage.js";
import ShopPage from "../pages/loggedIn/shop/ShopPage.js";
import SettingsPage from "../pages/loggedIn/settings/SettingsPage.js";

import AdminSettingsPage from "../pages/loggedIn/admin/settings/AdminSettingsPage.js";
import ManageEmployeesPage from "../pages/loggedIn/admin/employees/ManageEmployeesPage.js";

import ManageActivityTypePage from "../pages/loggedIn/admin/activityTypes/ManageActivityTypePage.js";
import ManageStorePage from "../pages/loggedIn/admin/store/ManageStorePage.js";
import ManageAnnouncementPage from "../pages/loggedIn/admin/announcements/ManageAnnouncementPage.js";
import ManageOrderPage from "../pages/loggedIn/admin/orders/ManageOrderPage.js";

import MyOrdersPage from "../pages/loggedIn/shop/MyOrdersPage.js";
import AnnouncementsPage from "../pages/loggedIn/announcements/AnnouncementsPage.js";
import ActivityDetailsPage from "../pages/loggedIn/activity/activityDetails/ActivityDetailsPage.js";

import UpcomingActivitiesPage from "../pages/loggedIn/activity/upcomingActivities/UpcomingActivitiesPage.js";
import AnnouncementPage from "../pages/loggedIn/announcements/AnnouncementPage.js";
import CartPage from "../pages/loggedIn/shop/CartPage.js";
import StoreItemPage from "../pages/loggedIn/shop/StoreItemPage.js";
import EditProfile from "../pages/loggedIn/onboarding/EditProfile.js";
import ManageActivityBookingsPage from "../pages/loggedIn/admin/activityBookings/ManageActivityBookingsPage.js";

import LoginWall from "../pages/loggedIn/LoginWall.js";
import TermsAndCondition from "../pages/foooterPages/TermsAndCondition.js";
import PrivacyPolicy from "../pages/foooterPages/PrivacyPolicy.js";
import RefundPolicy from "../pages/foooterPages/RefundPolicy.js";
import LoggedOutMembershipPage from "../pages/loggedOut/LoggedOutMembershipPage.js";

import EmployeePage from "../pages/loggedIn/employeePage/EmployeePage.js";
import VerifyEmail from "../pages/loggedIn/onboarding/VerifyEmail.js";
import ManageUserPage from "../pages/loggedIn/admin/users/ManageUserPage.js";
import ManageMembershipPage from "../pages/loggedIn/admin/membershipPlans/ManageMembershipPage.js";
import UserPage from "../pages/loggedIn/userPage/UserPage.js";
import ManagePaymentEntry from "../pages/loggedIn/admin/paymentEntry/ManagePaymentEntry.js";
import ManageMembershipRequests from "../pages/loggedIn/admin/membershipRequests/ManageMembershipRequests.js";
import RechargeActivityPage from "../pages/loggedIn/rechargeActivity/RechargeActivityPage.js";
import ManageActivityRechargeRequests from "../pages/loggedIn/admin/purchaseRequests/ManageActivityRechargeRequests.js";
import ActivityTypeDetailsPage from "../pages/loggedIn/activity/activityDetails/ActivityTypeDetailsPage.js";
import MyBookingsPage from "../pages/loggedIn/activity/myActivities/MyBookingsPage.js";
import ActivityBookingPage from "../pages/loggedIn/activity/activityDetails/ActivityBookingPage.js";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

let pathVsComp = {
  "/": <EntryPage />,
  "/edit-profile": <EditProfile />,
  "/buy-membership": <LoggedOutMembershipPage />,

  //Auth
  "/auth-redirect": <SendAuthCode />,
  "/auth": <LoginSignupPage initialType={"LOGIN"} />,
  "/signup": <LoginSignupPage initialType={"SIGNUP"} />,
  "/forgot-password": <ForgotPassword />,
  "/change-password": <ChangePassword />,

  //Testing
  "/testing": <ScheduleCheckupPage />,
  //logged-in-main
  "/membership": <LoggedInMembershipPage />,
  "/shopping": <ShopPage />,
  "/settings": <SettingsPage />,

  //Employee Dashboard
  "/employee-dashboard/manage-activity-types": (
    <ManageActivityTypePage dashboardType={"EMPLOYEE_DASHBOARD"} />
  ),

  "/employee-dashboard/manage-employee": (
    <ManageEmployeesPage dashboardType={"EMPLOYEE_DASHBOARD"} />
  ),
  "/employee-dashboard": (
    <ManageActivityTypePage dashboardType={"EMPLOYEE_DASHBOARD"} />
  ),

  //Logged-in-secondary
  "/employee": <EmployeePage />,

  "/my-bookings": <MyBookingsPage />,
  "/upcoming-activities": <UpcomingActivitiesPage />,
  "/my-orders": <MyOrdersPage />,
  "/announcements": <AnnouncementsPage />,
  //Content page

  "/activity-type": <ActivityTypeDetailsPage />,
  "/booking-and-report": <ActivityBookingPage />,
  "/activity": <ActivityDetailsPage />,

  //
  "/order": <MyOrdersPage />,
  "/store-item": <StoreItemPage />,
  "/announcement": <AnnouncementPage />,
  "/cart": <CartPage />,
  "/recharge-activity": <RechargeActivityPage />,

  //user
  "/user": <UserPage />,

  //admin

  //
  "/admin/manage-store": <ManageStorePage />,
  "/admin/manage-activity-types": <ManageActivityTypePage />,
  "/admin/manage-activity-bookings": <ManageActivityBookingsPage />,

  "/admin/manage-employee": <ManageEmployeesPage />,
  //
  "/admin/manage-users": <ManageUserPage />,
  "/admin/payment-entry": <ManagePaymentEntry />,
  "/admin/membership-requests": <ManageMembershipRequests />,
  "/admin/manage-membership-plans": <ManageMembershipPage />,
  //
  "/admin/manage-announcements": <ManageAnnouncementPage />,
  "/admin/manage-orders": <ManageOrderPage />,
  "/admin/manage-settings": <AdminSettingsPage />,
  "/admin/activity-recharge-requests": <ManageActivityRechargeRequests />,
  "/admin": <ManageActivityTypePage />,

  //Footer Pages
  "/confirm-email": <VerifyEmail />,
  "/terms-and-conditions": <TermsAndCondition />,
  "/privacy-policy": <PrivacyPolicy />,
  "/refund-policy": <RefundPolicy />,
};

let loginNotRequiredPages = [
  "/",
  "/confirm-email",
  "/auth-redirect",
  "/auth",
  "/signup",
  "/forgot-password",
  "/change-password",
  "/privacy-policy",
  "/terms-and-conditions",
  "/refund-policy",
  "/buy-membership",
  "/buy-activity",
];

export default function CustomRouter() {
  const { currentRoute, nonPopupRoute, loggedInUserID } = useContext(Context);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);

  let popupType = getPopupType(currentRoute);

  if (popupType && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  let containerStyle = null;

  if (popupComp) {
    containerStyle = { display: "none" };
  }

  return (
    <>
      <Container style={containerStyle}>
        {baseComp ? baseComp : <PageNotFound />}
      </Container>
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    // console.log("Scanning Path", pathToScan);

    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") return pathVsComp["/"];

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);

      if (extracted === aPath) {
        // console.log()

        if (!loginNotRequiredPages.includes(aPath)) {
          if (!loggedInUserID) return <LoginWall />;
        }

        return theComp;
      }
    }

    return null;
  }

  function isNotForm(thePath) {
    return thePath.indexOf("formPage=true") === -1;
  }

  function getPopupComp(pathName) {
    return false;
  }
}
